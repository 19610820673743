import React, { useState } from 'react';
import { Avatar, Menu, Dropdown, Space } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logout from '../../assets/icons/logoutIcon.png'
import API from '../../API';
import Constants from '../../constants/Constants';
import useNavigation from "../../helper/UseNavigation";
import { displayToaster } from '../../helper/Toaster';
interface AvatarMenuProps {
    initials: string;
  }

  const AvatarMenu: React.FC<AvatarMenuProps> = ({ initials }) =>{
    const [open, setOpen] = useState(false);
    const handleNavigation = useNavigation();
    // const { logout } = useAuth();

    function invalidateCookie(cookieName : string) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    const handleMenuClick = (e: { key: string; }) => {
        if (e.key === 'logout') {
            // Handle logout action
            setOpen(false); // Close the menu after logout
        }
    };

    const handleLogout = async () => {
        const url = '/auth/signout';

        const requestData = {
            
            email: localStorage.getItem("email"),
            
        }
        await API.post(url, requestData)
                .then(res => {
                //   setToken(null);
                //   setIsLoggedIn(false);

                  localStorage.clear();
                  invalidateCookie(Constants.JWT_TOKEN); 
                  handleNavigation('/signin', null);
                })
                .catch(error => {
                    // toaster
                    displayToaster("error", error.response.data.message);
                });
       
       
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Link to="/settings"><Menu.Item key="settings">
                <Space>
                    <SettingOutlined className='avatar-menu-icon' />
                    Settings
                </Space>

            </Menu.Item>
            </Link>
            <Menu.Item key="logout" onClick={handleLogout}>
                <Space>
                    <img src={Logout} className='avatar-menu-icon-logout' alt='logout' />
                    Logout
                </Space>

            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            open={open}
            onOpenChange={setOpen}
        >
            <Avatar >{initials}</Avatar>
        </Dropdown>
    );
};

export default AvatarMenu;
