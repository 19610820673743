import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
} from "antd";
import Constants from "../../../constants/Constants";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import TextEditor from "../../../components/editor/TextEditor";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import GoogleAutoComplete from "react-google-autocomplete";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { createCampaign } from "../../../helper/CreateCampaign";
import { getCampaignDetails } from "../../../helper/GetCampaignDetails";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { createDraftCampaign } from "../../../helper/CreateDraftCampaign";
const { Option } = Select;
const moment = require("moment-timezone");

interface FieldOfStudy {
  fieldOfStudyId: string;
  name: string;
}

interface QualificationLevel {
  qualificationLevelId: string;
  name: string;
}

interface GeneralDetailsProps {
  disableDraftButton: (status: boolean) => void;
  onCampaignIdChange: (id: string) => void;
  onIsValidChange: (isValid: boolean) => void;
  campaignId: string | undefined;
  onNextClick: () => void;
  clickSaveDraft: (status: boolean) => void;
  saveDraftFlag: boolean | undefined;
  setGeneralSectionCompleted: (generalSectionCompleted: boolean) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

function GeneralDetails({
  disableDraftButton,
  onCampaignIdChange,
  onIsValidChange,
  campaignId,
  onNextClick,
  clickSaveDraft,
  saveDraftFlag,
  setGeneralSectionCompleted,
  setCurrentStep,
}: GeneralDetailsProps) {
  const [form] = Form.useForm();

  const locationGeneral = useLocation();

  const state = locationGeneral.state;

  const [fieldOfStudy, setFieldOfStudy] = useState<FieldOfStudy[]>([]);

  const [qualificationLevel, setQualificationLevel] = useState<
    QualificationLevel[]
  >([]);

  const [content, setContent] = useState("");

  const [isshow, setIsShow] = useState(true);

  const [isCampaignLoad, setIsCampaignLoad] = useState(true);

  const handleDisableSaveDraftButton = useCallback(() => {
    const isShowDaft =
      !!form.getFieldValue("campaignTitle") &&
      !!form.getFieldValue("empType") &&
      !!form.getFieldValue("workingType") &&
      !!form.getFieldValue("location") &&
      !!form.getFieldValue("campaignDescription") &&
      !!form.getFieldValue("deadLineDate") &&
      !!form.getFieldValue("deadLineTime");
    disableDraftButton(!isShowDaft);
  }, [form, disableDraftButton]);

  const handleCompaignDescription = (content: any) => {
    setContent(content);
    form.setFieldValue("campaignDescription", content);
  };

  const handleChange = (isshow: boolean) => {
    setIsShow(isshow);
  };

  const handlePlaceSelected = (place: any) => {
    form.setFieldsValue({ location: place.formatted_address });
  };

  const onFinish = async (values: any) => {
    const params = {
      section: Constants.GENERAL_DETAILS,
    };
    const timeZone = await getBrowserTimeZone();
    const deadLineDate = formatDate(values.deadLineDate);
    const deadLineTime = formatTime(values.deadLineTime);

    console.log(campaignId, "campaignId");

    if (campaignId != null && campaignId !== undefined) {
      const requestData = {
        id: campaignId,
        email: localStorage.getItem("email"),
        title: values.campaignTitle,
        empType: values.empType,
        workType: values.workingType,
        location: values.location,
        description: values.campaignDescription,
        deadLineDate: deadLineDate,
        deadLineTime: deadLineTime,
        timeZone: timeZone,
        isShowDetails: values.isShow,
        currency: values.currency,
        paidInterval: values.paidRate,
        minSal: values.minSal,
        maxSal: values.maxSal,
        minExp: values.minExp,
        maxExp: values.maxExp,
        educationQualificatons: values.educationQualifications,
      };

      const url = "/campaign/update-campaign";
      await API.post(url, requestData, {
        params: params,
      })
        .then((res) => {
          displayToaster("success", res.data.message);
          onIsValidChange(true);
          setGeneralSectionCompleted(true);
          setCurrentStep(1);
        })
        .catch((error) => {
          displayToaster("error", error.response.data.message);
        });
    } else {
      const requestData = {
        email: localStorage.getItem("email"),
        title: values.campaignTitle,
        empType: values.empType,
        workType: values.workingType,
        location: values.location,
        description: values.campaignDescription,
        deadLineDate: deadLineDate,
        deadLineTime: deadLineTime,
        timeZone: timeZone,
        isShowDetails: values.isShow,
        currency: values.currency,
        paidInterval: values.paidRate,
        minSal: values.minSal,
        maxSal: values.maxSal,
        minExp: values.minExp,
        maxExp: values.maxExp,
        educationQualificatons: values.educationQualifications,
      };
      await createCampaign(requestData, params)
        .then(async (res) => {
          const responseData = res.data;

          sessionStorage.setItem("createdCampaignId", responseData.id);

          onCampaignIdChange(responseData.id);
          displayToaster("success", responseData.message);
          onIsValidChange(true);
          setGeneralSectionCompleted(true);
          setCurrentStep(1);
          // onNextClick();
        })
        .catch((error) => {
          displayToaster("error", error.response.data.message);
        });
    }
  };

  // Custom validator function to check if max salary is greater than min salary
  const validateMaxSalary = async (_: any, value: number) => {
    const minSalary = form.getFieldValue("minSal");

    if (minSalary >= 0 && value >= 0 && value < minSalary) {
      throw new Error(
        "Maximum salary must be greater than or equal to minimum salary"
      );
    }
    if (minSalary < 0 && value < 0 && value > minSalary) {
      throw new Error(
        "Maximum salary must be greater than or equal to minimum salary"
      );
    }
  };

  const validateMaxExperience = async (_: any, value: number) => {
    const minExp = form.getFieldValue("minExp");
    if (value < 0) {
      return Promise.resolve();
    }
    if (value <= minExp) {
      throw new Error(
        "Maximum experience must be greater than minimum experience"
      );
    }
  };

  const validateExperience = (_: any, value: number | undefined) => {
    if (value && value > 100) {
      return Promise.reject("Experience cannot exceed 100");
    }
    return Promise.resolve();
  };

  const fetchCampaignDetails = useCallback(async () => {
    if (campaignId) {
      const requestData = {
        email: localStorage.getItem("email"),
        id: campaignId,
      };

      try {
        const res = await getCampaignDetails(requestData);
        const responseData = res.data.data;

        return responseData;
      } catch (error) {
        return null;
      }
    }
  }, [campaignId]);

  useEffect(() => {
    const initializeData = async () => {
      const dropdownsString = localStorage.getItem("dropdowns");
      const dropdowns = dropdownsString
        ? JSON.parse(dropdownsString)
        : undefined;

      if (dropdowns != null && dropdowns !== undefined) {
        if (qualificationLevel.length === 0) {
          setQualificationLevel(dropdowns.qualificationLevel);
        }

        if (fieldOfStudy.length === 0) {
          setFieldOfStudy(dropdowns.fieldOfStudy);
        }
      } else {
        const url = "/campaign/dropdowns";
        await API.post(url)
          .then((res) => {
            localStorage.setItem("dropdowns", JSON.stringify(res.data.data));
            setQualificationLevel(res.data.data.qualificationLevel);
            setFieldOfStudy(res.data.data.fieldOfStudy);
          })
          .catch((error) => {
            displayToaster("error", error.response.data.message);
          });
      }
    };

    initializeData();
  }, [fieldOfStudy, qualificationLevel]);

  const formatTime = (date: any) => {
    return dayjs(date).format(Constants.TIME_FORMAT);
  };
  const formatDate = (date: any) => {
    return dayjs(date).format(Constants.DATE_FORMAT);
  };

  const getBrowserTimeZone = () => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert browser timezone to a Moment.js timezone object
    const momentTimezone = moment.tz.zone(browserTimezone);
    console.log("timeZone2:", momentTimezone.name);
    return momentTimezone.name;
  };

  const onrefreshWithoutCampaignCreated = async () => {
    const createdCampaignId = sessionStorage.getItem("createdCampaignId");
    console.log(createdCampaignId, "createdCampaignId");

    if (createdCampaignId != null) {
      const requestData = {
        email: localStorage.getItem("email"),
        id: createdCampaignId,
      };

      try {
        const res = await getCampaignDetails(requestData);
        const data = res.data.data;

        if (data !== undefined) {
          setIsShow(data.jobDetails.isShow);
          setContent(data.jobDetails.jobDetails);
          form.setFieldsValue({
            campaignTitle: data.jobDetails.jobTitle,
            location: data.jobDetails.location,
            empType: data.jobDetails.employmentType,
            campaignDescription: data.jobDetails.jobDetails,
            workingType: data.jobDetails.workMode,
            isShow: data.jobDetails.isShow,
            currency: data.jobDetails.currency,
            paidRate: data.jobDetails.paidInterval,
            minSal: data.jobDetails.minSal,
            maxSal: data.jobDetails.maxSal,
            minExp: data.jobDetails.minExp,
            maxExp: data.jobDetails.maxExp,
            deadLineDate: dayjs(data.jobDetails.deadLineDate),
            deadLineTime: dayjs(
              data.jobDetails.deadLineTime,
              Constants.TIME_FORMAT
            ),
            educationQualifications: data.jobDetails.educationQualifications,
          });
          handleDisableSaveDraftButton();
        }
        setIsCampaignLoad(false);
        onCampaignIdChange(createdCampaignId);
        setGeneralSectionCompleted(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (state == null) {
      onrefreshWithoutCampaignCreated();
    }
  }, []);

  useEffect(() => {
    const handleSaveAsDraft = async () => {
      if (
        form.getFieldValue("campaignTitle") &&
        form.getFieldValue("empType") &&
        form.getFieldValue("workingType") &&
        form.getFieldValue("location") &&
        form.getFieldValue("campaignDescription") &&
        form.getFieldValue("deadLineDate") &&
        form.getFieldValue("deadLineTime")
      ) {
        console.log(
          "educationQualifications:",
          form.getFieldValue("educationQualifications")
        );
        console.log("deadLineDate:", form.getFieldValue("deadLineDate"));
        console.log(
          "dt:",
          dayjs(form.getFieldValue("deadLineDate")).format(
            Constants.DATE_FORMAT
          )
        );
        console.log("timeZone:", dayjs().utcOffset());

        const timeZone = await getBrowserTimeZone();
        const deadLineDate = formatDate(form.getFieldValue("deadLineDate"));
        const deadLineTime = formatTime(form.getFieldValue("deadLineTime"));

        const params = {
          section: Constants.GENERAL_DETAILS,
        };

        if (campaignId != null && campaignId !== undefined) {
          const requestData = {
            id: campaignId,
            email: localStorage.getItem("email"),
            title: form.getFieldValue("campaignTitle"),
            empType: form.getFieldValue("empType"),
            workType: form.getFieldValue("workingType"),
            location: form.getFieldValue("location"),
            description: form.getFieldValue("campaignDescription"),
            deadLineDate: deadLineDate,
            deadLineTime: deadLineTime,
            timeZone: timeZone,
            isShowDetails: form.getFieldValue("isShow"),
            currency: form.getFieldValue("currency"),
            paidInterval: form.getFieldValue("paidRate"),
            minSal: form.getFieldValue("minSal"),
            maxSal: form.getFieldValue("maxSal"),
            minExp: form.getFieldValue("minExp"),
            maxExp: form.getFieldValue("maxExp"),
            educationQualificatons: form.getFieldValue(
              "educationQualifications"
            ),
          };

          const url = "/campaign/update-draft-campaign";
          await API.post(url, requestData, {
            params: params,
          })
            .then((res) => {
              displayToaster("success", res.data.message);
              fetchCampaignDetails();
            })
            .catch((error) => {
              displayToaster("error", error.response.data.message);
            });
        } else {
          const requestData = {
            email: localStorage.getItem("email"),
            title: form.getFieldValue("campaignTitle"),
            empType: form.getFieldValue("empType"),
            workType: form.getFieldValue("workingType"),
            location: form.getFieldValue("location"),
            description: form.getFieldValue("campaignDescription"),
            deadLineDate: deadLineDate,
            deadLineTime: deadLineTime,
            timeZone: timeZone,
            isShowDetails: form.getFieldValue("isShow"),
            currency: form.getFieldValue("currency"),
            paidInterval: form.getFieldValue("paidRate"),
            minSal: form.getFieldValue("minSal"),
            maxSal: form.getFieldValue("maxSal"),
            minExp: form.getFieldValue("minExp"),
            maxExp: form.getFieldValue("maxExp"),
            educationQualificatons: form.getFieldValue(
              "educationQualifications"
            ),
          };
          await createDraftCampaign(requestData, params)
            .then(async (res) => {
              const responseData = res.data;

              console.log("RESPONSE DATA", responseData);
              sessionStorage.setItem("createdCampaignId", responseData.id);

              onCampaignIdChange(responseData.id);
              displayToaster("success", responseData.message);
              fetchCampaignDetails();
            })
            .catch((error) => {
              displayToaster("error", error.response.data.message);
            });
        }
      }
      clickSaveDraft(false);
    };

    if (
      clickSaveDraft !== undefined &&
      clickSaveDraft !== null &&
      saveDraftFlag !== false
    ) {
      // TODO...........
      handleSaveAsDraft();
      // console.log("saveDraftFlag:",saveDraftFlag)
      clickSaveDraft(false);
    }
    // console.log("saveDraftFlagout:",saveDraftFlag)

    if (
      isCampaignLoad &&
      campaignId != null &&
      campaignId !== undefined &&
      saveDraftFlag === false
    ) {
      fetchCampaignDetails().then((data: any) => {
        if (data !== undefined) {
          console.log("inside fetchCampaignDetails...............");
          setIsShow(data.jobDetails.isShow);
          setContent(data.jobDetails.jobDetails);
          form.setFieldsValue({
            campaignTitle: data.jobDetails.jobTitle,
            location: data.jobDetails.location,
            empType: data.jobDetails.employmentType,
            campaignDescription: data.jobDetails.jobDetails,
            workingType: data.jobDetails.workMode,
            isShow: data.jobDetails.isShow,
            currency: data.jobDetails.currency,
            paidRate: data.jobDetails.paidInterval,
            minSal: data.jobDetails.minSal,
            maxSal: data.jobDetails.maxSal,
            minExp: data.jobDetails.minExp,
            maxExp: data.jobDetails.maxExp,
            deadLineDate: dayjs(data.jobDetails.deadLineDate),
            deadLineTime: dayjs(
              data.jobDetails.deadLineTime,
              Constants.TIME_FORMAT
            ),
            educationQualifications: data.jobDetails.educationQualifications,
          });
          handleDisableSaveDraftButton();
        }
      });
      setIsCampaignLoad(false);
      setGeneralSectionCompleted(true);
    } else if (
      state != null &&
      state.campaignId != null &&
      state.campaignId !== undefined
    ) {
      onCampaignIdChange(state.campaignId);
    } else {
      if (isCampaignLoad) {
        console.log("inside else 414...............");
        setIsShow(true);
        form.setFieldsValue({
          educationQualifications: [
            { fieldOfStudies: {}, qualificationLevels: {} },
          ],
          isShow: true,
        });
        setIsCampaignLoad(false);
      }
    }
  }, [
    campaignId,
    clickSaveDraft,
    fetchCampaignDetails,
    form,
    handleDisableSaveDraftButton,
    isCampaignLoad,
    onCampaignIdChange,
    saveDraftFlag,
    state,
  ]);

  const validateDateTime = (
    rule: any,
    value: string | number | dayjs.Dayjs | Date | null | undefined
  ) => {
    // Check if the value is empty
    if (!value) {
      return Promise.resolve(); // Return resolved Promise when the value is empty
    }
    const selectedDateTime = dayjs(form.getFieldValue("deadLineDate"))
      .set("hour", form.getFieldValue("deadLineTime").hour())
      .set("minute", form.getFieldValue("deadLineTime").minute());
    const currentDateTime = dayjs();

    if (selectedDateTime.isBefore(currentDateTime)) {
      return Promise.reject("Selected time cannot be in the past");
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        name="campaign-form"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onValuesChange={handleDisableSaveDraftButton}
        scrollToFirstError
      >
        <div>
          <p id="general-details" className="mb-4 text-lg font-bold">
            General Details
          </p>

          <Form.Item
            label="Campaign Title"
            name="campaignTitle"
            rules={[
              {
                required: true,
                message: "Campaign Title is required",
              },
            ]}
          >
            <Input
              placeholder="Eg. Software Engineer"
              onChange={handleDisableSaveDraftButton}
              size="large"
            />
          </Form.Item>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Form.Item
              label="Employment Type"
              name="empType"
              rules={[
                {
                  required: true,
                  message: "Employment Type is required",
                },
              ]}
            >
              <Select
                onChange={handleDisableSaveDraftButton}
                showSearch
                placeholder="Select Employment Type"
                size="large"
              >
                {Constants.EMPLOYMENT_TYPE.map((emptype) => (
                  <Option key={emptype} value={emptype}>
                    {emptype}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Working Type"
              name="workingType"
              rules={[
                {
                  required: true,
                  message: "Working Type is required",
                },
              ]}
            >
              <Select
                size="large"
                onChange={handleDisableSaveDraftButton}
                showSearch
                placeholder="Select Working Type"
              >
                {Constants.WORKING_TYPE.map((worktype) => (
                  <Option key={worktype} value={worktype}>
                    {worktype}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Location is required",
              },
            ]}
          >
            <GoogleAutoComplete
              onChange={handleDisableSaveDraftButton}
              name="location"
              className="w-full border  border-[#e5e7eb] px-[11px] py-2 rounded-lg text-base"
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              onPlaceSelected={handlePlaceSelected}
              placeholder="Select City"
            />
          </Form.Item>

          <Form.Item
            label="Campaign Description"
            name="campaignDescription"
            rules={[
              {
                required: true,
                message: "Campaign description is required",
              },
            ]}
          >
            <TextEditor
              onContentChange={handleCompaignDescription}
              initialContent={content}
            />
          </Form.Item>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
            <Form.Item
              label="Campaign Deadline"
              name="deadLineDate"
              rules={[
                {
                  required: true,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                className="w-full"
                size="large"
                minDate={dayjs()}
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
                format={Constants.DISPLAY_DATE_FORMAT}
                inputReadOnly={true}
                // onChange={disabledTime}
              />
            </Form.Item>

            <Form.Item
              className="no-required "
              label=" "
              name="deadLineTime"
              rules={[
                {
                  required: true,
                  message: "Time is required",
                },
                {
                  validator: validateDateTime,
                },
              ]}
            >
              <TimePicker
                className="w-full "
                size="large"
                use12Hours
                format="h:mm A"
                inputReadOnly={true}
                // {...disabledTime() }
              />
            </Form.Item>
          </div>
        </div>
        <div>
          <p id="salary-details" className="mb-4 text-lg font-bold">
            Salary Details
          </p>

          <div className="flex flex-row items-center gap-2 mb-6">
            <Form.Item name="isShow" className="m-0">
              <Switch defaultChecked onChange={handleChange} />
            </Form.Item>
            <p className="m-0 text-base">
              Show salary details for the candidate
            </p>
          </div>
          {isshow ? (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Currency is required",
                  },
                ]}
              >
                <Select size="large" showSearch placeholder="Select Currency ">
                  {Constants.CURRENCY_TYPE.map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Paid Every"
                name="paidRate"
                rules={[
                  {
                    required: true,
                    message: "Paid Every is required",
                  },
                ]}
              >
                <Select size="large" showSearch placeholder="Select Paid Every">
                  {Constants.PAID_RATE.map((rate) => (
                    <Option key={rate} value={rate}>
                      {rate}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Minimum Salary"
                name="minSal"
                rules={[
                  {
                    required: true,
                    message: "Minimum Salary is required",
                  },
                  {
                    validator: (_, value) => {
                      if (value >= 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Minimum Salary cannot be negative"
                      );
                    },
                  },
                ]}
              >
                <InputNumber
                  // onChange={handleMinSalaryChange}
                  // formatter={(value) => `${value}`.replace(/\D/g, "")} // Allow only digits
                  // parser={(value) => parseFloat(value || "0")} // Parse input as a floating-point number
                  formatter={
                    (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas for thousands separators
                  }
                  parser={(value) => {
                    if (typeof value === "string") {
                      return parseFloat(value.replace(/[^\d.-]/g, "")); // Parse input as a floating-point number
                    }
                    return 0; // or return a default value if value is undefined
                  }}
                  placeholder="Enter salary"
                  className="w-full"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Maximum Salary"
                name="maxSal"
                rules={[
                  {
                    required: true,
                    message: "Maximum Salary is required",
                  },
                  {
                    validator: validateMaxSalary,
                  },
                  {
                    validator: (_, value) => {
                      if (value >= 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Maximum Salary cannot be negative"
                      );
                    },
                  },
                ]}
              >
                <InputNumber
                  // onChange={handleMaxSalaryChange}
                  // formatter={(value) => `${value}`.replace(/\D/g, "")} // Allow only digits
                  // parser={(value) => parseFloat(value || "0")} // Parse input as a floating-point number
                  formatter={
                    (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add commas for thousands separators
                  }
                  parser={(value) => {
                    if (typeof value === "string") {
                      return parseFloat(value.replace(/[^\d.-]/g, "")); // Parse input as a floating-point number
                    }
                    return 0; // or return a default value if value is undefined
                  }}
                  placeholder="Enter salary"
                  className="w-full"
                  size="large"
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <p id="work-experience" className="mb-4 text-lg font-bold">
            Work Experience
          </p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Form.Item
              label="Minimum Experience"
              name="minExp"
              rules={[
                {
                  required: true,
                  message: "Minimum Experience is required",
                },
                {
                  validator: validateExperience,
                },
                {
                  validator: (_, value) => {
                    if (value >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Minimum Experience cannot be negative"
                    );
                  },
                },
              ]}
            >
              <InputNumber
                size="large"
                className="w-full"
                placeholder="Enter Experience"
                formatter={(value) => {
                  const formattedValue = `${value} ${
                    value == 1 ? "Year" : "Years"
                  }`;
                  return formattedValue; // Return the formatted value as a string
                }}
                parser={(value: string | undefined) => {
                  const numericValue = parseFloat(
                    value?.replace(" years", "") || "0"
                  );
                  return isNaN(numericValue) ? 0 : numericValue;
                }}
              />
            </Form.Item>
            <Form.Item
              name="maxExp"
              label="Maximum Experience"
              rules={[
                {
                  required: true,
                  message: "Maximum Experience is required",
                },
                {
                  validator: validateMaxExperience,
                },
                {
                  validator: validateExperience,
                },
                {
                  validator: (_, value) => {
                    if (value >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Minimum Experience cannot be negative"
                    );
                  },
                },
              ]}
            >
              <InputNumber
                size="large"
                className="w-full"
                placeholder="Enter Experience"
                formatter={(value) => {
                  const formattedValue = `${value} ${
                    value == 1 ? "Year" : "Years"
                  }`;
                  return formattedValue; // Return the formatted value as a string
                }}
                parser={(value: string | undefined) => {
                  const numericValue = parseFloat(
                    value?.replace(" years", "") || "0"
                  );
                  return isNaN(numericValue) ? 0 : numericValue;
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div>
          <p id="educational-qualifications" className="mb-4 text-lg font-bold">
            Education Qualifications
          </p>

          <Form.List name="educationQualifications">
            {(educationQualifications, { add, remove }) => (
              <div>
                {educationQualifications.map((field, index) => (
                  <div
                    className="flex flex-row items-center gap-4"
                    key={field.key}
                    style={{ marginTop: 10 }}
                  >
                    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                      <Form.Item
                        label="Field of Study"
                        name={[index, "fieldOfStudies", "fieldOfStudyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Field of study is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          className="w-full"
                          size="large"
                          placeholder="Field of Study"
                          optionFilterProp="children"
                        >
                          {fieldOfStudy.map((study) => (
                            <Option
                              key={study.fieldOfStudyId}
                              value={study.fieldOfStudyId}
                            >
                              {study.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Qualification Level"
                        name={[
                          index,
                          "qualificationLevels",
                          "qualificationLevelId",
                        ]}
                        rules={[
                          {
                            required: true,
                            message: "Qualification level is required",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     // console.log(getFieldValue([
                          //     //   index,
                          //     //   "fieldOfStudies",
                          //     //   "fieldOfStudyId",
                          //     // ]))
                          //     console.log("value:", value, ", index:", index)
                          //     // Check for duplicate records
                          //     const isDuplicate = checkForDuplicates(educationQualifications, index, getFieldValue);
                          //     console.log("isDuplicate:", isDuplicate)
                          //     if (isDuplicate) {
                          //       return Promise.reject(
                          //         new Error(
                          //           "Remove duplicate record."
                          //         )
                          //       );
                          //     }
                          //     return Promise.resolve();
                          //   },
                          // }),
                        ]}
                      >
                        <Select
                          showSearch
                          className="w-full"
                          size="large"
                          placeholder="Qualification Level"
                          optionFilterProp="children"
                        >
                          {qualificationLevel.map((level) => (
                            <Option
                              key={level.qualificationLevelId}
                              value={level.qualificationLevelId}
                            >
                              {level.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {educationQualifications.length > 1 ? (
                      <Button
                        onClick={() => remove(field.name)}
                        icon={<CloseOutlined />}
                      />
                    ) : null}
                  </div>
                ))}
                <div>
                  <Form.Item>
                    <Button
                      className="text-[#595D62]"
                      icon={<PlusOutlined />}
                      type="link"
                      size="large"
                      onClick={() => add()}
                    >
                      Add another
                    </Button>
                  </Form.Item>
                </div>
              </div>
            )}
          </Form.List>
        </div>

        <Form.Item className="flex justify-end w-full" colon={false}>
          <Button
            type="primary"
            className="mt-5 "
            htmlType="submit"
            size="large"
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default GeneralDetails;
