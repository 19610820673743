import {
  Button,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Modal,
  Select,
  message,
} from "antd";

import { useEffect, useState } from "react";
import CandidateExperienceService from "../../../Services/Candidate/CandidateExperienceService";
import dayjs from "dayjs";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import CandidateGetInfoDTO from "../../../DTOs/CandidateGetAboutYouDTO";
import Constants from "../../../constants/Constants";
import GoogleAutoComplete from "react-google-autocomplete";
import { AxiosError } from "axios";

interface ExperienceEditModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  experienceEditId: number | null;
  campaignId: string;
  renderExperience: () => void;
}

function ExperienceEditModal({
  isModalOpen,
  setIsModalOpen,
  experienceEditId,
  campaignId,
  renderExperience,
}: ExperienceEditModalProps) {
  const [form] = Form.useForm();
  const [isWorking, setIsWorking] = useState(false);
  const [empStartDate, setEmpStartDate] = useState<any>(null);
  const [empEndDate, setEmpEndDate] = useState<any>(null);
  const dateFormat = "DD/MM/YYYY";
  const dateFormatToDataBase = "YYYY-MM-DD";
  const { Option } = Select;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePlaceSelected = (place: any) => {
    form.setFieldsValue({ location: place.formatted_address });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (experienceEditId != null && isModalOpen) {
        const candidateEmail = retriveCandidateEmail();

        const info: CandidateGetInfoDTO = {
          campaignId: campaignId,
          email: candidateEmail ? candidateEmail : "",
          roles: Constants.ROLES[2].value,
        };

        try {
          const resp = await CandidateExperienceService.getExperienceBYId(
            info,
            experienceEditId
          );

          const candidateData = resp.data.userData;

          const data = {
            position: candidateData.jobTitle,
            companyName: candidateData.companyName,
            empEndDate:
              candidateData.endDate != null
                ? dayjs(
                    dayjs(candidateData.endDate).format(dateFormat),
                    dateFormat
                  )
                : null,
            empStartDate: dayjs(
              dayjs(candidateData.startDate).format(dateFormat),
              dateFormat
            ),
            employmentType: candidateData.employmentType,
            isWorking: candidateData.isWorking,
            location: candidateData.location,
            summary: candidateData.summary,
          };

          setEmpStartDate(
            dayjs(dayjs(candidateData.startDate).format(dateFormat), dateFormat)
          );
          setEmpEndDate(
            candidateData.endDate != null
              ? dayjs(
                  dayjs(candidateData.endDate).format(dateFormat),
                  dateFormat
                )
              : null
          );
          setIsWorking(candidateData.isWorking);

          form.setFieldsValue(data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [isModalOpen]);

  const disabledDateEmpStartDate = (current: dayjs.Dayjs | null): boolean => {
    if (empEndDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isAfter(empEndDate, "day") ||
            dayjs(current).isSame(empEndDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const disabledDateEmpEndDate = (current: dayjs.Dayjs | null): boolean => {
    if (empStartDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isBefore(empStartDate, "day") ||
            dayjs(current).isSame(empStartDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const onFinish = async (values: any) => {
    const candidateEmail = retriveCandidateEmail();
    try {
      const data: any = {
        jobTitle: values.position,
        employmentType: values.employmentType,
        location: values.location,
        startDate: values.empStartDate.format(dateFormatToDataBase),
        endDate: isWorking
          ? null
          : values.empEndDate.format(dateFormatToDataBase),
        isWorking: isWorking,
        summary: values.summary,
        id: experienceEditId,
        campaignId: campaignId,
        email: candidateEmail ? candidateEmail : "",
        roles: Constants.ROLES[2].value,
        companyName: values.companyName,
      };

      const resp = await CandidateExperienceService.updateExperience(data);
      renderExperience();
      setIsModalOpen(false);
      message.success("Experience sucessfully updated");
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  return (
    <>
      <Modal
        title="Edit Experience"
        onCancel={handleCancel}
        open={isModalOpen}
        footer={false}
      >
        <Form
          form={form}
          name="experience-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div>
            {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Your Position*
              </span>
              <Form.Item
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Position is required",
                  },
                ]}
              >
                <Input placeholder="Eg. Software Engineer" size="large" />
              </Form.Item>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Company Name*
              </span>
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Company Name is required",
                  },
                ]}
              >
                <Input placeholder="Type company name" size="large" />
              </Form.Item>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Employment Type*
              </span>
              <Form.Item
                name="employmentType"
                rules={[
                  {
                    required: true,
                    message: "Employment Type is required",
                  },
                ]}
              >
                <Select
                  // defaultValue="lucy"
                  placeholder="Select"
                  size="large"
                  // onChange={handleChange}
                >
                  {Constants.EMPLOYMENT_TYPE.map((emptype) => (
                    <Option key={emptype} value={emptype}>
                      {emptype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Location*
              </span>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Location is required",
                  },
                ]}
              >
                <GoogleAutoComplete
                  className="w-full border  border-[#e5e7eb] px-[11px] py-2 rounded-lg text-base"
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handlePlaceSelected}
                  placeholder="Select"
                />
              </Form.Item>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Work Period*
              </span>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <Form.Item
                  name="empStartDate"
                  rules={[
                    {
                      required: true,
                      message: "Employment Start Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select start date"
                    format={dateFormat}
                    style={{ width: "100%" }}
                    size="large"
                    disabledDate={disabledDateEmpStartDate}
                    onChange={setEmpStartDate}
                    inputReadOnly={true}
                  />
                </Form.Item>
                {!isWorking && (
                  <Form.Item
                    name="empEndDate"
                    rules={[
                      {
                        required: true,
                        message: "Employment End Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select end date"
                      format={dateFormat}
                      style={{ width: "100%" }}
                      size="large"
                      disabledDate={disabledDateEmpEndDate}
                      onChange={setEmpEndDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
            <Form.Item name="currentlyWorking">
              <Checkbox
                checked={isWorking}
                onChange={(e) => setIsWorking(e.target.checked)}
              >
                I currently work here
              </Checkbox>
            </Form.Item>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Summary*
              </span>

              <Form.Item
                name="summary" // Add a unique name
                rules={[
                  {
                    required: true,
                    message: "Summary is required",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: "132px" }}
                  size="large"
                  placeholder="Summary"
                />
              </Form.Item>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default ExperienceEditModal;
