import { Button } from "antd";
import SettingsLeftSider from "../../../components/leftsider/SettingsLeftSider";
import Profile from "./Profile";
import Security from "./Security";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

function Settings() {
  const [activeTab, setActiveTab] = useState("account"); // Default tab

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back to the previous location
  };

  return (
    <>
      <div className="flex items-center w-full  py-2 bg-white border-b border-gray-200 px-[30px] md:px-[116px] sticky top-0  z-50 ">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <Button
              size="large"
              icon={<ArrowLeftOutlined />}
              onClick={goBack}
            />

            <p className="m-0 text-xl font-bold">Settings</p>
          </div>
        </div>
      </div>
      <div className="px-[30px] md:px-[116px] flex flex-col md:flex-row h-full">
        <SettingsLeftSider onTabClick={handleTabClick}></SettingsLeftSider>
        <div className="md:py-5 md:pl-[46px] w-full md:max-w-[400px]  ">
          {activeTab === "account" && <Profile />}
          {activeTab === "security" && <Security />}
        </div>
      </div>
    </>
  );
}

export default Settings;
