import { FileOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Form, message } from "antd";
import { useEffect, useState } from "react";
import Constants from "../../../constants/Constants";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import CandidatePreviewService from "../../../Services/Candidate/CandidatePreviewService";
import dayjs from "dayjs";
import API from "../../../API";
import { AxiosError } from "axios";

interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}
interface PreviewProps {
  currentData: () => StepData | void;
  onPreviousClick: () => void;
  campaignId: string;
  setCandidateInforamtionSuccess: (
    candidateInforamtionSuccess: boolean
  ) => void;
  isMobile: boolean;
}

interface FieldOfStudy {
  fieldOfStudyId: string;
  name: string;
}

interface QualificationLevel {
  qualificationLevelId: string;
  name: string;
}

function Preview({
  currentData,
  onPreviousClick,
  campaignId,
  setCandidateInforamtionSuccess,
  isMobile,
}: PreviewProps) {
  const [form] = Form.useForm();
  const stepData = currentData();
  const candidateEmail = retriveCandidateEmail();
  const [aboutYou, setAboutPreview] = useState<any | null>();
  const [allExpereinces, setAllExpereinces] = useState<any | null>();
  const [educations, setEducations] = useState<any | null>();
  const [allSkills, setAllSkills] = useState<any | null>();
  const skillNames = ["Soft Skills", "Hard Skills", "Tech and Tools", "Other"];
  const [fieldOfStudy, setFieldOfStudy] = useState<FieldOfStudy[]>([]);
  const [qualificationLevel, setQualificationLevel] = useState<
    QualificationLevel[]
  >([]);

  useEffect(() => {
    const getPreview = async () => {
      try {
        const data: any = {
          campaignId: campaignId,
          roles: Constants.ROLES[2].value,
          email: candidateEmail,
        };
        const resp = await CandidatePreviewService.getPreview(data);

        if (resp.status == 200) {
          const previewData = resp.data;
          setAboutPreview(previewData.profile);
          setAllExpereinces(previewData.experience);
          setEducations(previewData.education);
          setAllSkills(previewData.skills);
        }
      } catch (error) {
        console.log(error);
        message.error("Sorry something wrong");
      }
    };

    getPreview();
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      const dropdownsString = localStorage.getItem("dropdowns");
      const dropdowns = dropdownsString
        ? JSON.parse(dropdownsString)
        : undefined;

      if (dropdowns != null && dropdowns !== undefined) {
        if (qualificationLevel.length === 0) {
          setQualificationLevel(dropdowns.qualificationLevel);
        }

        if (fieldOfStudy.length === 0) {
          setFieldOfStudy(dropdowns.fieldOfStudy);
        }
      } else {
        const url = "/campaign/dropdowns";
        await API.post(url)
          .then((res) => {
            localStorage.setItem("dropdowns", JSON.stringify(res.data.data));
            setQualificationLevel(res.data.data.qualificationLevel);
            setFieldOfStudy(res.data.data.fieldOfStudy);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    initializeData();
  }, []);

  const getQualificationLevel = (id: string) => {
    const qualification = qualificationLevel.find(
      (level) => level.qualificationLevelId == id
    );

    return qualification?.name;
  };

  const getFieldOdStudy = (id: string) => {
    const field = fieldOfStudy.find((level) => level.fieldOfStudyId == id);

    return field?.name;
  };

  const getSkillNameAccordingToIndex = (ind: number) => {
    return skillNames[ind];
  };

  const getDateDifference = (d1: string, d2: string): any => {
    const formatString = "YYYY-MM-DD";

    const date1 = new Date(Date.parse(d1));
    const date2 = new Date(Date.parse(d2));

    const daysDifference =
      Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);

    const years = Math.floor(daysDifference / 365);
    const remainingDays = daysDifference % 365;
    const months = Math.floor(remainingDays / 30);

    if (years == 0 && months == 0) {
      return `${Math.round(daysDifference)}  ${
        Math.round(daysDifference) > 1 ? "days" : "day"
      }`;
    } else if (years == 0 && months > 0) {
      return `${months}  ${months > 1 ? "months" : "month"}`;
    } else {
      return `${years}  ${years > 1 ? "years" : "year"} ${
        months > 0 ? months : ""
      } ${months > 0 ? (months > 1 ? "months" : "month") : ""}`;
    }
  };

  const onPreviewSubmit = async () => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
      };

      const resp = await CandidatePreviewService.submitPreview(data);

      if (resp.status == 200) {
        setCandidateInforamtionSuccess(true);
        sessionStorage.setItem("candidateSubmitted", "true");
        sessionStorage.setItem("submittedCampaignId", campaignId);
        sessionStorage.removeItem("candidateEmail");
        sessionStorage.removeItem("currentStep");
      }
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  // const skills = [
  //   {
  //     skillType: "Soft Skills",
  //     year: "10 Years",
  //     skills: [
  //       "Creativity",
  //       "Communication",
  //       "Problem-solving",
  //       "Empathy",
  //       "Adaptability",
  //       "Attention to detail",
  //     ],
  //   },
  //   {
  //     skillType: "Hard Skills",
  //     year: "10 Years",
  //     skills: [
  //       "Creativity",
  //       "Communication",
  //       "Problem-solving",
  //       "Empathy",
  //       "Adaptability",
  //       "Attention to detail",
  //     ],
  //   },
  //   {
  //     skillType: "Tech and Tools",
  //     year: "10 Years",
  //     skills: [
  //       "Creativity",
  //       "Communication",
  //       "Problem-solving",
  //       "Empathy",
  //       "Adaptability",
  //       "Attention to detail",
  //     ],
  //   },
  //   {
  //     skillType: "Other",
  //     year: "10 Years",
  //     skills: [
  //       "Creativity",
  //       "Communication",
  //       "Problem-solving",
  //       "Empathy",
  //       "Adaptability",
  //       "Attention to detail",
  //     ],
  //   },
  // ];

  if (!stepData) {
    // Handle case where currentData returns void
    return <div>No data available</div>;
  }

  return (
    <>
      <div className="md-0 mr-0 md:ml-[96px] md:mr-[96px] px-5 md:px-0">
        <div style={{ marginBottom: "16px" }}>
          <p className="m-0 text-sm">{stepData && stepData.stepName}</p>
          <p style={{ margin: "12px 0px" }} className="m-0 text-3xl font-bold">
            {stepData && stepData.subTitle}
          </p>
        </div>
        <div
          // className="md-0 mr-0 md:ml-[96px] md:mr-[96px]"
          style={{ border: "1px solid #E2E2E2" }}
        >
          <div style={{ margin: "10px" }}>
            <div
              style={{
                borderBottom: "1px solid #E2E2E2",
                paddingBottom: "10px",
              }}
            >
              <div className="flex flex-col gap-2 md:justify-between md:flex-row">
                <div>
                  <span style={{ fontSize: "32px", fontWeight: "700" }}>
                    {aboutYou && aboutYou.fullName}
                  </span>
                </div>
                <Button
                  style={{ border: "1px solid #595D62" }}
                  size="large"
                  icon={<FileOutlined />}
                  onClick={() => {
                    window.open(aboutYou && aboutYou.resumeLink, "_blank");
                  }}
                >
                  View Resume
                </Button>
              </div>
              <div
                className="mt-[20px] flex md:flex-row flex-col"
                style={{ fontWeight: "400", fontSize: "16px" }}
              >
                <span style={{ marginRight: "15px", display: "inline-block" }}>
                  <PhoneOutlined style={{ transform: "scaleX(-1)" }} />{" "}
                  {aboutYou && aboutYou.contactNumber}
                </span>
                <span>
                  <MailOutlined /> {aboutYou && aboutYou.email}
                </span>
              </div>
            </div>
            <div style={{ padding: "20px 0px" }}>
              <span style={{ fontWeight: "700", fontSize: "24px" }}>About</span>
              <p style={{ fontWeight: "400", fontSize: "16px" }}>
                {aboutYou && aboutYou.about}
              </p>
            </div>
            <div
              style={{
                display: isMobile ? "block" : "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
              }}
            >
              <div>
                {/* Experience */}
                <div style={{ marginBottom: "30px" }}>
                  <span style={{ fontWeight: "700", fontSize: "24px" }}>
                    Experience
                  </span>
                  {allExpereinces != null &&
                    allExpereinces.map((data: any) => (
                      <div
                        style={{
                          // margin: "10px 0px",
                          padding: "16px 0px",
                          borderBottom:
                            allExpereinces.length > 1
                              ? "2px solid #E2E2E2"
                              : undefined,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p style={{ fontWeight: "700", fontSize: "16px" }}>
                              {data.jobTitle}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {data.companyName} • {data.employmentType} •{" "}
                              {data.location}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#595D62",
                                margin: "5px 0px",
                              }}
                            >
                              {data.endDate != null
                                ? getDateDifference(
                                    data.startDate,
                                    data.endDate
                                  )
                                : dayjs(data.startDate).year() + " - Present"}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#595D62",
                              }}
                            >
                              {data.summary}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "self-start",
                              gap: "10px",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  {/* <div
                // style={{
                //   margin: "0px 16px",
                //   padding: "16px",
                //   borderBottom: "2px solid #E2E2E2",
                // }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <p style={{ fontWeight: "700", fontSize: "16px" }}>
                        Senior UI UX Designer
                      </p>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Insharp Technologies • Full-time • Sri Lanka
                      </p>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#595D62",
                          margin: "5px 0px",
                        }}
                      >
                        2 Years 2 Month
                      </p>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#595D62",
                        }}
                      >
                        We are the crafters of cutting-edge software solutions
                        that provide worldwide services for businesses to
                        digitally transform.
                      </p>
                    </div>
                  </div>
                </div> */}
                </div>
                {/* Education */}
                <div>
                  <span style={{ fontWeight: "700", fontSize: "24px" }}>
                    Education
                  </span>
                  {educations != null &&
                    educations.map((data: any) => (
                      <div
                        style={{
                          // margin: "0px 16px",
                          padding: "16px 0px",
                          borderBottom:
                            educations.length > 1
                              ? "2px solid #E2E2E2"
                              : undefined,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p style={{ fontWeight: "700", fontSize: "16px" }}>
                              {data.institute}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {getQualificationLevel(data.qualificationLevel)}{" "}
                              in {getFieldOdStudy(data.fieldOfStudy)}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#595D62",
                                margin: "5px 0px",
                              }}
                            >
                              {data.endDate != null
                                ? dayjs(data.startDate).format("MMMM YYYY") +
                                  " - " +
                                  dayjs(data.endDate).format("MMMM YYYY")
                                : dayjs(data.startDate).format("MMMM YYYY") +
                                  " - Present"}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#595D62",
                              }}
                            >
                              {data.summary}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "self-start",
                              gap: "10px",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <span style={{ fontWeight: "700", fontSize: "24px" }}>
                  Skills
                </span>

                {allSkills &&
                  Object.entries(allSkills).map((key: any, ind) => {
                    if (Object.keys(key[1]).length > 0) {
                      if (key[0] == "SoftSkills" || key[0] == "HardSkills") {
                        return (
                          <div
                            style={{
                              // margin: "0px 16px",
                              padding: "16px 0px",
                              borderBottom: "2px solid #E2E2E2",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                  }}
                                >
                                  {getSkillNameAccordingToIndex(ind)}
                                </p>

                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    margin: "10px 0px",
                                  }}
                                >
                                  {key[1] &&
                                    key[1].map((skill: any) => {
                                      return <div>{skill.name}</div>;
                                    })}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "self-start",
                                  gap: "10px",
                                }}
                              ></div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              // margin: "0px 16px",
                              padding: "16px 0px",
                              borderBottom: "2px solid #E2E2E2",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                  }}
                                >
                                  {getSkillNameAccordingToIndex(ind)}
                                </p>

                                {key[1] &&
                                  Object.entries(key[1]).map(
                                    (skill: any, ind) => {
                                      return (
                                        <>
                                          <p
                                            style={{
                                              fontWeight: "700",
                                              fontSize: "16px",
                                              margin: "10px 0px",
                                            }}
                                          >
                                            {skill[0]}
                                          </p>

                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                              flexWrap: "wrap",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {skill[1].map(
                                              (singleSkill: any) => (
                                                <span>{singleSkill}</span>
                                              )
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "self-start",
                                  gap: "10px",
                                }}
                              ></div>
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between w-full">
          <Button className="mt-5 " onClick={onPreviousClick} size="large">
            Previous
          </Button>

          <Button
            type="primary"
            className="mt-5 "
            size="large"
            onClick={onPreviewSubmit}
          >
            Confirm & Submit
          </Button>
        </div>
        <div className="empty-div"></div>
      </div>
    </>
  );
}

export default Preview;
