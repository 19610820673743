import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import usePasswordValidator from "../../../helper/PasswordValidator";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
function Security() {
  const validatePassword = usePasswordValidator();
  const onFinish = async (values: any) => {
    var requestData = {
      email: localStorage.getItem("email"),
      password: values.currentPassword,
      newPassword: values.confirm,
    };

    const url = "/user/change-password";
    await API.post(url, requestData)
      .then((res) => {
        displayToaster("success", res.data.message);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <p className="text-2xl font-bold">Security</p>
        <Form
          name="profile-form"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item
            label=" Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              name="password"
              size="large"
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              name="password"
              placeholder="input password"
              size="large"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              name="password"
              placeholder="input password"
              size="large"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item colon={false}>
            <Button
              type="primary"
              className="w-full"
              htmlType="submit"
              size="large"
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Security;
