import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { copytoClipBoard } from "../../../helper/CopyToClipboard";

interface CopyToUrlButtonProps {
  url: string;
}

function CopyToUrlButton({ url }: CopyToUrlButtonProps) {
  return (
    <>
      <div className="flex flex-col w-full gap-2">
        <p className="text-base">Copy Link</p>
        <div className="flex flex-row items-center justify-between p-3 border border-[#E2E2E2] rounded-lg ">
          <div className="flex flex-row w-full gap-2">
            <LinkOutlined />

            <p className="w-full break-all">{url}</p>
          </div>
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={() => copytoClipBoard(url)}
          ></Button>
        </div>
      </div>
    </>
  );
}

export default CopyToUrlButton;
