import axios from "../API";

export async function getCampaignDetails (data: any) {
  try {
   
    const response = await axios.post('/campaign/campaign-details', data, {
    
    });
    
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
