import { Button, Col, Divider, Modal, Row, Spin, Tag } from "antd";
import {
  EditOutlined,
  EnvironmentOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import API from "../../../API";
import { useState, useEffect } from "react";
import { displayToaster } from "../../../helper/Toaster";
import CopyToUrlButton from "../../../components/buttons/buttons/CopyToUrlButton";
import ShareWithEmailButton from "../../../components/buttons/buttons/ShareWithEmailButton";
import ShareWithFacebookButton from "../../../components/buttons/buttons/ShareWithFacebookButton";
import ShareWithLinkedinButton from "../../../components/buttons/buttons/ShareWithLinkedinButton";
import useNavigation from "../../../helper/UseNavigation";
import { sanitizeHTMLString } from "../../../helper/SanitizeHTMLString";
import Constants from "../../../constants/Constants";
import dayjs from "dayjs";
import ShareCampaign from "../../../components/component/ShareCampaign";

interface CampaignData {
  jobDetails: JobDetails;
  companyDetails: CompanyDetails;
}

interface JobDetails {
  jobTitle: string;
  location: string;
  workMode: string;
  minSal: number;
  maxSale: number;
  currency: string;
  salaryRange: string;
  jobDetails: string;
  employmentType: string;
  deadLineDate: string;
  deadLineTime: string;
  deadLineTimeStr: string;
  status: string;
  isExpired: boolean;
  isShow: boolean;
}

interface CompanyDetails {
  logo: string;
  companyName: string;
  companyDescription: string;
  companyDetails: CompanyInDetail;
}

interface CompanyInDetail {
  size: string;
  industry: string;
  website: string;
}
interface CampaignDetailProps {
  campaignId: string | undefined;
}

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

const CampaignDetail: React.FC<CampaignDetailProps> = ({ campaignId }) => {
  const [campaignData, setcampaignData] = useState<CampaignData>();

  const [campaignUrl, setCampaignUrl] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleNavigation = useNavigation();

  const handleEditPost = async (item: any) => {
    handleNavigation("/campaign", { campaignId: campaignId });
  };

  const openShareModal = async (item: any) => {
    setIsModalOpen(true);
  };
  const closeShareModal = async (item: any) => {
    setIsModalOpen(false);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (campaignId !== undefined && campaignId != null) {
      const url = `${process.env.REACT_APP_ADMIN_FRONTEND_URL}/campaign-post/${campaignId}`;
      setCampaignUrl(url);
    }

    const initializeData = async () => {
      const url = "/campaign/campaign-preview";
      const requestData = {
        email: localStorage.getItem("email"),
        id: campaignId,
      };
      await API.post(url, requestData)
        .then((res) => {
          setcampaignData(res.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if(error.response.data.code === 404){
            handleNavigation("/not-found", null)
          }
          displayToaster("error", error.response.data.message);
          setIsLoading(false);
        });
    };

    initializeData();
  }, [campaignId]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading" fullscreen={true} size="small">
          {content}
        </Spin>
      ) : (
        <div className="flex flex-col md:border md:border-[#E2E2E2] md:flex-row min-h-full">
          <div
            id="campaign-content"
            className="flex flex-col w-full gap-5 py-4 md:px-6  "
          >
            <div className="flex gap-[10px] flex-col advance-text-editor">
              <div className="flex justify-between">
                <p className="m-0 text-2xl font-bold">
                  {campaignData && campaignData.jobDetails.jobTitle}
                </p>
                <div className="flex flex-row gap-[10px]">
                  {campaignData &&
                    !(
                      campaignData.jobDetails.isExpired === true &&
                      Constants.CAMPAIGN_STATUS.PUBLISHED ===
                      campaignData.jobDetails.status
                    ) && (
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={handleEditPost}
                      ></Button>
                    )}

                  {campaignData &&
                    !(
                      campaignData.jobDetails.isExpired === true &&
                      Constants.CAMPAIGN_STATUS.PUBLISHED ===
                      campaignData.jobDetails.status
                    ) && (
                      <Button
                        type="text"
                        icon={<ShareAltOutlined />}
                        onClick={openShareModal}
                      ></Button>
                    )}

                  <Modal
                    visible={isModalOpen}
                    okButtonProps={{ style: { display: "none" } }} // Hide OK button
                    cancelButtonProps={{ style: { display: "none" } }}
                    onCancel={closeShareModal}
                  >
                    <ShareCampaign url={campaignUrl}></ShareCampaign>
                  </Modal>
                </div>
              </div>
              <div className="flex flex-row flex-wrap gap-[10px]">
                <Tag>
                  {campaignData && campaignData.jobDetails.location}{" "}
                  <EnvironmentOutlined />
                </Tag>
                <Tag>
                  {campaignData && campaignData.jobDetails.employmentType}
                </Tag>

                <Tag>{campaignData && campaignData.jobDetails.workMode}</Tag>

                {campaignData && campaignData.jobDetails.isShow ? (
                  <Tag>
                    {campaignData && campaignData.jobDetails.salaryRange}
                  </Tag>
                ) : null}
              </div>{" "}
            </div>

            <div
              className="flex flex-col gap-2 md:flex-row"
            >
              <p className="text-base text-[#595D62] m-0">
                Application Deadline:{" "}</p>
              <p className="text-base text-[#595D62] m-0">
                {campaignData &&
                  dayjs(campaignData.jobDetails.deadLineDate).format(
                    "DD/MM/YYYY"
                  )}
                - {campaignData && campaignData.jobDetails.deadLineTimeStr}
              </p>
            </div>

            <Divider className="m-0" />

            <p className="text-lg font-bold">Job Details</p>
            <p className="text-base">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    campaignData && campaignData.jobDetails.jobDetails
                      ? sanitizeHTMLString(campaignData.jobDetails.jobDetails)
                      : "",
                }}
              />
            </p>
          </div>
          <div
            id="company-content"
            className="max-w-[360px] py-4 px-6  bg-[#F5F5F580]"
          >
            <div>
              {/* when no value found */}
              {/* <Skeleton /> */}
              {campaignData &&
                campaignData.companyDetails.logo !== "" &&
                campaignData.companyDetails.companyDescription !== "" &&
                campaignData.companyDetails.companyDetails.size !== "" &&
                campaignData.companyDetails.companyDetails.industry !== "" &&
                campaignData.companyDetails.companyDetails.website !== "" ? (
                <div className="flex flex-col w-full gap-3">
                  <div>
                    <img
                      style={{ height: "50px" }}
                      src={campaignData && campaignData.companyDetails.logo}
                      alt="company-logo"
                    />
                  </div>
                  <div className="flex flex-col gap-[5px]">
                    <p className="text-lg font-bold">
                      {" "}
                      {campaignData && campaignData.companyDetails.companyName}
                    </p>

                    <p className="text-sm">
                      {campaignData &&
                        campaignData.companyDetails.companyDescription}
                    </p>
                  </div>

                  <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-row gap-[5px]">
                      <p className="text-sm">Size:</p>
                      <p className="text-sm font-bold">
                        {campaignData &&
                          campaignData.companyDetails.companyDetails.size}
                      </p>
                    </div>
                    <div className="flex flex-row gap-[5px]">
                      <p className="text-sm">Industry:</p>
                      <p className="text-sm font-bold">
                        {campaignData &&
                          campaignData.companyDetails.companyDetails.industry}
                      </p>
                    </div>
                    <div className="flex flex-row gap-[5px] ">
                      <p className="text-sm">Website:</p>
                      <p
                        onClick={() => {
                          window.open(
                            campaignData &&
                            campaignData.companyDetails.companyDetails
                              .website,
                            "_blank"
                          );
                        }}
                        className="text-sm font-bold break-all cursor-pointer"
                      >
                        {campaignData &&
                          campaignData.companyDetails.companyDetails.website}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignDetail;
