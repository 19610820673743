import { Button, Card, Divider, Input, Pagination, Skeleton } from "antd";
import ApplicantsGreenIcon from "../../../src/assets/icons/applicantsGreenIcon.png";
import ApplicantsGreyIcon from "../../../src/assets/icons/applicantsGreyIcon.png";
import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import Constants from "../../constants/Constants";
import API from "../../API";
import { displayToaster } from "../../helper/Toaster";
import useNavigation from "../../helper/UseNavigation";

function DashBoardCampaigns() {

  const [activeButton, setActiveButton] = useState(
    Constants.CAMPAIGN_STATUS.ACTIVE
  );
  const [filterCriteria, setFilterCriteria] = useState({
    title: null,
    status: Constants.CAMPAIGN_STATUS.ACTIVE,
  });
  const [campaignData, setCampaignData] = useState<any[] | undefined>(
    undefined
  );

  const handleNavigation = useNavigation();

  const [pageSize, setPageSize] = useState<number>(10);
  const [current, setCurrent] = useState<number>(1);
  
  const onPageChange = (page: number, size: number) => {   
    if(current!== page){
      setCurrent(page);
    }
    if(pageSize !== size){
      setPageSize(size);
      setCurrent(1);
    }
    console.log('current:', current,' Page:', page,' pageSize:', size);

  };
  const [queryParam] = useState({
    type: "all",
    orderBy: "status",
    order: "ASC",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const handleActiveButton = () => {
    setActiveButton(Constants.CAMPAIGN_STATUS.ACTIVE);
    // campaignData?.length}
    setCampaignData([]);
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      status: Constants.CAMPAIGN_STATUS.ACTIVE,
    }));
  };

  const handleClosedButton = () => {
    setActiveButton(Constants.CAMPAIGN_STATUS.CLOSED);
    // Update filterCriteria state with the new status
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      status: Constants.CAMPAIGN_STATUS.CLOSED,
    }));
  };

  const handleDraftButton = () => {
    setActiveButton(Constants.CAMPAIGN_STATUS.DRAFT);
    setFilterCriteria((prevFilterCriteria) => ({
      ...prevFilterCriteria,
      status: Constants.CAMPAIGN_STATUS.DRAFT,
    }));
  };

  const handleSearch = (e: any) => {
    filterCriteria.title = e.target.value;
    initializeData();
  };

  const handleArrowClick = async (item: any) => {
    if (activeButton === Constants.CAMPAIGN_STATUS.DRAFT) {
      handleNavigation("/campaign", { campaignId: item.campaignId });
    } else {
      handleNavigation(`/campaign-post-view/${item.campaignId}`, {
        campaignId: item.campaignId,
        jobTitle: item.title,
      });
    }
  };

  const initializeData = useCallback(async () => {
    const url = "campaign/campaign-search";
    const requestData = {
      email: localStorage.getItem("email"),
      filterCriteria: filterCriteria,
    };
    const params = queryParam;

    setLoading(true);
    await API.post(url, requestData, {
      params: params,
    })
      .then((res) => {
        setCampaignData(res.data.data.campaigns);
        setLoading(false);
        setCurrent(1);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
        setLoading(false);
      });
  }, [filterCriteria, queryParam]);

  useEffect(() => {
    initializeData();
  }, [filterCriteria, activeButton, initializeData]);

  return (
    <div className="flex flex-col gap-[20px]">
      {/* <div className="fixed-div"> */}
        <div className="flex flex-col justify-between gap-4 md:flex-row">
          <p className="m-0 text-2xl font-bold">Campaigns</p>
          <div className="flex flex-row gap-[14px] ">
            <Button
              onClick={handleActiveButton}
              style={{
                backgroundColor:
                  activeButton === Constants.CAMPAIGN_STATUS.ACTIVE
                    ? "#13265C"
                    : "white",
                color:
                  activeButton === Constants.CAMPAIGN_STATUS.ACTIVE
                    ? "white"
                    : "black",
              }}
            >
              {activeButton === Constants.CAMPAIGN_STATUS.ACTIVE && campaignData
                ? `Active • ${!loading ? campaignData?.length : ""}`
                : "Active"}
            </Button>
            <Button
              onClick={handleClosedButton}
              style={{
                backgroundColor:
                  activeButton === Constants.CAMPAIGN_STATUS.CLOSED
                    ? "#13265C"
                    : "white",
                color:
                  activeButton === Constants.CAMPAIGN_STATUS.CLOSED
                    ? "white"
                    : "black",
              }}
            >
              {activeButton === Constants.CAMPAIGN_STATUS.CLOSED && campaignData
                ? `Closed • ${!loading ? campaignData?.length : ""}`
                : "Closed"}
            </Button>
            <Button
              onClick={handleDraftButton}
              style={{
                backgroundColor:
                  activeButton === Constants.CAMPAIGN_STATUS.DRAFT
                    ? "#13265C"
                    : "white",
                color:
                  activeButton === Constants.CAMPAIGN_STATUS.DRAFT
                    ? "white"
                    : "black",
              }}
            >
              {activeButton === Constants.CAMPAIGN_STATUS.DRAFT && campaignData
                ? `Draft • ${!loading ? campaignData?.length : ""}`
                : "Draft"}
            </Button>
          </div>
        </div>
        <Divider style={{ margin: "0px" }}></Divider>

        <Input
          placeholder="Search"
          allowClear
          size="large"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />
      {/* </div> */}



      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <Skeleton loading={loading}>
          {campaignData !== undefined && campaignData.length > 0 &&
            campaignData.slice((current - 1) * pageSize, current * pageSize).map((item, index) => (
              <div key={index} className="">
                <Card className="md:min-w-[250px]  min-w-[180px]   ">
                  <div className="flex flex-col justify-between min-h-[176px] ">
                    <div className="flex flex-col gap-[10px] ">
                      <div className="flex flex-col gap-[5px]">
                        <p className="m-0 text-lg font-bold">{item.title}</p>
                        <p className="m-0 text-sm">
                          {item.location} • {item.employmentType} • {item.workType}
                        </p>
                        <p
                          className="m-0 text-sm"
                          style={{ color: item.isExpired ? "red" : "#595D62" }}
                        >
                          Deadline:{item.deadlineDate} - {item.deadLineTimeStr}
                        </p>
                      </div>
                      <div className="flex gap-[5px]">
                        <img
                          width={24}
                          height={24}
                          src={
                            item.isExpired
                              ? ApplicantsGreyIcon
                              : ApplicantsGreenIcon
                          }
                          alt="applicants-icon"
                        ></img>

                        <p
                          className="m-0 text-[14px]"
                          style={{
                            color: !item.isExpired ? "#40A69F" : "#595D62",
                          }}
                        >
                          {item.numberOfApplicants} Applicants
                        </p>
                      </div>
                    </div>

                    <div
                      className="flex justify-between pt-3 border-t border-gray-200 cursor-pointer "
                      onClick={() => {
                        handleArrowClick(item);
                      }}
                    >
                      <p className="m-0 text-sm ">
                        {/* {item.isExpired &&
                      activeButton === Constants.CAMPAIGN_STATUS.CLOSED
                        ? "View Campaign"
                        : "Continue Editing "} */}
                        {activeButton === Constants.CAMPAIGN_STATUS.DRAFT
                          ? "Continue Editing"
                          : "View Campaign"}
                      </p>

                      <ArrowRightOutlined />
                    </div>
                  </div>
                </Card>
              </div>
            ))}
        </Skeleton>
      </div>
      <div className="text-center mt-8 mb-8">
        <Pagination defaultCurrent={1} defaultPageSize={1} pageSize={pageSize}  current={current} onChange={onPageChange} total={campaignData && campaignData.length > 0 ? campaignData.length : 0} pageSizeOptions={[10, 20, 50, 100]} />
      </div>
    </div>
  );
}

export default DashBoardCampaigns;
