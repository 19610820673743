import AvatarMenu from "../components/menu/AvatarMenu";
import Logo from "../assets/images/logo.svg";
import { useEffect, useState } from "react";
import API from "../API";
import { displayToaster } from "../helper/Toaster";
import { ExtractInitials } from "../helper/ExtractInitials";

function HeaderLayoutWithoutAvatar() {
  return (
   
      <div
        className="flex flex-row justify-between py-[10px] items-center  px-[30px] md:px-[116px]"
        style={{ borderBottom: "1px solid #F5F5F5" }}
      >
        <img src={Logo} className="logo" alt="Icon"></img>
      </div>
    
  );
}

export default HeaderLayoutWithoutAvatar;
