import { Button, Divider } from "antd";
import Success from "../../../assets/icons/successWindowIcon.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useNavigation from "../../../helper/UseNavigation";
import ShareWithEmailButton from "../../../components/buttons/buttons/ShareWithEmailButton";
import ShareWithFacebookButton from "../../../components/buttons/buttons/ShareWithFacebookButton";
import ShareWithLinkedinButton from "../../../components/buttons/buttons/ShareWithLinkedinButton";
import CopyToUrlButton from "../../../components/buttons/buttons/CopyToUrlButton";
import { CloseOutlined } from "@ant-design/icons";
import ShareCampaign from "../../../components/component/ShareCampaign";

function CampaignPublishSuccess() {
  const [campaignUrl, setCampaignUrl] = useState("");

  const location = useLocation();
  const state = location.state;
  const id = state.id;
  const handleNavigation = useNavigation();

  const handleClose = () => {
    handleNavigation("/dashboard", null);
  };
  useEffect(() => {
    if (id !== undefined && id != null) {
      // todo
      const url = `${process.env.REACT_APP_ADMIN_FRONTEND_URL}/campaign-post/${id}`;
      setCampaignUrl(url);
    }
  }, [id]);

  return (
    <>
      
      <div className="flex flex-col items-center justify-start w-full md:h-full ">
      <div className="flex justify-end w-full py-4">
        <Button
          size="large"
          onClick={handleClose}
          className="text-[#222225] w-8 h-8 text-base"
          icon={<CloseOutlined />}
          type="link"
        ></Button>
      </div>
        <div className="flex flex-col items-center gap-4 max-w-[380px] px-6 h-full mt-4 mb-4 md:mt-6">
          <img height={90} width={90} src={Success} alt="Success Icon"></img>

          <p className="m-0 text-3xl font-bold">Successful</p>

          <p className="m-0 text-base text-[#595D62] text-center">
            Campagin published successfully! Share campaign post with everyone!
          </p>
          <Divider className="m-0" />

          {/* <div className="flex flex-col w-full gap-2">
            <p className="text-base">Share via</p>
            <div className="flex flex-row gap-[10px]">
              <ShareWithEmailButton url={campaignUrl}></ShareWithEmailButton>
              <ShareWithFacebookButton
                url={campaignUrl}
              ></ShareWithFacebookButton>
              <ShareWithLinkedinButton
                url={campaignUrl}
              ></ShareWithLinkedinButton>
            </div>
          </div>

          <CopyToUrlButton url={campaignUrl}></CopyToUrlButton> */}
          <ShareCampaign url={campaignUrl}></ShareCampaign>
        </div>
      </div>
    </>
  );
}

export default CampaignPublishSuccess;
