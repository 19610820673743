import { Button, message } from "antd";
import { SetStateAction, useEffect, useState } from "react";
import CampaignLeftSider from "../../../components/leftsider/CampaignLeftSider";
import Constants from "../../../constants/Constants";
import GeneralDetails from "./GeneralDetails";
import Skills from "./Skills";
import Preview from "./Preview";
import { StepComponent } from "../../../components/Steps/StepComponent";
import {
  FileOutlined,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import CustomModal from "../../../components/Modals/CustomModal";
import useNavigation from "../../../helper/UseNavigation";
import CampaignMenu from "../../../components/menu/CampaignMenu";

function Campaign() {
  const [currentStep, setCurrentStep] = useState(0);
  const [hideComponent, setHideComponent] = useState(true);
  const [subHeadingTopics, setSubHeadingTopics] = useState(
    Constants.CAMPAIGN_SUB_HEADINGS_STEPS
  ); // State to track the current step
  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(true);
  const [campaignId, setCampaignId] = useState<string | undefined>(); // Define campaignId state variable
  const [isValid, setIsValid] = useState(false);
  const [clickSaveDraft, setClickSaveDraft] = useState(false);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [skillsSectionCompleted, setSkillsSectionCompleted] =
    useState<boolean>(false);

  const [generalSectionCompleted, setGeneralSectionCompleted] =
    useState<boolean>(false);

  useEffect(() => {

    window.scrollTo(0, 0);


    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768); // You can adjust the threshold as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalData = {
    title: "",
    imageUrl: "",
    okText: "Yes, Save it",
    cancelText: "No, Keep editing",
    bodyTitle: "Draft Campaign",
    bodySubTitle: "Are you sure you want to save to draft?",
    bodyIcon: (
      <QuestionCircleOutlined style={{ fontSize: "100px", color: "#13265C" }} />
    ),
  };

  const handleStepChange = (step: SetStateAction<number>) => {
    if (!generalSectionCompleted) {
      message.error("Complete General Details Secton.");
    } else if (!skillsSectionCompleted && step == 2) {
      message.error("Complete Skills Section");
    } else {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    changeSubheadingTopics(currentStep);
  }, [currentStep]);

  function changeSubheadingTopics(step: SetStateAction<number>) {
    if (step == 0) {
      // populate subheadings for left sider
      setSubHeadingTopics(Constants.CAMPAIGN_SUB_HEADINGS_STEPS);
      setHideComponent(true);
    }
    if (step == 1) {
      // populate subheadings for left sider
      setSubHeadingTopics(Constants.SKILLS_SUB_HEADINGS_STEPS);
      setHideComponent(true);
    }
    if (step == 2) {
      // hide left sider when click on preview tab
      setHideComponent(false);
    }
  }

  const handleSavetoDraft = () => {
    setShowSaveDraftModal(true);
  };

  const handleModalClose = () => {
    // console.log("handleModalClose...........")
    setShowSaveDraftModal(false);
  };

  const handleClickSaveDraft = () => {
    // console.log("handleClickSaveDraft...........")
    setClickSaveDraft(true);
  };

  const handleNavigation = useNavigation();

  const goBack = () => {
    sessionStorage.removeItem("createdCampaignId");
    handleNavigation("/dashboard", null);
  };

  const handleCampaignIdChange = (id: string) => {
    setCampaignId(id);
  };

  const handleIsValid = (value: boolean) => {
    setIsValid(value);
  };

  return (
    <>
      <div className="relative flex flex-col ">
        <div className="flex items-center w-full py-2 bg-white px-[30px] border-b border-gray-200 md:px-[116px] z-50	 sticky top-0  ">
          <div className="flex items-center justify-between w-full ">
            <div className="flex items-center gap-2">
              <Button
                size="large"
                icon={<ArrowLeftOutlined />}
                onClick={goBack}
              />

              <p className="m-0 text-xl font-bold">Create New Campaign</p>
            </div>

            <Button
              size="large"
              icon={<FileOutlined />}
              onClick={handleSavetoDraft}
              disabled={disableDraftButton}
              className="hidden md:block"
            >
              Save to Draft
            </Button>

            <Button
              size="large"
              icon={<FileOutlined />}
              onClick={handleSavetoDraft}
              disabled={disableDraftButton}
              className=" hide-md"
            ></Button>
          </div>
        </div>

        <div className="flex px-[30px] md:px-[116px] w-full mt-5 md:mt-[57px] mb-5 md:fixed  web-body  ">
          {/* {currentStep} */}
          {showSaveDraftModal && (
            <CustomModal
              onClose={handleModalClose}
              visible={showSaveDraftModal}
              modalData={modalData}
              onOk={handleClickSaveDraft}
            />
          )}
          {hideComponent && (
            <CampaignLeftSider
              CAMPAIGN_SUB_HEADINGS_STEPS={subHeadingTopics}
            ></CampaignLeftSider>
          )}
          <div className="flex flex-col w-full md:mt-[30px]">
            <div className="flex flex-col w-full gap-10 max-w-[720px]">
              {/* Render content based on the current step */}
              {currentStep === 0 && (
                <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] ">
                  <div className="flex flex-row justify-between w-full md:justify-center">
                    <div className="sm:w-full ">
                    {StepComponent(
                      currentStep,
                      Constants.MAIN_CAMPAIGN_STEPS,
                      handleStepChange,
                      isMobile
                    )}</div>
                    <div>
                    <CampaignMenu
                      steps={Constants.CAMPAIGN_SUB_HEADINGS_STEPS[0].heading}
                    ></CampaignMenu>
                  </div>
                  </div>

                  

                  <GeneralDetails
                    disableDraftButton={setDisableDraftButton}
                    onCampaignIdChange={handleCampaignIdChange}
                    onIsValidChange={handleIsValid}
                    campaignId={campaignId}
                    onNextClick={() => handleStepChange(currentStep + 1)}
                    clickSaveDraft={setClickSaveDraft}
                    saveDraftFlag={clickSaveDraft}
                    setGeneralSectionCompleted={setGeneralSectionCompleted}
                    setCurrentStep={setCurrentStep}
                  />
                </div>
              )}
              {currentStep == 1 && (
                <div className=" w-[full] flex flex-col gap-[50px]">
                  <div className="flex justify-center w-[full] ">
                    {StepComponent(
                      currentStep,
                      Constants.MAIN_CAMPAIGN_STEPS,
                      handleStepChange,
                      isMobile
                    )}
                  </div>
                  <div>
                    <CampaignMenu
                      steps={Constants.SKILLS_SUB_HEADINGS_STEPS[0].heading}
                    ></CampaignMenu>
                  </div>

                  <Skills
                    disableDraftButton={setDisableDraftButton}
                    campaignId={campaignId}
                    onIsValidChange={handleIsValid}
                    onNextClick={() => handleStepChange(currentStep + 1)}
                    onPreviousClick={() => handleStepChange(currentStep - 1)}
                    clickSaveDraft={setClickSaveDraft}
                    saveDraftFlag={clickSaveDraft}
                    setSkillsSectionCompleted={setSkillsSectionCompleted}
                    setCurrentStep={setCurrentStep}
                  />
                </div>
              )}
            </div>
            {currentStep == 2 && (
              <div className=" w-[full] flex flex-col gap-2">
                <div className="flex md:justify-center w-full justify-left">
                  {StepComponent(
                    currentStep,
                    Constants.MAIN_CAMPAIGN_STEPS,
                    handleStepChange,
                    isMobile
                  )}
                </div>
                <Preview
                  campaignId={campaignId}
                  onPreviousClick={() => handleStepChange(currentStep - 1)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Campaign;
