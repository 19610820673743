import { Button } from "antd";
import GroupIcon from "../../../src/assets/icons/groupIcon.png";
import { Link } from "react-router-dom";

function DashBoardNoCompanyProfile() {
  return (
    <div className="flex flex-col items-center justify-center min-h-[70dvh]">
      <div className="max-w-[342px] flex flex-col items-center justify-center gap-[32px] ">
        <img width={200} height={200} src={GroupIcon} alt="group-icon" />

        <p className="m-0 text-base text-center">
          Complete your company profile to attract top talent & build trust!
        </p>

        <Link to="/company-profile">
          <Button className="primary-button" htmlType="submit" size="large">
            Complete Company Profile
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default DashBoardNoCompanyProfile;
