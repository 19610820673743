import {
  Button,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Modal,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import CandidateEducationService from "../../../Services/Candidate/CandidateEducationService";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import Constants from "../../../constants/Constants";
import { AxiosError } from "axios";
const { Option } = Select;

interface FieldOfStudy {
  fieldOfStudyId: string;
  name: string;
}

interface QualificationLevel {
  qualificationLevelId: string;
  name: string;
}
interface EducationEditModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  fieldOfStudy: FieldOfStudy[];
  qualificationLevel: QualificationLevel[];
  educationEditId: number | null;
  campaignId: string;
  renderEducation: () => void;
}

function EducationEditModal({
  isModalOpen,
  setIsModalOpen,
  fieldOfStudy,
  qualificationLevel,
  educationEditId,
  campaignId,
  renderEducation,
}: EducationEditModalProps) {
  const [form] = Form.useForm();
  const [isStuding, setIsStuding] = useState(false);
  const [studyStartDate, setStudyStartDate] = useState<any>(null);
  const [studyEndDate, setStudyEndDate] = useState<any>(null);
  const dateFormat = "DD/MM/YYYY";
  const dateFormatToDataBase = "YYYY-MM-DD";
  const dummyData = {
    studyEndDate: "30/05/2024",
    currentlyStuding: false,
    fieldOfStudy: 100001,
    instituteName: "test",
    qualification: "test",
    qualificationLevel: 1,
    studyStartDate: "01/05/2024",
    summary: "test test",
  };

  useEffect(() => {
    const fetchData = async () => {
      if (educationEditId != null && isModalOpen) {
        try {
          const candidateEmail = retriveCandidateEmail();

          const info: any = {
            campaignId: campaignId,
            email: candidateEmail,
            roles: Constants.ROLES[2].value,
            id: educationEditId,
          };

          const resp = await CandidateEducationService.getEducatoinById(
            info,
            educationEditId
          );

          const candidateData = resp.data.userData;

          const data = {
            studyEndDate:
              candidateData.endDate != null
                ? dayjs(
                    dayjs(candidateData.endDate).format(dateFormat),
                    dateFormat
                  )
                : null,
            currentlyStuding: candidateData.isStudying,
            fieldOfStudy: candidateData.fieldOfStudy,
            instituteName: candidateData.institute,
            qualification: candidateData.qualification,
            qualificationLevel: candidateData.qualificationLevel,
            studyStartDate: dayjs(
              dayjs(candidateData.startDate).format(dateFormat),
              dateFormat
            ),
            summary: candidateData.summary,
          };

          setStudyStartDate(
            dayjs(dayjs(candidateData.startDate).format(dateFormat), dateFormat)
          );
          setStudyEndDate(
            candidateData.endDate != null
              ? dayjs(
                  dayjs(candidateData.endDate).format(dateFormat),
                  dateFormat
                )
              : null
          );
          setIsStuding(candidateData.isStudying);

          form.setFieldsValue(data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const disabledDateStudyStartDate = (current: dayjs.Dayjs | null): boolean => {
    if (studyEndDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isAfter(studyEndDate, "day") ||
            dayjs(current).isSame(studyEndDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const disabledDateStudyEndDate = (current: dayjs.Dayjs | null): boolean => {
    if (studyStartDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isBefore(studyStartDate, "day") ||
            dayjs(current).isSame(studyStartDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const onFinish = async (values: any) => {
    const candidateEmail = retriveCandidateEmail();
    try {
      const data: any = {
        email: candidateEmail ? candidateEmail : "",
        roles: Constants.ROLES[2].value,
        companyName: values.companyName,
        institute: values.instituteName,
        fieldOfStudy: values.fieldOfStudy,
        qualificationLevel: values.qualificationLevel,
        qualification: values.qualification,
        startDate: values.studyStartDate.format(dateFormatToDataBase),
        endDate: isStuding
          ? null
          : values.studyEndDate.format(dateFormatToDataBase),
        isStudying: isStuding,
        summary: values.summary,
        campaignId: campaignId,
        id: educationEditId,
      };

      const resp = await CandidateEducationService.updateEducation(data);
      message.success("Sucessfully updated your education");
      renderEducation();
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);

      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  return (
    <>
      <Modal
        title="Edit Education"
        onCancel={handleCancel}
        open={isModalOpen}
        footer={false}
      >
        <Form
          form={form}
          name="campaign-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div>
            {/* <p id="general-details" className="mb-4 text-lg font-bold">
                  General Details
                </p> */}

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Institute Name*
              </span>
              <Form.Item
                name="instituteName"
                rules={[
                  {
                    required: true,
                    message: "Institute Name is required",
                  },
                ]}
              >
                <Input placeholder="Type your institute name" size="large" />
              </Form.Item>
            </div>

            <div
            className="grid grid-cols-1 gap-4 md:grid-cols-2"
             
            >
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    display: "inline-block",
                  }}
                >
                  Field of study*
                </span>
                <Form.Item
                  name="fieldOfStudy"
                  rules={[
                    {
                      required: true,
                      message: "Field of study is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    size="large"
                    showSearch
                    filterOption={(input, option) => {
                      if (!option || !option.children) return false;
                      return option.children
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {fieldOfStudy.map((study) => (
                      <Option
                        key={study.fieldOfStudyId}
                        value={study.fieldOfStudyId}
                      >
                        {study.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    display: "inline-block",
                  }}
                >
                  Qualifications Level*
                </span>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Qualifications Level is required",
                    },
                  ]}
                  name="qualificationLevel"
                >
                  <Select placeholder="Select" size="large">
                    {qualificationLevel.map((level) => (
                      <Option
                        key={level.qualificationLevelId}
                        value={level.qualificationLevelId}
                      >
                        {level.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Qualifications*
              </span>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Qualifications is required",
                  },
                ]}
                name="qualification"
              >
                <Input
                  placeholder="B.Sc. (Hons) in Multimedia Technology"
                  size="large"
                />
              </Form.Item>
            </div>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Study Period*
              </span>
              <div
                className="grid grid-cols-1 gap-4 md:grid-cols-2"
              >
                <Form.Item
                  name="studyStartDate"
                  rules={[
                    {
                      required: true,
                      message: "Start Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select start date"
                    style={{ width: "100%" }}
                    size="large"
                    format={dateFormat}
                    disabledDate={disabledDateStudyStartDate}
                    onChange={setStudyStartDate}
                    inputReadOnly={true}
                  />
                </Form.Item>
                {!isStuding && (
                  <Form.Item
                    name="studyEndDate"
                    rules={[
                      {
                        required: true,
                        message: "End Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select end date"
                      style={{ width: "100%" }}
                      size="large"
                      format={dateFormat}
                      disabledDate={disabledDateStudyEndDate}
                      onChange={setStudyEndDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                )}
              </div>
            </div>
            <Form.Item name="currentlyStuding">
              <Checkbox
                checked={isStuding}
                onChange={(e) => {
                  setIsStuding(e.target.checked);
                  if (e.target.checked) {
                    setStudyEndDate(null);
                    form.setFieldsValue({
                      studyEndDate: null,
                    });
                  }
                }}
              >
                I currently study here
              </Checkbox>
            </Form.Item>

            <div>
              <span
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  display: "inline-block",
                }}
              >
                Summary*
              </span>

              <Form.Item
                name="summary" // Add a unique name
                rules={[
                  {
                    required: true,
                    message: "Summary is required",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: "132px" }}
                  size="large"
                  placeholder="Summary"
                />
              </Form.Item>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default EducationEditModal;
