import React, { useState, ChangeEventHandler, useEffect } from "react";
import {
  Button,
  Input,
  Select,
  Table,
  Tag,
  message,
  Checkbox,
  Empty,
  Pagination
} from "antd";
import {
  CloudDownloadOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { TableRowSelection } from "antd/lib/table/interface";
import CandidateGeneralService from "../../../Services/Candidate/CandidateGeneralService";
import { AxiosError } from "axios";
import ExportModal from "./ExportModal";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface DataSourceItem {
  key: string;
  name: string;
  email: string;
  contact: string;
  location: string;
  resume: string;
  percentage: string;
}

interface AllApplicantsViewProps {
  setViewCandidateDetails: (viewCandidateDetails: boolean) => void;
  setCandidateName: (candidateName: string) => void;
  campaignId: string | undefined;
  setCandidateEmail: (candidateEmail: string) => void;
  allApplicants: any[];
}

const AllApplicantsView: React.FC<AllApplicantsViewProps> = ({
  setViewCandidateDetails,
  setCandidateName,
  campaignId,
  setCandidateEmail,
  allApplicants,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [filterAllApplicants, setFilterAllApplicants] = useState<
    any[] | undefined
  >(allApplicants);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [exportProfiles, setExportProfiles] = useState<any>();

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onPageChange = (page: React.SetStateAction<number>) => {
    console.log(page);
    setCurrent(page);
  };
  const onShowPageSizeChange = (size: React.SetStateAction<number>) => {
    console.log('Page size:', size);
    setPageSize(size);
    setCurrent(1); 
  };
  const emptyState = <Empty description={<span>No applicants</span>} />;

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768); // You can adjust the threshold as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setExportProfiles(() => {
      const filterApplicants = allApplicants.filter((applicant: any) =>
        selectedRowKeys.includes(applicant.key)
      );
      return filterApplicants?.map((obj) => {
        const { key: _, about: __, ...newObj } = obj;
        return newObj;
      });
    });
  }, [selectedRowKeys]);

  const percentageColor = (value: any) => {
    const numberValue = Number(value);
    if (numberValue >= 0 && numberValue < 50) {
      return "#FF4E64";
    } else if (numberValue >= 50 && numberValue <= 75) {
      return "#FFB319";
    } else {
      return "#40A69F";
    }
  };

  const onViewCandidate = (row: any) => {
    setCandidateName(row.fullname);
    setCandidateEmail(row.email);
    setViewCandidateDetails(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   key: "location",
    // },
    {
      title: "Resume",
      render: (resume: string, row: any) => (
        <span onClick={() => onViewCandidate(row)} className="cursor-pointer">
          <ExportOutlined style={{ transform: "scaleX(-1)" }} />{" "}
          <span className="underline">View</span>
        </span>
      ),
      key: "resume",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      render: (tag: any) => (
        <Tag color={percentageColor(tag)} key={tag}>
          {tag}%
        </Tag>
      ),
      key: "percentage",
      sorter: (a: any, b: any) => a.percentage - b.percentage,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataSourceItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onExportClick = () => {
    console.log("exportProfiles", exportProfiles);

    setIsModalOpen(true);
  };

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value: string = event.target.value;

    if (!value) {
      setFilterAllApplicants(allApplicants);
    } else {
      const filteredApplicants = allApplicants.filter((applicant: any) =>
        applicant.fullname.toLowerCase().startsWith(value.toLocaleLowerCase())
      );
      setFilterAllApplicants(filteredApplicants);
    }
  };

  return (
    <div>
      <h2 style={{ marginBottom: "27px" }} className="text-2xl font-bold">
        Applicants • {filterAllApplicants?.length}
      </h2>
      <div style={{ marginBottom: "27px" }} className="flex flex-col justify-between gap-2 md:flex-row">
        <div className="flex gap-4 ">
          <Input
          className="md:w-[300px] w-full"
            size="middle"
            placeholder="Search"
            prefix={<SearchOutlined />}
           
            onChange={onSearch}
          />
          {/* <Select
            style={{ width: "200px" }}
            size="middle"
            placeholder="Filter Order"
            options={[
              {
                value: "1",
                label: "Best 5 Application",
              },
              {
                value: "2",
                label: "Best 10 Application",
              },
              {
                value: "3",
                label: "Best 15 Application",
              },
            ]}
            allowClear={true}
          ></Select> */}
        </div>
        <Button
          disabled={selectedRowKeys.length > 0 ? false : true}
          onClick={onExportClick}
          type="primary"
          size={"middle"}
          style={{ width: isMobile ? "102px" : "default" }}
        >
          <span>Export</span>
          <CloudDownloadOutlined />
        </Button>
      </div>

      {isMobile ? (
        <div>
          {filterAllApplicants && filterAllApplicants.length > 0 ? (
            filterAllApplicants.slice((current - 1) * pageSize, current * pageSize).map((applicant: any) => {
              return (
                <>
                  <div className="flex justify-between mb-4">
                    <div className="flex">
                      <span className="block mr-2">
                        <Checkbox
                          checked={selectedRowKeys.includes(applicant.key)}
                          onChange={(e: CheckboxChangeEvent) => {
                            const checked = e.target.checked;

                            if (checked) {
                              setSelectedRowKeys([
                                applicant.key,
                                ...selectedRowKeys,
                              ]);
                            } else {
                              setSelectedRowKeys(
                                selectedRowKeys.filter(
                                  (item) => item != applicant.key
                                )
                              );
                            }
                          }}
                        />
                      </span>
                      <div>
                        <div className="mb-2">
                          <span className="block">{applicant.fullname}</span>
                          <span className="block">{applicant.email}</span>
                          <span className="block">
                            {applicant.contactNumber}
                          </span>
                        </div>

                        <span className="block">
                          <span
                            onClick={() => onViewCandidate(applicant)}
                            className="cursor-pointer"
                          >
                            <ExportOutlined
                              style={{ transform: "scaleX(-1)" }}
                            />{" "}
                            <span className="underline">View</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <Tag
                      color={percentageColor(applicant.percentage)}
                      key={applicant.percentage}
                    >
                      {applicant.percentage}%
                    </Tag>
                  </div>
                </>
              );
            })
          ) : (
            <Empty description="No applicants" />
          )}
        <div className="text-center mt-8 mb-8">
        <Pagination pageSize={pageSize} defaultPageSize={pageSize} onShowSizeChange={onShowPageSizeChange} current={current} onChange={onPageChange} total={filterAllApplicants && filterAllApplicants.length > 0 ? filterAllApplicants.length : 0} />
        </div>
         
        </div>
      ) : (
        <Table
          rowSelection={rowSelection}
          dataSource={filterAllApplicants}
          columns={columns}
          className="antd-table"
          locale={{ emptyText: emptyState }}
        />
      )}
      <ExportModal
        exportProfiles={exportProfiles}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onFinishExport={() => {
          setSelectedRowKeys([]);
        }}
      />
    </div>
  );
};

export default AllApplicantsView;
