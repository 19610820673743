import Constants from "../constants/Constants";

function useCoookieHandler() {

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop()?.split(';').shift();
        }
        return undefined;
    };

    const decodeToken = (token: string) => {
        try {
            // Decode the token
            if(token != null && token !== undefined && token !== 'undefined'){
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const decodedToken = JSON.parse(atob(base64));
                return decodedToken;
            }

        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    const checkAuthentication = async () => {
        try {
            // Retrieve the JWT token from the cookie
            const token = getCookie(Constants.JWT_TOKEN);

            if (token) {
                // Decode the JWT token to determine if it's valid
                const decodedToken = decodeToken(token);
                // Check if the token is expired or if it's valid
                if (decodedToken && decodedToken.exp && decodedToken.exp > Date.now() / 1000) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error checking authentication:', error);
            return false;
        }
    };

    const getToken = async () => {
        try {
            // Retrieve the JWT token from the cookie
            const token = getCookie(Constants.JWT_TOKEN);

            if (token) {
                // Decode the JWT token to determine if it's valid
                const decodedToken = decodeToken(token);
                // Check if the token is expired or if it's valid
                if (decodedToken && decodedToken.exp && decodedToken.exp > Date.now() / 1000) {
                    return decodedToken;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error checking authentication:', error);
            return false;
        }
    };

    // Define an object containing all the methods you want to expose
    const authMethods = {
        getCookie,
        decodeToken,
        checkAuthentication,
        getToken
    };

    // Return the object containing the methods
    return authMethods;
}

export default useCoookieHandler;


