import { Button, Input, Form } from "antd";
import useNavigation from "../../../helper/UseNavigation";
import {
  ArrowLeftOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { Link, useLocation } from "react-router-dom";
import usePasswordValidator from "../../../helper/PasswordValidator";

function SetNewPassword() {
  // navigation helper function
  const handleNavigation = useNavigation();
  const validatePassword = usePasswordValidator();
  const location = useLocation();
  const state = location.state;

  const handleSetNewPassword = async (values: any) => {
    const email =
      values.email != null && values.email !== undefined
        ? values.email
        : localStorage.getItem("email");
    const newPassword = values.password;
    const otp = state.otp;
    var requestData = {
      email: email,
      code: otp,
      password: newPassword,
    };

    const url = "/user/reset-password";
    await API.post(url, requestData)
      .then((res) => {
        // toaster
        displayToaster("success", res.data.message);
        handleNavigation("/pw-update-success", null);
      })
      .catch((error) => {
        // toaster
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <>
      <div className="flex justify-center w-full h-full align-center px-[30px]">
        <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
          <div className="flex flex-col gap-[14px]">
            <p className="m-0 text-[32px] font-bold">Set new password</p>
            <p className="m-0 text-base">
              Your new password must be different to previously used passwords.
            </p>
          </div>

          <Form
            name="content-body-form"
            layout="vertical"
            autoComplete="off"
            onFinish={handleSetNewPassword}
            scrollToFirstError
          >
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                size="large"
                name="password"
                placeholder="input password"
                className="input-fields"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm"
              rules={[
                {
                  required: true,
                  validator: validatePassword,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name="password"
                placeholder="input password"
                className="input-fields"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item colon={false}>
              <Button
                type="primary"
                className="w-full mt-2"
                htmlType="submit"
                size="large"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>

          <div className="flex justify-center w-full">
            <Link to="/signin">
              <Button
                className="text-[#595D62]"
                type="text"
                size="large"
                icon={<ArrowLeftOutlined />}
              >
                Back to log in
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetNewPassword;
