import { Button, Input, Form, GetProp } from "antd";
import { useState } from "react";
import useNavigation from "../../../helper/UseNavigation";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../helper/AuthContext";
import Constants from "../../../constants/Constants";
import { ArrowLeftOutlined } from "@ant-design/icons";

function VerifyPassword() {
  const authHandler = useAuth();

  // navigation helper function
  const handleNavigation = useNavigation();

  const location = useLocation();
  const [form] = Form.useForm();
  const state = location.state;
  const [isClickable, setIsClickable] = useState(false);

  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    // console.log('onChange:', text);
    form.setFieldValue("verifycode", text);
  };

  const sharedProps = {
    onChange,
  };

  const handleResendEmail = () => {
    const email = state.email;
    const url = "/auth/send-verification-email";

    var requestData = {
      email: email,
      roles: Constants.ROLES[0].value,
    };
    API.post(url, requestData)
      .then((res) => {
        // toaster
        setIsClickable(true);
        displayToaster("success", res.data.message);
        setTimeout(() => {
          setIsClickable(false);
        }, 60000); // 60 seconds
      })
      .catch((error) => {
        // toaster
        displayToaster("error", error.response.data.message);
      });
  };

  const handleVerify = async (values: any) => {
    try {
      const code = values.verifycode;
      const email = state.email;
      const password =
        state.password != null &&
        state.password !== undefined &&
        state.password !== "undefined"
          ? state.password
          : null;
      // Handle verification logic here

      let url;
      let location: string | null | undefined;
      let props: { email: any; otp: string } | null;

      if (state.type === "reset-password") {
        url = "/auth/reset-password/verifiy-user";
        location = "/set-new-password";
        props = { email: email, otp: code };
      } else {
        url = "/auth/signin/verifiy-user";
        location = "/dashboard";
        props = null;
      }

      var requestData = {
        email: email,
        password: password,
        token: code,
        type: state.type,
        roles: Constants.ROLES[0].value,
      };

      await API.post(url, requestData)
        .then(async (res) => {
          // toaster
          displayToaster("success", res.data.message);

          if (email !== null) {
            localStorage.setItem("email", email);
          }

          authHandler.login(res.data.accessToken);

          if (location != null && location !== undefined) {
            // Redirect to dashboard upon successful sign-insetIsClickable
            var data = {
              email: email,
            };
            const response = await API.post("user/view-profile", data);

            if (
              response != null &&
              response !== undefined &&
              response.data.data.isWorkSpaceCreated === false &&
              response.data.data.isCompanyProfileCreated === false
            ) {
              location = "/empty-dashboard";
            }
            handleNavigation(location, props);
          }
        })
        .catch((error) => {
          // toaster
          displayToaster("error", error.response.data.message);
        });
    } catch (error) {}
  };

  return (
    <div className="flex justify-center w-full h-full align-center px-[30px]">
      <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8 ">
        <div className="flex flex-col gap-[14px]">
          <p className="m-0 text-[32px] font-bold leading-none">
            Verify your email
          </p>
          <p className="m-0 text-base text-[#595D62] leading-none">
            We sent your code to {state.email}.
          </p>
        </div>
        <div className="flex flex-col w-full gap-6">
          <Form
            form={form}
            className="w-full"
            name="verify-password-form"
            onFinish={handleVerify}
            scrollToFirstError
            autoComplete="off"
          >
            <Form.Item
              name="verifycode"
              className="flex w-full otpinput"
              rules={[
                {
                  required: true,
                  message: "verification code is required",
                },
              ]}
            >
              <Input.OTP
                size="large"
                className="w-full m-0"
                style={{ textAlign: "center" }}
                {...sharedProps}
              />
            </Form.Item>

            <Form.Item colon={false}>
              <Button
                type="primary"
                className="w-full "
                htmlType="submit"
                size="large"
              >
                Verify
              </Button>
            </Form.Item>
            <div className="flex items-center justify-center w-full">
              <p className="pr-1 m-0 text-base">Didn't get code? </p>
              {isClickable ? (
                <p className="m-0 text-base text-[#22222540]">Resend in 60s</p>
              ) : (
                <Button
                  className="p-0 text-base "
                  type="link"
                  htmlType="reset"
                  onClick={handleResendEmail}
                >
                  Resend
                </Button>
              )}
            </div>
          </Form>
        </div>
        <div className="flex justify-center w-full">
          <Link to="/register">
            <Button
              className="text-[#595D62]"
              type="text"
              size="large"
              icon={<ArrowLeftOutlined />}
            >
              Back to sign up
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VerifyPassword;
