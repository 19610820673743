import {
  Button,
  Input,
  Form,
  Select,
  Popover,
  notification,
  Upload,
} from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import Constants from "../../../constants/Constants";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import useNavigation from "../../../helper/UseNavigation";
import ImgCrop from "antd-img-crop";
import { UploadCompanyLogo } from "../../../helper/UploadCompanyLogo";
const { TextArea } = Input;
const { Option } = Select;

function CompanyProfile() {
  const [companyData, setCompanyData] = useState<{
    name: string;
    about: string;
    industry: string;
    logo: string;
    numberOfEmployees: string;
    website: string;
  } | null>(null);

  const handleNavigation = useNavigation();

  const [api, contextHolder] = notification.useNotification();
  const [companyForm] = Form.useForm();

  const industries = Constants.INDUSTRIES;

  const numberOfEmployees = Constants.NUMBER_OF_EMPLOYEES;

  const content = (
    <ul>
      <li>Aspect Ratio: 1:1 (Square)</li>
      <li>Formats: JPG, PNG</li>
      <li>Max File Size: 5MB</li>
      <li>Min Dimensions: 200x200px</li>
    </ul>
  );

  const [imageFile, setImageFile] = useState();

  const getCompanyData = async () => {
    const url = "/company/view-company-profile";
    var requestData = {
      email: localStorage.getItem("email"),
    };
    await API.post(url, requestData)
      .then(async (res) => {
        setCompanyData(res.data.data);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };
  const setLogoData = (logoUrl: any) => {
    setImageFile(logoUrl);
    companyForm.setFieldValue("logo", logoUrl);
  };

  const handleOk = async (blob: any) => {
    try {
      if (blob != null && blob !== undefined) {
        const reader = new FileReader();
        // Pass the imageFile to the FileReader instance
        reader.readAsDataURL(blob);
        reader.onload = async () => {
          const base64Data = reader.result as string;
          const requestData = {
            image: base64Data,
            contentType: blob.type,
          };

          const res = await UploadCompanyLogo(requestData);
          const logoUrl = res.data.url;
          setLogoData(logoUrl);
        };
      } else {
        // console.log("imageFile:", blob);
        displayToaster("error", "Image not selected!");
      }
    } catch (error: any) {
      displayToaster(
        "error",
        error.response?.data?.message || "An error occurred"
      );
    }
  };

  const validateURL = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error("Please input a URL!"));
    }
    // Regular expression for URL validation
    const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
    if (!urlRegex.test(value)) {
      return Promise.reject(new Error("Please enter a valid URL!"));
    }
    return Promise.resolve();
  };

  // handles normal signup function
  const onFinish = async (value: any) => {
    if (value != null && value !== undefined) {
      var requestData = {
        email: localStorage.getItem("email"),
        name: value.companyname,
        logo: value.logo,
        industry: value.industry,
        numberOfEmployees: value.employeeNumber,
        about: value.aboutcompany,
        website: value.website,
      };
      const url = "/company/update-company-profile";
      await API.post(url, requestData)
        .then((res) => {
          // toaster
          displayToaster("success", res.data.message);
          handleNavigation("/dashboard", null);
        })
        .catch((error) => {
          // toaster
          displayToaster("error", error.response.data.message);
        });
    }
  };

  const handleClose = () => {
    handleNavigation("/dashboard", null);
  };

  useEffect(() => {
    if (companyData == null || companyData === undefined) {
      getCompanyData();
    }

    if (companyData) {
      companyForm.setFieldsValue({
        companyname: companyData.name,
        aboutcompany: companyData.about,
        industry: companyData.industry,
        logo: companyData.logo,
        employeeNumber: companyData.numberOfEmployees,
        website: companyData.website,
      });
    }
  }, [companyData, companyForm]);

  return (
    <>
      <div className="flex flex-col justify-center w-full h-full align-center px-[30px]  md:px-[116px]">
        {contextHolder}
        <div className="flex justify-end py-4">
          <Button
            size="large"
            onClick={handleClose}
            className="text-[#222225] w-8 h-8 text-base"
            icon={<CloseOutlined />}
            type="link"
          ></Button>
        </div>
        <div className="flex flex-col m-auto w-full align-center max-w-[380px] gap-8">
          <div className="flex flex-col gap-[14px]">
            <p className="m-0 text-[32px] font-bold leading-none">
              Company Profile
            </p>
            <p className="m-0 text-base text-[#595D62] leading-none">
              Help us understand your company and culture
            </p>
          </div>

          <Form
            form={companyForm}
            name="signup-form"
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            scrollToFirstError
          >
            <Form.Item
              name="logo"
              rules={[
                {
                  required: true,
                  message: "Logo is required",
                },
              ]}
              label={
                <>
                  Company Logo
                  <Popover
                    className="ml-2"
                    placement="rightTop"
                    content={content}
                  >
                    {/* <InfoCircleOutlined className="tooltip-info-icon" /> */}
                    <InfoCircleOutlined
                      style={{ fontSize: "16px", marginLeft: "5px" }}
                    />
                  </Popover>
                </>
              }
            >
              <div className="flex gap-2 col">
                {imageFile && (
                  <img
                    src={imageFile}
                    height={40}
                    width={40}
                    alt="Company Logo"
                    style={{ maxWidth: "100px" }}
                  />
                )}

                <ImgCrop
                  rotationSlider
                  aspect={1}
                  showGrid
                  modalTitle="Crop Image"
                  onModalOk={handleOk}
                >
                  <Upload showUploadList={false} maxCount={1}>
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload Logo
                    </Button>
                  </Upload>
                </ImgCrop>
              </div>
            </Form.Item>

            <Form.Item
              label="Company Name"
              name="companyname" // Add a unique name
              rules={[
                {
                  required: true,
                  message: "Company Name is required",
                },
              ]}
            >
              <Input placeholder="Insharp Technologies LLC" size="large" />
            </Form.Item>

            <Form.Item
              label="Industry"
              name="industry"
              rules={[
                {
                  required: true,
                  message: "Please select the industry!",
                  type: "string",
                },
              ]}
            >
              <Select size="large" showSearch placeholder="Select Industry">
                {industries.map((industry) => (
                  <Option key={industry} value={industry}>
                    {industry}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Number of Employees"
              name="employeeNumber"
              rules={[
                {
                  required: true,
                  message: "Please select the number of employees!",
                  type: "string",
                },
              ]}
            >
              <Select size="large" showSearch placeholder="Select">
                {numberOfEmployees.map((empNumber) => (
                  <Option key={empNumber} value={empNumber}>
                    {empNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="About Company"
              name="aboutcompany" // Add a unique name
              rules={[
                {
                  required: true,
                  message: "Company description is required",
                },
              ]}
            >
              <Input.TextArea size="large" placeholder="Description" />
            </Form.Item>

            <Form.Item
              label="Website"
              name="website"
              rules={[
                {
                  required: true,
                  message: "Website is required",
                },
                {
                  validator: validateURL,
                },
              ]}
            >
              <Input placeholder="Eg. https://www.company.com" size="large" />
            </Form.Item>

            <Form.Item colon={false}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-full"
              >
                Save Company
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
export default CompanyProfile;
