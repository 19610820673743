import { Button, Form, Modal, Select, message, InputNumber } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CandidateSkillsService from "../../../Services/Candidate/CandidateSkillsService";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import Constants from "../../../constants/Constants";
import { AxiosError } from "axios";
const { Option } = Select;

// interface StepData {
//   title: string;
//   value: number;
//   subTitle: string;
//   stepName: string;
//   description: string;
// }

interface SoftSkill {
  softSkillsId: string;
  name: string;
}

interface HardSkill {
  hardSkillsId: string;
  name: string;
}

interface ToolsTechnologies {
  toolsTechnologiesId: string;
  name: string;
}

interface Other {
  otherId: string;
  name: string;
}
interface SkillEditModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  editSkillType: number;
  campaignId: string;
  sofSkills: SoftSkill[];
  hardSkills: HardSkill[];
  toolsTechnologies: ToolsTechnologies[];
  other: Other[];
  renderSkills: () => void;
}

function SkillEditModal({
  isModalOpen,
  setIsModalOpen,
  editSkillType,
  campaignId,
  sofSkills,
  hardSkills,
  toolsTechnologies,
  other,
  renderSkills,
}: SkillEditModalProps) {
  const [form] = Form.useForm();
  const [isStuding, setIsStuding] = useState(false);
  const candidateEmail = retriveCandidateEmail();
  const [skills, setSkills] = useState<any>([]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSkillUpdate = async () => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        type: Constants.SKILL_TYPES[editSkillType],
        skills: skills,
      };

      const resp = await CandidateSkillsService.updateSkill(data);
      setIsModalOpen(false);
      renderSkills();
      message.success("Skill updated successfully");
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const skillTYpes = [
    "soft skills",
    "hard skills",
    "tech and tools skills",
    "other skills",
  ];

  const getSkillDropDownOptions = (skillNumber: number): React.ReactNode => {
    if (skillNumber == 0) {
      return (
        <>
          {sofSkills.map((skill: SoftSkill) => (
            <Option key={skill.softSkillsId} value={skill.softSkillsId}>
              {skill.name}
            </Option>
          ))}
        </>
      );
    } else if (skillNumber == 1) {
      return (
        <>
          {hardSkills.map((skill: HardSkill) => (
            <Option key={skill.hardSkillsId} value={skill.hardSkillsId}>
              {skill.name}
            </Option>
          ))}
        </>
      );
    } else if (skillNumber == 2) {
      return (
        <>
          {toolsTechnologies.map((tech) => (
            <Option
              key={tech.toolsTechnologiesId}
              value={tech.toolsTechnologiesId}
            >
              {tech.name}
            </Option>
          ))}
        </>
      );
    } else {
      return (
        <>
          {other.map((otheritem) => (
            <Option key={otheritem.otherId} value={otheritem.otherId}>
              {otheritem.name}
            </Option>
          ))}
        </>
      );
    }
    // Make sure to return valid React nodes, such as <Option> components
  };

  const getSkillName = (skillType: number, skillNumber: number) => {
    if (skillType == 0) {
      return sofSkills.find(
        (skill: SoftSkill) => Number(skill.softSkillsId) == skillNumber
      )?.name;
    } else if (skillType == 1) {
      return hardSkills.find(
        (skill: HardSkill) => Number(skill.hardSkillsId) == skillNumber
      )?.name;
    } else if (skillType == 2) {
      return toolsTechnologies.find(
        (skill: ToolsTechnologies) =>
          Number(skill.toolsTechnologiesId) == skillNumber
      )?.name;
    } else {
      return other.find((skill: Other) => Number(skill.otherId) == skillNumber)
        ?.name;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: any = {
          email: candidateEmail,
          roles: Constants.ROLES[2].value,
          campaignId,
        };

        const resp = await CandidateSkillsService.getSkillByType(
          data,
          editSkillType
        );

        setSkills(resp.data.userData);
      } catch (error) {
        console.log(error);
      }
    };

    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  console.log(editSkillType, "editSkillType");

  return (
    <>
      <Modal
        title={`Edit ${skillTYpes[editSkillType]}`}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={false}
      >
        {skills.length > 0 ? (
          <>
            {skills.map((skill: any) => {
              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2.5fr 1.5fr 0.2fr",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select"
                    size="large"
                    showSearch
                    filterOption={(input, option) => {
                      if (!option || !option.children) return false;
                      return option.children
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    value={skill.skillsId}
                    onChange={(value) => {
                      const isValuePresent = skills.find(
                        (singleSkill: any) => singleSkill.skillsId == value
                      );

                      if (isValuePresent) {
                        message.error("Skill is already added.");
                        return;
                      } else {
                        setSkills(
                          skills.map((singleSkill: any) => {
                            if (singleSkill.id == skill.id) {
                              singleSkill.skillsId = value;
                              singleSkill.name = getSkillName(
                                editSkillType,
                                value
                              );
                            }
                            return singleSkill;
                          })
                        );
                      }
                    }}
                  >
                    {getSkillDropDownOptions(editSkillType)}
                  </Select>

                  {(editSkillType == 2 || editSkillType == 3) && (
                    <InputNumber
                      value={skill.experience}
                      style={{ width: "100%" }}
                      // // min={1}
                      // max={10}
                      size="large"
                      placeholder="No. of years"
                      formatter={(value) => {
                        if (!value) {
                          return "";
                        }

                        const formattedValue = `${value} ${
                          value == 1 ? "Year" : "Years"
                        }`;

                        return formattedValue;
                      }}
                      parser={(value: string | undefined) => {
                        const numericValue = parseFloat(
                          value?.replace(" years", "") || "0"
                        );
                        return isNaN(numericValue) ? 0 : numericValue;
                      }}
                      onChange={(value) => {
                        setSkills(
                          skills.map((singleSkill: any) => {
                            if (singleSkill.id == skill.id) {
                              if (value <= 0 || value >= 100) {
                                singleSkill.experience = 1;
                              } else {
                                singleSkill.experience = value;
                              }
                            }
                            return singleSkill;
                          })
                        );
                      }}
                    />
                  )}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSkills(
                        skills.filter(
                          (singleSkill: any) => singleSkill.id != skill.id
                        )
                      );
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{ height: "100px", display: "grid", placeItems: "center" }}
          >
            <span>Delete All?</span>{" "}
          </div>
        )}

        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={onSkillUpdate} type="primary">
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default SkillEditModal;
