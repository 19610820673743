import { Button, Divider, Tag } from "antd";
import Logo from "../../../../src/assets/images/logo.svg";
import { EnvironmentOutlined } from "@ant-design/icons";
import API from "../../../API";
import { useState, useEffect } from "react";
import { displayToaster } from "../../../helper/Toaster";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { sanitizeHTMLString } from "../../../helper/SanitizeHTMLString";
import useNavigation from "../../../helper/UseNavigation";
import dayjs from "dayjs";

interface CampaignData {
  jobDetails: JobDetails;
  companyDetails: CompanyDetails;
}

interface JobDetails {
  jobTitle: string;
  location: string;
  workMode: string;
  minSal: number;
  maxSale: number;
  currency: string;
  salaryRange: string;
  jobDetails: string;
  employmentType: string;
  deadLineDate: string;
  deadLineTime: string;
  deadLineTimeStr: string;
  isShow: boolean;
}

interface CompanyDetails {
  logo: string;
  companyName: string;
  companyDescription: string;
  companyDetails: CompanyInDetail;
}

interface CompanyInDetail {
  size: string;
  industry: string;
  website: string;
}
interface CampaignPostViewProps {
  campaignId: string | undefined;
}

const CampaignPostView: React.FC<CampaignPostViewProps> = ({ campaignId }) => {
  const [campaignData, setcampaignData] = useState<CampaignData>();

  const [isShowPublish, setIsShowPublish] = useState(true);

  const locationGeneral = useLocation();
  const state = locationGeneral.state;
  const { id } = useParams();
  const handleNavigation = useNavigation();

  const candidateSubmitted = sessionStorage.getItem("candidateSubmitted");

  const submittedCampaignId = sessionStorage.getItem("submittedCampaignId");

  useEffect(() => {
    if (
      campaignData &&
      campaignData.companyDetails.logo !== "" &&
      campaignData.companyDetails.companyDescription !== "" &&
      campaignData.companyDetails.companyDetails.size !== "" &&
      campaignData.companyDetails.companyDetails.industry !== "" &&
      campaignData.companyDetails.companyDetails.website !== ""
    ) {
      setIsShowPublish(false);
    } else {
      setIsShowPublish(true);
    }

    // Cleanup function
    return () => {
      // Perform cleanup if necessary
    };
  }, [campaignData]);

  useEffect(() => {
    const initializeData = async () => {
      const url = "/campaign/campaign-preview";
      const requestData = {
        email: null,
        id:
          state != null &&
          state.campaignId != null &&
          state.campaignId !== undefined
            ? state.campaignId
            : id,
      };
      await API.post(url, requestData)
        .then((res) => {
          setcampaignData(res.data.data);
        })
        .catch((error) => {
          if(error.response.data.code === 404){
            handleNavigation("/not-found", null)
          }
          displayToaster("error", error.response.data.message);
        });
    };

    initializeData();
  }, [id, state]);

  return (
    <>
      <div
        className="flex sticky top-0 bg-white z-30 p-0 flex-row justify-start py-[10px] items-center  px-[30px] md:px-[116px]"
        style={{ borderBottom: "1px solid #F5F5F5" }}
      >
        <img src={Logo} className="logo" alt="Icon"></img>
      </div>

      <div className="py-[20px] px-[30px] md:px-[116px] md:py-[0px] flex flex-col md:flex-row gap-[2px]  post-paage">
        <div
          id="campaign-content"
          className="flex flex-col w-full gap-5 py-[30px] pb-4 md:pr-6   md:px-[0px]"
        >
          <div className="flex gap-[10px] flex-col">
            <p className="m-0 text-2xl font-bold">
              {campaignData && campaignData.jobDetails.jobTitle}
            </p>

            <div className="flex flex-row flex-wrap gap-[10px]">
              <Tag>
                {campaignData && campaignData.jobDetails.location}{" "}
                <EnvironmentOutlined />
              </Tag>
              <Tag>
                {campaignData && campaignData.jobDetails.employmentType}
              </Tag>
              <Tag>{campaignData && campaignData.jobDetails.workMode}</Tag>
              {campaignData && campaignData.jobDetails.isShow ? (
                <Tag>{campaignData && campaignData.jobDetails.salaryRange}</Tag>
              ) : null}
            </div>
          </div>

          <div className="flex flex-col items-start justify-between md:items-center md:flex-row">
            <div className="flex flex-col gap-1 md:flex-row">
              <p className="text-base text-[#595D62] m-0 ">
                Application Deadline:
              </p>
              <p className="text-base text-[#595D62] mb-2 md:mb-0">
                {campaignData &&
                  dayjs(campaignData.jobDetails.deadLineDate).format(
                    "DD/MM/YYYY"
                  )}
                - {campaignData && campaignData.jobDetails.deadLineTimeStr}
              </p>
            </div>

            <Button
              type="primary"
              size="large"
              className=""
              style={{
                backgroundColor:
                  submittedCampaignId != null &&
                  id != null &&
                  submittedCampaignId == id
                    ? "grey"
                    : "#222225",
              }}
              disabled={
                submittedCampaignId != null &&
                id != null &&
                submittedCampaignId == id
                  ? true
                  : false
              }
              onClick={() => {
                handleNavigation("/candidate", {
                  campaignId:
                    state != null &&
                    state.campaignId != null &&
                    state.campaignId !== undefined
                      ? state.campaignId
                      : id,
                  campaignName:
                    campaignData && campaignData.jobDetails.jobTitle,
                  companyLogo: campaignData && campaignData.companyDetails.logo,
                });
              }}
            >
              {submittedCampaignId != null &&
              id != null &&
              submittedCampaignId == id
                ? "Applied"
                : " Apply Now"}
            </Button>
          </div>
          <Divider className="m-0" />
          <div className="flex gap-[10px] flex-col advance-text-editor">
            <p className="text-lg font-bold">Job Details</p>

            <p className="text-base">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    campaignData && campaignData.jobDetails.jobDetails
                      ? sanitizeHTMLString(campaignData.jobDetails.jobDetails)
                      : "",
                }}
              />
            </p>
          </div>
        </div>

        <div
          id="company-content"
          className="md:max-w-[360px] py-[30px] px-6  bg-[#F5F5F580] border-[#E2E2E2] solid"
        >
          <div>
            {/* when no value found */}
            {/* <Skeleton /> */}
            {campaignData &&
            campaignData.companyDetails.logo !== "" &&
            campaignData.companyDetails.companyDescription !== "" &&
            campaignData.companyDetails.companyDetails.size !== "" &&
            campaignData.companyDetails.companyDetails.industry !== "" &&
            campaignData.companyDetails.companyDetails.website !== "" ? (
              <div className="flex flex-col w-full gap-3">
                <div>
                  <img
                    style={{ height: "50px" }}
                    src={campaignData && campaignData.companyDetails.logo}
                    alt="company-logo"
                  />
                </div>

                <div className="flex flex-col gap-[5px]">
                  <p className="text-lg font-bold">
                    {campaignData && campaignData.companyDetails.companyName}
                  </p>

                  <p className="text-sm">
                    {campaignData &&
                      campaignData.companyDetails.companyDescription}
                  </p>
                </div>

                <div className="flex flex-col gap-[10px]">
                  <div className="flex flex-col md:flex-row gap-[5px]">
                    <p className="text-sm">Size:</p>
                    <p className="text-sm font-bold">
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.size}
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[5px]">
                    <p className="text-sm">Industry:</p>
                    <p className="text-sm font-bold">
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.industry}
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row gap-[5px]">
                    <p className="text-sm">Website:</p>
                    <p
                      onClick={() => {
                        window.open(
                          campaignData &&
                            campaignData.companyDetails.companyDetails.website,
                          "_blank"
                        );
                      }}
                      className="text-sm font-bold break-all cursor-pointer"
                    >
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.website}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignPostView;
