import { Button } from "antd";
import NotFoundImage from "../assets/images/notFound.png";
import useNavigation from "../helper/UseNavigation";
function NotFound() {

  const handleNavigation = useNavigation();

  const navigateToHome = async () => {
    handleNavigation('/dashboard', null);
  }

  return (
    <div className="flex justify-center w-full h-full align-center px-[30px]">
      <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
        <div className="flex flex-col gap-[14px] items-center" >
          <img className="justify-center"
            width={200}
            height={200}
            src={NotFoundImage}
            alt="notfound-icon"
          ></img>
          <p className="m-0 text-[32px] font-bold leading-none">Oops!</p>
          <p className="align-center">The page you're looking for could not be found</p>
          <Button className="align-center" type="primary" onClick={navigateToHome}>Return Home</Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;


