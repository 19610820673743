

function usePasswordValidator() {


  const validatePassword = (_: any, value: string) => {
    if (!value) {
        return Promise.reject('Password is required');
    }
    if (value.length < 8) {
        return Promise.reject('Password must be at least 8 characters long');
    }
    if (!/(?=.*[A-Z])/.test(value)) {
        return Promise.reject('Password must contain at least one uppercase letter');
    }
    if (!/(?=.*[0-9])/.test(value)) {
        return Promise.reject('Password must contain at least one number');
    }
    return Promise.resolve();
};

  return validatePassword;
}

export default usePasswordValidator;
