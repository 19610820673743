import { EditOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Select, message } from "antd";
import { useEffect, useState } from "react";
import SkillEditModal from "./SkillEditModal";
import API from "../../../API";
import CandidateSkillsService from "../../../Services/Candidate/CandidateSkillsService";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import Constants from "../../../constants/Constants";
import { AxiosError } from "axios";
const { Option } = Select;

interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}

interface SoftSkill {
  softSkillsId: string;
  name: string;
}

interface HardSkill {
  hardSkillsId: string;
  name: string;
}

interface ToolsTechnologies {
  toolsTechnologiesId: string;
  name: string;
}

interface Other {
  otherId: string;
  name: string;
}

interface SkillsProps {
  currentData: () => StepData | void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  isMobile: boolean;
  campaignId: string;
  setSkillsSectionComplete: (skillsSectionComplete: boolean) => void;
}

function Skills({
  currentData,
  onNextClick,
  onPreviousClick,
  isMobile,
  campaignId,
  setSkillsSectionComplete,
}: SkillsProps) {
  const [form] = Form.useForm();
  const [softSkillForm] = Form.useForm();
  const [hardSkillForm] = Form.useForm();
  const [toolAndTechForm] = Form.useForm();
  const [otherSkillForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stepData = currentData();
  const [sofSkills, setSoftSkills] = useState<SoftSkill[]>([]);

  const [hardSkills, setHardSkills] = useState<HardSkill[]>([]);

  const [toolsTechnologies, setToolsTechnologies] = useState<
    ToolsTechnologies[]
  >([]);

  const [other, setOther] = useState<Other[]>([]);
  const candidateEmail = retriveCandidateEmail();

  const [allSkills, setAllSkills] = useState<any | null>();

  const skillNames = ["Soft Skills", "Hard Skills", "Tech and Tools", "Other"];

  const [editSkillType, setEditSkillType] = useState<number>(0);

  useEffect(() => {
    getSkills();
  }, []);

  const getSkills = async () => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
      };
      const resp = await CandidateSkillsService.getAllSkills(data);
      setAllSkills(resp.data.userData);

      if (
        Object.keys(resp.data.userData.SoftSkills).length == 0 &&
        Object.keys(resp.data.userData.TechAndTools).length == 0 &&
        Object.keys(resp.data.userData.HardSkills).length == 0 &&
        Object.keys(resp.data.userData.Other).length == 0
      ) {
        setSkillsSectionComplete(false);
      } else {
        setSkillsSectionComplete(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      const dropdownsString = localStorage.getItem("dropdowns");
      const dropdowns = dropdownsString
        ? JSON.parse(dropdownsString)
        : undefined;

      if (dropdowns != null && dropdowns !== undefined) {
        setSoftSkills(dropdowns?.softSkills);
        setHardSkills(dropdowns?.hardSkills);
        setToolsTechnologies(dropdowns?.toolsTechnologies);
        setOther(dropdowns?.other);
      } else {
        const url = "/campaign/dropdowns";
        await API.post(url)
          .then((res) => {
            localStorage.setItem("dropdowns", JSON.stringify(res.data.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    initializeData();
  }, []);

  const handleSoftSkillSave = async (values: any) => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        type: Constants.SKILL_TYPES[0],
        skillsId: values.softSkill,
        experience: values.softSkillNoOfYears,
      };

      const resp = await CandidateSkillsService.createSkill(data);
      getSkills();
      softSkillForm.resetFields();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setSkillsSectionComplete(true);

      if(resp.data.code === 207){
        console.log(resp)
        message.error(resp.data.message);
      }
      else{
        message.success("Successfully soft skill added");
      }
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const handleHardSkillSave = async (values: any) => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        type: Constants.SKILL_TYPES[1],
        skillsId: values.hardSkill,
        experience: values.hardSkillNoOfYears,
      };

      const resp = await CandidateSkillsService.createSkill(data);
      getSkills();

      hardSkillForm.resetFields();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setSkillsSectionComplete(true);
      if(resp.data.code === 207){
        console.log(resp)
        message.error(resp.data.message);
      }
      else{
        message.success("Successfully hard skill added");
      }

      
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const handleToolAndTechSkillSave = async (values: any) => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        type: Constants.SKILL_TYPES[2],
        skillsId: values.toolAndTechSkill,
        experience: values.toolAndTechSkillNoOfYears,
      };

      const resp = await CandidateSkillsService.createSkill(data);
      getSkills();
      toolAndTechForm.resetFields();

      setSkillsSectionComplete(true);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      message.success("Successfully tool & tech skill added");
    } catch (error) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const handleOtherSkillFormSave = async (values: any) => {
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        type: Constants.SKILL_TYPES[3],
        skillsId: values.otherSkill,
        experience: values.otherSkillNoOfYears,
      };

      const resp = await CandidateSkillsService.createSkill(data);
      getSkills();
      otherSkillForm.resetFields();

      setSkillsSectionComplete(true);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      message.success("Successfully other skill added");
    } catch (error: unknown) {
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const getSkillNameAccordingToIndex = (ind: number) => {
    return skillNames[ind];
  };

  if (!stepData) {
    // Handle case where currentData returns void
    return <div>No data available</div>;
  }

  return (
    <>
      <div className="flex flex-col md:flex-row gap-6 md-0 mr-0 md:ml-[116px] md:mr-[116px] justify-between">
        <div className="md:max-w-[500px] w-full px-5 md:px-0">
          <div className="flex flex-col w-full gap-1">
            <p className="m-0 text-sm">{stepData && stepData.stepName}</p>
            <p
              style={{ margin: "12px 0px" }}
              className="m-0 text-3xl font-bold"
            >
              {stepData && stepData.subTitle}
            </p>
            <p className="m-0 text-base text-[#595D62] leading-none">
              {stepData && stepData.description}
            </p>
          </div>

          <div className="mt-4">
            <Form
              form={softSkillForm}
              name="softSkills-form"
              layout="vertical"
              autoComplete="off"
              onFinish={handleSoftSkillSave}
              scrollToFirstError
              
            >
              <div>
                {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}
                <div>
                  <p className="m-0 text-[18px] font-bold mb-[8px]">
                    Soft Skills
                  </p>
                  <div>
                    <Form.Item
                      name="softSkill"
                      rules={[
                        {
                          required: true,
                          message: "Skill is required",
                        },
                      ]}
                    >
                      {/* <Select
                        options={sofSkills.map((item) => ({
                          value: item.softSkillsId,
                          label: item.name,
                        }))}
                        placeholder="Select"
                        size="large"
                        showSearch
                        filterOption={(input, option: any) => {
                          if (!option || !option.children) return false;
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      ></Select> */}
                      <Select
                        placeholder="Select"
                        size="large"
                        showSearch
                        mode="multiple"
                        filterOption={(input, option) => {
                          if (!option || !option.children) return false;
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {sofSkills.map((item) => (
                          <Option
                            key={item.softSkillsId}
                            value={item.softSkillsId}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/* 
                    <Form.Item
                      name="softSkillNoOfYears"
                      rules={[
                        {
                          required: true,
                          message: "No Of Years is required",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Experience must be greater than or equal to one and less than 100",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        // min={0}
                        // max={100}
                        size="large"
                        placeholder="No. of years"
                        formatter={(value) => {
                          if (!value) {
                            return "";
                          }

                          const formattedValue = `${value} ${
                            value == 1 ? "Year" : "Years"
                          }`;
                          return formattedValue;
                        }}
                        parser={(value: string | undefined) => {
                          const numericValue = parseFloat(
                            value?.replace(" years", "") || "0"
                          );
                          return isNaN(numericValue) ? 0 : numericValue;
                        }}
                        // onChange={onChange}
                      />
                    </Form.Item> */}
                  </div>
                </div>

                <Button
                  style={{ background: "#F5F5F5" }}
                  className="w-full mt-5 border-none"
                  htmlType="submit"
                  size="large"
                >
                  Add
                </Button>
              </div>
            </Form>
          </div>

          <div style={{ marginTop: "16px" }}>
            <Form
              form={hardSkillForm}
              name="hardSkills-form"
              layout="vertical"
              autoComplete="off"
              onFinish={handleHardSkillSave}
              scrollToFirstError
            >
              <div>
                {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}
                <div>
                  <p className="m-0 text-[18px] font-bold mb-[8px]">
                    Hard Skills
                  </p>
                  <div>
                    <Form.Item
                      name="hardSkill"
                      rules={[
                        {
                          required: true,
                          message: "Skill is required",
                        },
                      ]}
                    >
                      {/* <Select
                        options={hardSkills.map((item) => ({
                          value: item.hardSkillsId,
                          label: item.name,
                        }))}
                        placeholder="Select"
                        size="large"
                      /> */}
                      <Select
                        placeholder="Select"
                        size="large"
                        showSearch
                        mode="multiple"
                        filterOption={(input, option) => {
                          if (!option || !option.children) return false;
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {hardSkills.map((item) => (
                          <Option
                            key={item.hardSkillsId}
                            value={item.hardSkillsId}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/* 
                    <Form.Item
                      name="hardSkillNoOfYears"
                      rules={[
                        {
                          required: true,
                          message: "No Of Years is required",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Experience must be greater than or equal to one and less than 100",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        // min={1}
                        // max={10}
                        size="large"
                        placeholder="No. of years"
                        formatter={(value) => {
                          if (!value) {
                            return "";
                          }

                          const formattedValue = `${value} ${
                            value == 1 ? "Year" : "Years"
                          }`;
                          return formattedValue;
                        }}
                        parser={(value: string | undefined) => {
                          const numericValue = parseFloat(
                            value?.replace(" years", "") || "0"
                          );
                          return isNaN(numericValue) ? 0 : numericValue;
                        }}
                        // onChange={onChange}
                      />
                    </Form.Item> */}
                  </div>
                </div>

                <Button
                  className="w-full mt-5 border-none"
                  style={{ background: "#F5F5F5" }}
                  htmlType="submit"
                  size="large"
                >
                  Add
                </Button>
              </div>
            </Form>
          </div>

          <div style={{ marginTop: "16px" }}>
            <Form
              form={toolAndTechForm}
              name="campaign-form"
              layout="vertical"
              autoComplete="off"
              onFinish={handleToolAndTechSkillSave}
              scrollToFirstError
            >
              <div>
                {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}
                <div>
                  <p className="m-0 text-[18px] font-bold mb-[8px]">
                    Tool & Tech
                  </p>
                  <div className="grid grid-cols-1 gap-0 md:gap-4 md:grid-cols-2">
                    <Form.Item
                      name="toolAndTechSkill"
                      rules={[
                        {
                          required: true,
                          message: "Skill is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        showSearch
                        filterOption={(input, option) => {
                          if (!option || !option.children) return false;
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {toolsTechnologies.map((tech) => (
                          <Option
                            key={tech.toolsTechnologiesId}
                            value={tech.toolsTechnologiesId}
                          >
                            {tech.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="toolAndTechSkillNoOfYears"
                      rules={[
                        {
                          required: true,
                          message: "No Of Years is required",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Experience must be greater than or equal to one and less than 100",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        // min={1}
                        // max={10}
                        size="large"
                        placeholder="No. of years"
                        formatter={(value) => {
                          if (!value) {
                            return "";
                          }

                          const formattedValue = `${value} ${
                            value == 1 ? "Year" : "Years"
                          }`;
                          return formattedValue;
                        }}
                        parser={(value: string | undefined) => {
                          const numericValue = parseFloat(
                            value?.replace(" years", "") || "0"
                          );
                          return isNaN(numericValue) ? 0 : numericValue;
                        }}
                        // onChange={onChange}
                      />
                    </Form.Item>
                  </div>
                </div>

                <Button
                  className="w-full mt-5 border-none"
                  style={{ background: "#F5F5F5" }}
                  htmlType="submit"
                  size="large"
                >
                  Add
                </Button>
              </div>
            </Form>
          </div>

          <div style={{ marginTop: "16px" }}>
            <Form
              form={otherSkillForm}
              name="otherSkills-form"
              layout="vertical"
              autoComplete="off"
              onFinish={handleOtherSkillFormSave}
              scrollToFirstError
            >
              <div>
                {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}
                <div>
                  <p className="m-0 text-[18px] font-bold mb-[8px]">
                    Other Skills
                  </p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                    }}
                  >
                    <Form.Item
                      name="otherSkill"
                      rules={[
                        {
                          required: true,
                          message: "Skill is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        size="large"
                        showSearch
                        filterOption={(input, option) => {
                          if (!option || !option.children) return false;
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {other.map((otheritem) => (
                          <Option
                            key={otheritem.otherId}
                            value={otheritem.otherId}
                          >
                            {otheritem.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="otherSkillNoOfYears"
                      rules={[
                        {
                          required: true,
                          message: "No Of Years is required",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Experience must be greater than or equal to one and less than 100",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        // min={1}
                        // max={10}
                        size="large"
                        placeholder="No. of years"
                        formatter={(value) => {
                          if (!value) {
                            return "";
                          }

                          const formattedValue = `${value} ${
                            value == 1 ? "Year" : "Years"
                          }`;
                          return formattedValue;
                        }}
                        parser={(value: string | undefined) => {
                          const numericValue = parseFloat(
                            value?.replace(" years", "") || "0"
                          );
                          return isNaN(numericValue) ? 0 : numericValue;
                        }}
                        // onChange={onChange}
                      />
                    </Form.Item>
                  </div>
                </div>

                <Button
                  className="w-full mt-5 border-none"
                  style={{ background: "#F5F5F5" }}
                  htmlType="submit"
                  size="large"
                >
                  Add
                </Button>
              </div>
            </Form>
          </div>

          {!isMobile ? (
            <div className="flex justify-between w-full">
              <Button className="mt-5 " onClick={onPreviousClick} size="large">
                Previous
              </Button>

              <Button
                type="primary"
                className="mt-5 "
                // htmlType="submit"
                onClick={() => onNextClick()}
                size="large"
              >
                Next
              </Button>
            </div>
          ) : (
            <div className="mt-5 "></div>
          )}
        </div>
        <div className="max-w-[600px] w-full px-5 md:px-0">
          <div className=" rounded-[10px]" style={{ background: "#F5F5F580" }}>
            {allSkills &&
              Object.entries(allSkills).map((key: any, ind) => {
                if (Object.keys(key[1]).length > 0) {
                  if (key[0] == "SoftSkills" || key[0] == "HardSkills") {
                    return (
                      <div
                        className="p-4"
                        style={{
                          borderBottom: "2px solid #E2E2E2",
                        }}
                      >
                        <div className="flex justify-between">
                          <div>
                            <p style={{ fontWeight: "700", fontSize: "18px" }}>
                              {getSkillNameAccordingToIndex(ind)}
                            </p>

                            <div
                              className="flex gap-[10px] flex-wrap text-base text-[#595D62]"
                              style={{
                                margin: "10px 0px",
                              }}
                            >
                              {key[1] &&
                                key[1].map((skill: any) => {
                                  return <div>{skill.name}</div>;
                                })}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "self-start",
                              gap: "10px",
                            }}
                          >
                            <EditOutlined
                              onClick={() => {
                                setIsModalOpen(true);
                                setEditSkillType(ind);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="p-4"
                        style={{
                          borderBottom: "2px solid #E2E2E2",
                        }}
                      >
                        <div className="flex justify-between">
                          <div>
                            <p style={{ fontWeight: "700", fontSize: "18px" }}>
                              {getSkillNameAccordingToIndex(ind)}
                            </p>

                            {key[1] &&
                              Object.entries(key[1]).map((skill: any, ind) => {
                                return (
                                  <>
                                    <p
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {skill[0]}
                                    </p>

                                    <div className="flex gap-[10px] flex-wrap text-base text-[#595D62]">
                                      {skill[1].map((singleSkill: any) => (
                                        <span>{singleSkill}</span>
                                      ))}
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "self-start",
                              gap: "10px",
                            }}
                          >
                            <EditOutlined
                              onClick={() => {
                                setIsModalOpen(true);
                                setEditSkillType(ind);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              })}
          </div>
        </div>
        {isMobile && (
          <div className="flex justify-between w-full px-5 md:px-0">
            <Button
              style={{ height: "40px", width: "150px" }}
              className="mt-5 "
              onClick={() => onPreviousClick()}
              size="large"
            >
              Previous
            </Button>

            <Button
              style={{ height: "40px", width: "150px" }}
              type="primary"
              className="mt-5 "
              size="large"
              onClick={() => onNextClick()}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      <div className="empty-div"></div>
      <SkillEditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editSkillType={editSkillType}
        campaignId={campaignId}
        sofSkills={sofSkills}
        hardSkills={hardSkills}
        toolsTechnologies={toolsTechnologies}
        other={other}
        renderSkills={getSkills}
      />
    </>
  );
}

export default Skills;
