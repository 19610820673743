import { Anchor } from "antd";

interface SettingsLeftSiderProps {
  CAMPAIGN_SUB_HEADINGS_STEPS: {
    step: string;
    title: string;
    heading: { key: string; href: string; title: string }[];
  }[];
}

const CampaignLeftSider: React.FC<SettingsLeftSiderProps> = ({
  CAMPAIGN_SUB_HEADINGS_STEPS,
}) => {
  return (
    <div className="hidden  md:flex w-[30%] max-w-[400px] sticky top-0">
      {CAMPAIGN_SUB_HEADINGS_STEPS.map((subHeadingStep, index) => (
        <div className="flex flex-col w-full gap-1 md:pt-[30px]" key={index}>
          <p className="m-0 text-sm">{subHeadingStep.step}</p>
          <p className="m-0 text-3xl font-bold">{subHeadingStep.title}</p>
          <Anchor className="mt-7" key={index} items={subHeadingStep.heading} />
        </div>
      ))}
    </div>
  );
};

export default CampaignLeftSider;
