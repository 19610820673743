import { SetStateAction, useEffect, useState } from "react";
import { StepComponent } from "../../../components/Steps/StepComponent";
import Constants from "../../../constants/Constants";
import AboutYou from "./AboutYou";
import Education from "./Education";
import Experience from "./Experience";
import Preview from "./Preview";
import Skills from "./Skills";
import { Button, message } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import successImg from "../../../assets/icons/successWindowIcon.png";
import CandidatePreviewService from "../../../Services/Candidate/CandidatePreviewService";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";

interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}

function Candidate() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [aboutSectionComplete, setAboutSectionComplete] =
    useState<boolean>(false);
  const [experienceSectionCompleted, setExperienceSectionCompleted] =
    useState<boolean>(false);

  const [educationSectionCompleted, setEducationSectionCompleted] =
    useState<boolean>(false);

  const [skillsSectionComplete, setSkillsSectionComplete] =
    useState<boolean>(false);

  const [candidateInforamtionSuccess, setCandidateInforamtionSuccess] =
    useState<boolean>(false);

  const locationGeneral = useLocation();
  const navigate = useNavigate();

  const state = locationGeneral.state;

  useEffect(() => {
    const candidateEmail = retriveCandidateEmail();
    const getPreview = async () => {
      try {
        const data: any = {
          campaignId: state && state.campaignId,
          roles: Constants.ROLES[2].value,
          email: candidateEmail,
        };
        const resp = await CandidatePreviewService.getPreview(data);

        if (resp.status == 200) {
          const previewData = resp.data;
          console.log(previewData, "preview Data");

          if (Object.keys(previewData.profile).length > 0) {
            setAboutSectionComplete(true);
          }

          if (previewData.experience.length > 0) {
            setExperienceSectionCompleted(true);
          }

          if (previewData.education.length > 0) {
            setEducationSectionCompleted(true);
          }

          if (
            Object.keys(previewData.skills.SoftSkills).length == 0 &&
            Object.keys(previewData.skills.TechAndTools).length == 0 &&
            Object.keys(previewData.skills.HardSkills).length == 0 &&
            Object.keys(previewData.skills.Other).length == 0
          ) {
            setSkillsSectionComplete(false);
          } else {
            setSkillsSectionComplete(true);
          }

          var refreshedStep = sessionStorage.getItem("currentStep");

          if (refreshedStep != null) {
            console.log(Number(refreshedStep), "Number Refreshed");

            setCurrentStep(Number(refreshedStep));
          }
        }
      } catch (error) {}
    };

    if (candidateEmail != null) {
      getPreview();
    }
  }, []);

  useEffect(() => {
    if (state == null) {
      navigate(
        `/campaign-post/${state && state.campaignId ? state.campaignId : ""}`
      );
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  }, [currentStep]);

  const handleStepChange = (step: SetStateAction<number>) => {
    if (currentStep >= Number(step)) {
      setCurrentStep(step);
      sessionStorage.setItem("currentStep", step.toString());
    } else if (!aboutSectionComplete) {
      message.warning("Complete the about you section to continue");
    } else if (!experienceSectionCompleted && step != 1 && Number(step) > 1) {
      message.warning("Add a experience to continue");
    } else if (!educationSectionCompleted && step != 2 && Number(step) > 2) {
      message.warning("Add a education to continue");
    } else if (!skillsSectionComplete && step != 3 && Number(step) > 3) {
      message.warning("Add a skill to continue");
    } else {
      setCurrentStep(step);
      sessionStorage.setItem("currentStep", step.toString());
    }
  };

  const fetchCurrentStepData = (): StepData | void => {
    const currentStepData = Constants.MAIN_CANDIDATE_STEPS.find(
      (step) => step.value === currentStep
    );
    if (currentStepData) {
      return currentStepData;
    }
    return undefined;
  };

  const fetchNextStepData = (): StepData | void => {
    const currentStepData = Constants.MAIN_CANDIDATE_STEPS.find(
      (step) => step.value === currentStep + 1
    );
    if (currentStepData) {
      return currentStepData;
    }
    return undefined;
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768); // You can adjust the threshold as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {candidateInforamtionSuccess ? (
        <div
          style={{
            height: "100vh",
            display: "grid",
            placeItems: "center",
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "3%",
              right: "20px",
              cursor: "pointer",
              fontSize: "32px",
            }}
            onClick={() =>
              navigate(
                `/campaign-post/${
                  state && state.campaignId ? state.campaignId : ""
                }`
              )
            }
          >
            <CloseOutlined />
          </span>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              textAlign: "center",
              width: "376px",
            }}
          >
            <img
              height={90}
              width={90}
              src={successImg}
              alt="Success Icon"
            ></img>
            <span
              style={{
                fontSize: "32px",
                fontWeight: "700",
              }}
            >
              Successful
            </span>
            <p style={{ fontSize: "16px", fontWeight: "400" }}>
              Application Submitted! We will inform you about the next
              information.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div
            className="flex items-center w-full py-2 bg-white px-[20px] border-b border-gray-200 md:px-[116px] z-50	 sticky top-0  "
          >
            <div
              
              className="flex items-center justify-between w-full "
            >

<div className="flex items-center gap-2">
              <Button
                size="large"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  navigate(
                    `/campaign-post/${
                      state && state.campaignId ? state.campaignId : ""
                    }`
                  );
                }}
              />

              <p className="m-0 text-xl font-bold">{state && state.campaignName ? state.campaignName : ""}</p>
            </div>

              
              <img
                style={{ width: "36px", height: "36px" }}
                src={state && state.companyLogo ? state.companyLogo : ""}
                alt=""
              />
            </div>
          </div>
          <div className="web-body " >
            {currentStep === 0 && (
              <>
                <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] pt-[30px]   ">
                  <div className="md:flex justify-center w-[full] px-5 md:px-0">
                    {StepComponent(
                      currentStep,
                      Constants.MAIN_CANDIDATE_STEPS,
                      handleStepChange,
                      isMobile
                    )}
                  </div>
                </div>
                <div
                className="flex justify-center mt-[30px]"
                 
                >
                  <AboutYou
                    currentData={fetchCurrentStepData}
                    onNextClick={() => handleStepChange(currentStep + 1)}
                    isMobile={isMobile}
                    campaignId={
                      state && state.campaignId ? state.campaignId : ""
                    }
                    setAboutSectionComplete={setAboutSectionComplete}
                    setCurrentStep={setCurrentStep}
                  ></AboutYou>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] pt-[30px] ">
                <div className="md:flex justify-center w-[full] px-5 md:px-0">
                  {StepComponent(
                    currentStep,
                    Constants.MAIN_CANDIDATE_STEPS,
                    handleStepChange,
                    isMobile
                  )}
                </div>

                <Experience
                  currentData={fetchCurrentStepData}
                  onNextClick={() => handleStepChange(currentStep + 1)}
                  onPreviousClick={() => handleStepChange(currentStep - 1)}
                  isMobile={isMobile}
                  campaignId={state && state.campaignId ? state.campaignId : ""}
                  setExperienceSectionCompleted={setExperienceSectionCompleted}
                ></Experience>
              </div>
            )}
            {currentStep === 2 && (
              <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] pt-[30px] ">
                <div className="md:flex justify-center w-[full] px-5 md:px-0">
                  {StepComponent(
                    currentStep,
                    Constants.MAIN_CANDIDATE_STEPS,
                    handleStepChange,
                    isMobile
                  )}
                </div>
                <Education
                  currentData={fetchCurrentStepData}
                  onNextClick={() => handleStepChange(currentStep + 1)}
                  onPreviousClick={() => handleStepChange(currentStep - 1)}
                  isMobile={isMobile}
                  campaignId={state && state.campaignId ? state.campaignId : ""}
                  setEducationSectionCompleted={setEducationSectionCompleted}
                ></Education>
              </div>
            )}
            {currentStep === 3 && (
              <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] pt-[30px] ">
                <div className="md:flex justify-center w-[full] px-5 md:px-0 ">
                  {StepComponent(
                    currentStep,
                    Constants.MAIN_CANDIDATE_STEPS,
                    handleStepChange,
                    isMobile
                  )}
                </div>
                <Skills
                  currentData={fetchCurrentStepData}
                  onNextClick={() => handleStepChange(currentStep + 1)}
                  onPreviousClick={() => handleStepChange(currentStep - 1)}
                  isMobile={isMobile}
                  campaignId={state && state.campaignId ? state.campaignId : ""}
                  setSkillsSectionComplete={setSkillsSectionComplete}
                ></Skills>
              </div>
            )}
            {currentStep === 4 && (
              <div className=" w-[full] flex flex-col gap-[20px] md:gap-[50px] pt-[30px] ">
                <div className="md:flex justify-center w-[full] px-5 md:px-0 ">
                  {StepComponent(
                    currentStep,
                    Constants.MAIN_CANDIDATE_STEPS,
                    handleStepChange,
                    isMobile
                  )}
                </div>
                <Preview
                  currentData={fetchCurrentStepData}
                  onPreviousClick={() => handleStepChange(currentStep - 1)}
                  campaignId={state && state.campaignId ? state.campaignId : ""}
                  setCandidateInforamtionSuccess={
                    setCandidateInforamtionSuccess
                  }
                  isMobile={isMobile}
                ></Preview>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Candidate;
