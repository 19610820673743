export function ExtractInitials(fullName: string) {
  try {
     // Split the full name into words
  const words = fullName.split(" ");
  // Extract the first letter of each word and convert to uppercase
  const initials = words
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase());
  // Concatenate the initials
  return initials.join("");
  } catch (error) {
    return ''; // Return empty string in case of error
  }
}


