import axios from "../../API";
import Constants from "../../constants/Constants";

const CandidateSkillsService = {
  createSkill: async (data: any) => {
    try {
      const params = {
        section: Constants.Skill,
        action: Constants.ACTIONS[0],
      };

      const url = "/candidate/create-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAllSkills: async (data: any) => {
    try {
      const params = {
        section: Constants.Skill,
      };

      const url = "/candidate/view-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getSkillByType: async (data: any, type: number) => {
    try {
      const params = {
        section: Constants.Skill,
        type: Constants.SKILL_TYPES[type],
      };

      const url = "/candidate/view-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  updateSkill: async (data: any) => {
    try {
      const params = {
        section: Constants.Skill,
        action: Constants.ACTIONS[1],
      };

      const url = "/candidate/create-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default CandidateSkillsService;
