import { displayToaster } from './Toaster';
import axios from "../API";

export async function UploadCompanyLogo(data: any) {
  try {
    const response = await axios.post('/company/upload-logo', data);
    displayToaster("success", "Image uploaded successfully!");
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
