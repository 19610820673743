import React, { useState } from "react";
import { Menu, Dropdown, Anchor, Button } from "antd";
import { FolderOpenOutlined } from "@ant-design/icons";

interface HeadingItem {
  key: string;
  href: string;
  title: string;
}

interface CampaignMenuProps {
  steps: HeadingItem[];
}

const CampaignMenu: React.FC<CampaignMenuProps> = ({ steps }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const { Link } = Anchor;

  return (
    <>
      <div className="flex pb-5 md:py-5 md:hidden border-b border-[#E2E2E2] justify-end">
        <Dropdown
          visible={menuVisible}
          onVisibleChange={(visible) => setMenuVisible(visible)}
          overlay={
            <Menu>
              {steps.map((heading, index) => (
                <Menu.Item key={index}>
                  <Link href={heading.href} title={heading.title} />
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button
            size="large"
            onClick={() => setMenuVisible(!menuVisible)}
            className="text-[#222225] w-8 h-8 text-base"
            icon={<FolderOpenOutlined />}
            type="link"
          ></Button>
        </Dropdown>
      </div>
    </>
  );
};

export default CampaignMenu;
