import { Button, Form, Input } from "antd";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import useNavigation from "../../../helper/UseNavigation";

function EmptyDashboard() {
  const handleNavigation = useNavigation();

  const onFinish = async (values: any) => {
    var requestData = {
      email: localStorage.getItem("email"),
      companyName: values.companyName,
    };
    const url = "/company/create-company";
    await API.post(url, requestData)
      .then((res) => {
        // toaster
        displayToaster("success", res.data.message);
        // Redirect to dashboard upon successful sign-in
        handleNavigation("/dashboard", null);
      })
      .catch((error) => {
        // toaster
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <div className="flex justify-center w-full h-full align-center px-[30px]">
      <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
        <div className="flex flex-col gap-[14px]">
          <p className="m-0 text-[32px] font-bold leading-none">
            Let’s Create Your Workspace
          </p>
          <p className="m-0 text-base text-[#595D62] leading-none">
            Use your company name
          </p>
        </div>

        <Form
          name="empty-dashboard-form"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item
            label="Company Name"
            name="companyName" // Add a unique name
            rules={[
              {
                required: true,
                message: "Company Name is required",
              },
            ]}
          >
            <Input placeholder="Insharp Technologies LLC" size="large" />
          </Form.Item>

          <Form.Item colon={false}>
            <Button
              type="primary"
              className="w-full"
              htmlType="submit"
              size="large"
            >
              Create Workspace
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default EmptyDashboard;
