import { Button } from "antd";
import Success from "../../../assets/icons/successWindowIcon.png";

import { Link } from "react-router-dom";
// import { CheckCircleOutlined } from '@ant-design/icons';

function PasswordUpdateSuccess() {
  return (
    <>
      <div className="flex items-center justify-center w-full md:h-full ">
        <div className="flex flex-col items-center max-w-[380px]  w-full">
          <img height={90} width={90} src={Success} alt="Success Icon"></img>

          <div className="flex flex-col items-center gap-4">
            <p className="m-0 text-3xl font-bold">Successful</p>

            <p className="m-0 text-base text-[#595D62]">
              Your password updated successfully!
            </p>
          </div>
          <Link className="w-full mt-8" to="/signin">
            <Button
              type="primary"
              className="w-full "
              htmlType="submit"
              size="large"
            >
              Continue
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PasswordUpdateSuccess;
