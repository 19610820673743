import axios from 'axios';
import Constants from './constants/Constants';
import { displayToaster } from './helper/Toaster';

// Create an instance of Axios with default configuration
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
  headers: {
    'Content-Type': 'application/json' // Optional: Set default content type
  }
});

// Add a response interceptor to handle HTTP errors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      displayToaster('error', "You're not authorized. Please login first.");
      setTimeout(() => {
        window.location.href = '/signin';
      }, 3000);
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor to attach the JWT token to each request
instance.interceptors.request.use(
  (config) => {
    // Retrieve the JWT token from cookies
    const token = getCookie(Constants.JWT_TOKEN);

    // If a token exists, attach it to the request's Authorization header
    if (token) {
      config.headers['x-access-token'] = token;
    }

    return config;
  }
);


function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const token = parts.pop();
    if (token) {
      return token.split(';').shift();
    }
  }
  return undefined; // or throw an error, return a default value, etc.
}

export default instance;

