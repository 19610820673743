import { Button, Divider, Input, Form } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { auth, provider } from "../../../config/FirebaseConfig";
import { signInWithPopup } from "firebase/auth";
import useNavigation from "../../../helper/UseNavigation";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { Link } from "react-router-dom";
import usePasswordValidator from "../../../helper/PasswordValidator";
import { useAuth } from "../../../helper/AuthContext";
import Constants from "../../../constants/Constants";
import Google from "../../../assets/icons/googleIcon.png";

function SignIn() {
  // navigation helper function
  const handleNavigation = useNavigation();
  // password validation helper function
  const validatePassword = usePasswordValidator();

  const authHandler = useAuth();
  // handles normal signup function
  const onFinish = async (values: any) => {
    if (
      values.email != null &&
      values.password != null &&
      values.email !== undefined &&
      values.password !== undefined
    ) {
      var requestData = {
        email: values.email,
        password: values.password,
        roles: Constants.ROLES[0].value,
      };
      const url = "/auth/signin";
      await API.post(url, requestData)
        .then((res) => {
          // toaster
          displayToaster("success", res.data.message);
          localStorage.setItem("email", values.email);

          // store token
          authHandler.login(res.data.accessToken);
          // Redirect to dashboard upon successful sign-in
          handleNavigation("/dashboard", null);
        })
        .catch((error) => {
          // toaster

          if (error) {
            displayToaster(
              "error",
              error.response != null
                ? error.response.data.message
                : "Please try again later"
            );

            // if (error.response != null && error.response.data.message === "Incorrect Password") {
            //   form.setFields([

            //     {
            //       name: 'password',
            //       errors: [error.response.data.message],
            //     },
            //   ]);
            // }
            // if (error.response != null && error.response.data.message === "User doesn't Exist!") {
            //   form.setFields([

            //     {
            //       name: 'email',
            //       errors: [error.response.data.message],
            //     },
            //   ]);
            // }
          }
        });
    }
  };
  const handleClick = () => {
    signInWithPopup(auth, provider).then(async (data) => {
      if (data.user.email != null && data.user.email !== undefined) {
        // call signin
        const user = data.user;
        const idToken = await user.getIdToken();
        const url = "/auth/google-signin";
        if (user.emailVerified) {
          var requestData = {
            email: user.email,
            token: idToken,
            roles: Constants.ROLES[0].value,
          };

          await API.post(url, requestData)
            .then(async (res) => {
              displayToaster("success", res.data.message);
              console.log("accessToken:", res.data.accessToken);
              authHandler.login(res.data.accessToken);

              let location = "/dashboard";
              if (user.email !== null) {
                localStorage.setItem("email", user.email);
              }

              handleNavigation(location, null);
            })
            .catch((error) => {
              // toaster
              displayToaster("error", error.response.data.message);
              handleNavigation("/register", null);
            });
        } else {
          displayToaster("error", "Email not verified!");
        }
      }
    });
  };

  return (
    <div className="flex justify-center w-full h-full align-center px-[30px]">
      <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
        <div className="flex flex-col gap-[14px]">
          <p className="m-0 text-[32px] font-bold">Welcome back!</p>
          <p className="m-0 text-base">
            Ease up your recruitment process by using our AI powered tools.
          </p>
        </div>
        <div className="flex flex-col gap-6">
          <Button size="large" onClick={handleClick}>
            <div className="flex items-center justify-center w-full gap-3">
              <img width={18} src={Google} alt="Google Icon"></img>
              Continue with Google
            </div>
          </Button>

          <Divider style={{ margin: "0px" }}>Or</Divider>
          <Form
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            className="flex flex-col "
            scrollToFirstError
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input type="email" size="large" placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                type="password"
                name="password"
                placeholder="input password"
                size="large"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <p className="m-0 text-base ">
              Forgot your password? <Link to="/reset-password">Reset</Link>
            </p>

            <Form.Item colon={false}>
              <Button
                type="primary"
                className="w-full mt-6"
                htmlType="submit"
                size="large"
              >
                Login
              </Button>
            </Form.Item>
            <p className="m-0 text-base text-center">
              Don't have an account? <Link to="/register">Signup</Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
