import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import EducationEditModal from "./EducationEditModal";
import API from "../../../API";
import dayjs from "dayjs";
import CandidateEducationDTO from "../../../DTOs/CandidateEducationDTO";
import CandidateGetInfoDTO from "../../../DTOs/CandidateGetAboutYouDTO";
import CandidateEducationService from "../../../Services/Candidate/CandidateEducationService";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import Constants from "../../../constants/Constants";
import { AxiosError } from "axios";
const { Option } = Select;

interface FieldOfStudy {
  fieldOfStudyId: string;
  name: string;
}

interface QualificationLevel {
  qualificationLevelId: string;
  name: string;
}
interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}
interface EducationProps {
  currentData: () => StepData | void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  isMobile: boolean;
  campaignId: string;
  setEducationSectionCompleted: (educationSectionCompleted: boolean) => void;
}
function Education({
  currentData,
  onNextClick,
  onPreviousClick,
  isMobile,
  campaignId,
  setEducationSectionCompleted,
}: EducationProps) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stepData = currentData();
  const [isStuding, setIsStuding] = useState(false);
  const [fieldOfStudy, setFieldOfStudy] = useState<FieldOfStudy[]>([]);
  const [studyStartDate, setStudyStartDate] = useState<any>(null);
  const [studyEndDate, setStudyEndDate] = useState<any>(null);
  const [educationEditId, setEducationEditId] = useState<number | null>(null);
  const [educations, setEducations] = useState<any | null>();
  const dateFormat = "DD/MM/YYYY";
  const dateFormatToDataBase = "YYYY-MM-DD";

  const [qualificationLevel, setQualificationLevel] = useState<
    QualificationLevel[]
  >([]);

  useEffect(() => {
    getEducations();
  }, []);

  const getEducations = async () => {
    const candidateEmail = retriveCandidateEmail();
    try {
      const data: CandidateGetInfoDTO = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
      };
      const resp = await CandidateEducationService.getAllEducations(data);

      const usereData = resp?.data.userData;

      setEducations(usereData);
      if (usereData.length > 0) {
        setEducationSectionCompleted(true);
      } else {
        setEducationSectionCompleted(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      const dropdownsString = localStorage.getItem("dropdowns");
      const dropdowns = dropdownsString
        ? JSON.parse(dropdownsString)
        : undefined;

      if (dropdowns != null && dropdowns !== undefined) {
        if (qualificationLevel.length === 0) {
          setQualificationLevel(dropdowns.qualificationLevel);
        }

        if (fieldOfStudy.length === 0) {
          setFieldOfStudy(dropdowns.fieldOfStudy);
        }
      } else {
        const url = "/campaign/dropdowns";
        await API.post(url)
          .then((res) => {
            localStorage.setItem("dropdowns", JSON.stringify(res.data.data));
            setQualificationLevel(res.data.data.qualificationLevel);
            setFieldOfStudy(res.data.data.fieldOfStudy);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    initializeData();
  }, []);

  const deleteEducation = async (candidate: any) => {
    const candidateEmail = retriveCandidateEmail();
    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        id: candidate.id,
        roles: Constants.ROLES[2].value,
      };
      await CandidateEducationService.deleteEducation(data);
      getEducations();
      message.success("Education deleted sucessfully");
    } catch (error) {
      console.log(error);
      message.error("Sorry something went wrong");
    }
  };

  const disabledDateStudyStartDate = (current: dayjs.Dayjs | null): boolean => {
    if (studyEndDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isAfter(studyEndDate, "day") ||
            dayjs(current).isSame(studyEndDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const disabledDateStudyEndDate = (current: dayjs.Dayjs | null): boolean => {
    if (studyStartDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isBefore(studyStartDate, "day") ||
            dayjs(current).isSame(studyStartDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const onFinish = async (value: any) => {
    const candidateEmail = retriveCandidateEmail();
    try {
      const data: CandidateEducationDTO = {
        endDate: isStuding
          ? null
          : value.studyEndDate.format(dateFormatToDataBase),
        isStudying: isStuding,
        fieldOfStudy: value.fieldOfStudy,
        institute: value.instituteName,
        qualification: value.qualification,
        qualificationLevel: value.qualificationLevel,
        startDate: value.studyStartDate.format(dateFormatToDataBase),
        summary: value.summary,
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
      };

      const resp = await CandidateEducationService.createEducation(data);

      getEducations();
      form.resetFields();
      setStudyStartDate(null);
      setStudyEndDate(null);
      setIsStuding(false);
      message.success("Education created successfully");

      setEducationSectionCompleted(true);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const getQualificationLevel = (id: string) => {
    const qualification = qualificationLevel.find(
      (level) => level.qualificationLevelId == id
    );

    return qualification?.name;
  };

  const getFieldOdStudy = (id: string) => {
    const field = fieldOfStudy.find((level) => level.fieldOfStudyId == id);

    return field?.name;
  };

  if (!stepData) {
    // Handle case where currentData returns void
    return <div>No data available</div>;
  }

  return (
    < >
      <div className="flex flex-col md:flex-row gap-6 md-0 mr-0 md:ml-[116px] md:mr-[116px] justify-between">
        <div className="md:max-w-[500px] w-full px-5 md:px-0">
          <div
            style={{ marginBottom: "16px" }}
            className="flex flex-col w-full gap-1"
          >
            <p className="m-0 text-sm">{stepData && stepData.stepName}</p>
            <p
              style={{ margin: "12px 0px" }}
              className="m-0 text-3xl font-bold"
            >
              {stepData && stepData.subTitle}
            </p>
            <p className="m-0 text-base text-[#595D62] leading-none">
              {stepData && stepData.description}
            </p>
          </div>
          <Form
            form={form}
            name="campaign-form"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            scrollToFirstError
          >
            <div>
              {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}

              <div>
                <p className="m-0 text-base mb-[8px]"
                  
                >
                  <span style={{color:"#ff4d4f"}}> *</span> Institute Name
                </p>
                <Form.Item
                  name="instituteName"
                  rules={[
                    {
                      required: true,
                      message: "Institute Name is required",
                    },
                  ]}
                >
                  <Input placeholder="Type your institute name" size="large" />
                </Form.Item>
              </div>

              <div className="grid grid-cols-1 gap-0 md:gap-4 md:grid-cols-2">
                <div>
                  <p className="m-0 text-base mb-[8px]"
                  >
                    <span style={{color:"#ff4d4f"}}> *</span> Field of study
                  </p>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Field of study is required",
                      },
                    ]}
                    name="fieldOfStudy"
                  >
                    <Select
                      placeholder="Select"
                      size="large"
                      showSearch
                      filterOption={(input, option) => {
                        if (!option || !option.children) return false;
                        return option.children
                          .toString()
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {fieldOfStudy.map((study) => (
                        <Option
                          key={study.fieldOfStudyId}
                          value={study.fieldOfStudyId}
                        >
                          {study.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p className="m-0 text-base mb-[8px]"
                  >
                    <span style={{color:"#ff4d4f"}}> *</span> Qualifications Level
                  </p>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Qualifications Level is required",
                      },
                    ]}
                    name="qualificationLevel"
                  >
                    <Select placeholder="Select" size="large">
                      {qualificationLevel.map((level) => (
                        <Option
                          key={level.qualificationLevelId}
                          value={level.qualificationLevelId}
                        >
                          {level.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]"
                >
                  <span style={{color:"#ff4d4f"}}> *</span> Qualifications
                </p>
                <Form.Item
                  name="qualification"
                  rules={[
                    {
                      required: true,
                      message: "Qualifications is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="B.Sc. (Hons) in Multimedia Technology"
                    size="large"
                  />
                </Form.Item>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]"
                >
                  <span style={{color:"#ff4d4f"}}> *</span> Study Period
                </p>
                <div className="grid grid-cols-1 gap-0 md:gap-4 md:grid-cols-2">
                  <Form.Item
                    name="studyStartDate"
                    rules={[
                      {
                        required: true,
                        message: "Start Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormat}
                      placeholder="Select start date"
                      style={{ width: "100%" }}
                      size="large"
                      disabledDate={disabledDateStudyStartDate}
                      onChange={setStudyStartDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                  {!isStuding && (
                    <Form.Item
                      name="studyEndDate"
                      rules={[
                        {
                          required: true,
                          message: "End Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        placeholder="Select end date"
                        style={{ width: "100%" }}
                        size="large"
                        disabledDate={disabledDateStudyEndDate}
                        onChange={setStudyEndDate}
                        inputReadOnly={true}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <Form.Item name="currentlyStuding">
                <Checkbox
                  checked={isStuding}
                  onChange={(e) => {
                    setIsStuding(e.target.checked);
                    if (e.target.checked) {
                      setStudyEndDate(null);
                      form.setFieldsValue({
                        studyEndDate: null,
                      });
                    }
                  }}
                >
                  I currently study here
                </Checkbox>
              </Form.Item>

              <div>
                <p className="m-0 text-base mb-[8px]"
                >
                  <span style={{color:"#ff4d4f"}}> *</span> Summary
                </p>

                <Form.Item
                  name="summary" // Add a unique name
                  rules={[
                    {
                      required: true,
                      message: "Summary is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ height: "132px" }}
                    size="large"
                    placeholder="Summary"
                  />
                </Form.Item>
              </div>

              <Button
                style={{ width: "100%", background: "#F5F5F5" }}
                className="mt-5 "
                htmlType="submit"
                size="large"
              >
                Add
              </Button>
            </div>

            {!isMobile ? (
              <div className="flex justify-between w-full">
                <Button
                  className="mt-5 "
                  onClick={onPreviousClick}
                  size="large"
                >
                  Previous
                </Button>

                <Button
                  type="primary"
                  className="mt-5 "
                  onClick={() => onNextClick()}
                  size="large"
                >
                  Next
                </Button>
              </div>
            ) : (
              <div className="mt-5 "></div>
            )}
          </Form>
        </div>
        <div className="max-w-[600px] w-full px-5 md:px-0">
          {/* set education if any */}
          <div className=" rounded-[10px]" style={{ background: "#F5F5F580" }}>
            {educations != null &&
              educations.map((data: any) => (
                <div
                className="p-[30px]"
                  style={{
                    
                    borderBottom: "2px solid #E2E2E2",
                  }}
                >
                  <div
                    className="flex items-start justify-between"
                  >
                    <div>
                      <p className="m-0 text-base font-bold">
                        {data.institute}
                      </p>
                      <p className="text-[14px] m-0"
                        
                      >
                        {getQualificationLevel(data.qualificationLevel)} in{" "}
                        {getFieldOdStudy(data.fieldOfStudy)}
                      </p>
                      <p className="m-0 mt-[5px] mb-[16px]">
                        {data.endDate != null
                          ? dayjs(data.startDate).format("MMMM YYYY") +
                            " - " +
                            dayjs(data.endDate).format("MMMM YYYY")
                          : dayjs(data.startDate).format("MMMM YYYY") +
                            " - Present"}
                      </p>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#595D62",
                        }}
                      >
                        {data.summary}
                      </p>
                    </div>
                    <div className="flex flex-row"
                    >
                      <Popconfirm
                        title="Delete Education"
                        description="Are you sure to delete this Education?"
                        onConfirm={() => deleteEducation(data)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          style: { backgroundColor: "#13265C" },
                        }}
                        placement={isMobile ? "bottom" : "left"}
                      >
                        <Button icon={<DeleteOutlined />} type="text"></Button>
                      </Popconfirm>

                      <Button
                        icon={<EditOutlined />}
                        type="text"
                        onClick={() => {
                          setIsModalOpen(true);
                          setEducationEditId(data.id);
                        }}
                      ></Button>
                      
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="flex justify-between w-full px-5 md:px-0">
          <Button
            style={{ height: "40px", width: "150px" }}
            className="mt-5 "
            onClick={onPreviousClick}
            size="large"
          >
            Previous
          </Button>

          <Button
            style={{ height: "40px", width: "150px" }}
            type="primary"
            className="mt-5 "
            size="large"
            onClick={() => onNextClick()}
          >
            Next
          </Button>
        </div>
      )}
      <div className="empty-div"></div>
      <EducationEditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        qualificationLevel={qualificationLevel}
        fieldOfStudy={fieldOfStudy}
        educationEditId={educationEditId}
        campaignId={campaignId}
        renderEducation={getEducations}
      />
    </>
  );
}

export default Education;
