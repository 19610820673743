import FacebookIcon from "../../../assets/icons/facebookIcon.png"; // Make sure to use correct path and filename

interface ShareWithFacebookButtonProps {
  url: string;
}

function ShareWithFacebookButton({ url }: ShareWithFacebookButtonProps) {
  const handleShare = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <>
      <img
        src={FacebookIcon}
        alt="Facebook Icon"
        onClick={handleShare}
      />
    </>
  );
}

export default ShareWithFacebookButton;
