import { Breadcrumb, Button, Tabs, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import TabPane from "antd/es/tabs/TabPane";
import CampaignDetail from "./CampaignDetail";
import AllApplicantsView from "./AllApplicantsView";
import CandidateDetailView from "./CandidateDetailView";
import { useEffect, useState } from "react";
import CandidateGeneralService from "../../../Services/Candidate/CandidateGeneralService";
import { AxiosError } from "axios";

interface CampaignPostViewProps {
  campaignId: string | undefined;
}

const CampaignPostView: React.FC<CampaignPostViewProps> = () => {
  const { id } = useParams();

  const [viewCandidateDetails, setViewCandidateDetails] =
    useState<boolean>(false);

  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  const [allApplicants, setAllApplicants] = useState<any[]>([]);

  const [allApplicantsCount, setAllApplicantsCount] = useState("");

  const [activeKey, setActiveKey] = useState<string>("1");
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const location = useLocation();
  const { jobTitle } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768); // You can adjust the threshold as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (id) {
      const requestData = {
        campaignId: id,
      };
      CandidateGeneralService.getCandidatesByCampaignId(requestData)
        .then((resp) => {
          const mappedCandidates = resp.data.profiles.map(
            (profile: any, ind: number) => {
              return { key: ind + 1, ...profile };
            }
          );
          setAllApplicants(mappedCandidates);
          setAllApplicantsCount(mappedCandidates.length);
        })
        .catch((error) => {
          console.log(error);
          const axiosError = error as AxiosError<any>;
          if (axiosError.response && axiosError.response.data) {
            message.error(axiosError.response.data.message);
          } else {
            message.error("Sorry something went wrong");
          }
        });
    }
  }, []);

  return (
    <div className="py-[50px] px-[30px] md:px-[116px] md:py-[30px] flex flex-col gap-[20px] md:h-full tabspanel">
      <div className="flex flex-row gap-[30px] items-center">
        <div className="flex flex-row gap-[10px] items-center">
          <Button
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              if (viewCandidateDetails) {
                setViewCandidateDetails(false);
                setActiveKey("2");
              } else {
                navigate("/dashboard");
              }
            }}
          />{" "}
          {isMobile ? (
            <div className="font-bold text-base">
              <Breadcrumb>
                {/* <Breadcrumb.Item>
                <Link to="/dashboard">Dashboard</Link>
              </Breadcrumb.Item> */}
                <Breadcrumb.Item>{jobTitle}</Breadcrumb.Item>
                {viewCandidateDetails && (
                  <Breadcrumb.Item>{candidateName}</Breadcrumb.Item>
                )}
              </Breadcrumb>
            </div>
          ) : (
            <p className="text-lg">Back</p>
          )}
        </div>

        {!isMobile && (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{jobTitle}</Breadcrumb.Item>
            {viewCandidateDetails && (
              <Breadcrumb.Item>{candidateName}</Breadcrumb.Item>
            )}
          </Breadcrumb>
        )}
      </div>

      {viewCandidateDetails ? (
        <CandidateDetailView campaignId={id} candidateEmail={candidateEmail} />
      ) : (
        <Tabs
          className="h-full"
          onChange={(activeKey: string) => {
            setActiveKey(activeKey);
          }}
          activeKey={activeKey}
        >
          <TabPane className="h-full" tab="Campaign Details" key="1">
            <CampaignDetail campaignId={id}></CampaignDetail>
          </TabPane>
          <TabPane
            className="h-full"
            tab={`Applicants •  ${allApplicantsCount}`}
            key="2"
          >
            <AllApplicantsView
              setViewCandidateDetails={setViewCandidateDetails}
              setCandidateName={setCandidateName}
              campaignId={id}
              setCandidateEmail={setCandidateEmail}
              allApplicants={allApplicants}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default CampaignPostView;
