import DOMPurify from 'dompurify';

// Sanitize HTML content to prevent XSS attacks
export function sanitizeHTMLString(content: string) {
  try {
    return DOMPurify.sanitize(content);
  } catch (error) {
    console.error('Error sanitizing HTML content:', error);
    return ''; // Return empty string in case of error
  }
}


