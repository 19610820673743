import CopyToUrlButton from "../buttons/buttons/CopyToUrlButton";
import ShareWithEmailButton from "../buttons/buttons/ShareWithEmailButton";
import ShareWithFacebookButton from "../buttons/buttons/ShareWithFacebookButton";
import ShareWithLinkedinButton from "../buttons/buttons/ShareWithLinkedinButton";


interface ShareCampaignProps {
  url: string;
}

function ShareCampaign({ url }: ShareCampaignProps) {

  return (
    <>
      <div className="flex flex-col items-center justify-center space-y-4">
        <div className="w-full flex justify-center ">
          <ShareWithEmailButton url={url} />
        </div>
        <div className="w-full flex justify-start m-0">
          <p className="text-base">Share via Social Media</p>
        </div>
        <div className="w-full flex justify-start space-x-2">
          <ShareWithFacebookButton url={url} />
          <ShareWithLinkedinButton url={url} />
        </div>
        <div className="w-full flex justify-center">
          <CopyToUrlButton url={url} />
        </div>
      </div>
    </>

  );
}

export default ShareCampaign;
