import axios from "../../API";
// import CandidateAboutYouDTO from "../../DTOs/CandidateAboutYouDTO";
// import CandidateGetInfoDTO from "../../DTOs/CandidateGetAboutYouDTO";
// import Constants from "../../constants/Constants";

const CandidateGeneralService = {
  getCandidatesByCampaignId: async (data: any) => {
    try {
      const url = "/candidate/view-candidates-by-campaignId";
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  exportCandidates: async (data: any) => {
    try {
      const url = "/candidate/export";
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default CandidateGeneralService;
