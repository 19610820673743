import axios from "../../API";
import CandidateEducationDTO from "../../DTOs/CandidateEducationDTO";
import CandidateGetInfoDTO from "../../DTOs/CandidateGetAboutYouDTO";
import Constants from "../../constants/Constants";

const CandidateEducationService = {
  createEducation: async (data: CandidateEducationDTO) => {
    try {
      const url = "/candidate/create-candidate";
      const params = {
        section: Constants.EDUCATION,
        action: Constants.ACTIONS[0],
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAllEducations: async (data: CandidateGetInfoDTO) => {
    try {
      const url = "/candidate/view-candidate";
      const params = {
        section: Constants.EDUCATION,
      };

      const response = await axios.post(url, data, {
        params: params,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  },

  getEducatoinById: async (data: any, id: number) => {
    try {
      const url = "/candidate/view-candidate";
      const params = {
        section: Constants.EDUCATION,
        id: id,
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  updateEducation: async (data: any) => {
    try {
      const url = "/candidate/create-candidate";
      const params = {
        section: Constants.EDUCATION,
        action: Constants.ACTIONS[1],
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  deleteEducation: async (data: any) => {
    try {
      const url = "/candidate/create-candidate";
      const params = {
        section: Constants.EDUCATION,
        action: Constants.ACTIONS[2],
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default CandidateEducationService;
