import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import type { UploadProps } from "antd";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CandidateAboutYouDTO from "../../../DTOs/CandidateAboutYouDTO";
import CandidateGetInfoDTO from "../../../DTOs/CandidateGetAboutYouDTO";
import CandidateAboutYouService from "../../../Services/Candidate/CandidateAboutYouService";
import { uploadResume } from "../../../helper/UploadResume";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import { AxiosError } from "axios";
import Constants from "../../../constants/Constants";

interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}

interface AboutYouProps {
  currentData: () => StepData | void;
  onNextClick: () => void;
  isMobile: boolean;
  campaignId: string;
  setAboutSectionComplete: (aboutSectionComplete: boolean) => void;
  setCurrentStep: (step: number) => void;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function AboutYou({
  currentData,
  onNextClick,
  isMobile,
  campaignId,
  setAboutSectionComplete,
  setCurrentStep,
}: AboutYouProps) {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState<any | undefined>();
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<any | undefined>([]);
  const [blob, setBlob] = useState<Blob | null>(null);
  const key = "updatable";

  useEffect(() => {
    const candidateEmail = retriveCandidateEmail();

    if (candidateEmail != null) {
      const data: CandidateGetInfoDTO = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
      };
      CandidateAboutYouService.getAboutYou(data)
        .then((resp) => {
          const userData = resp.data.userData;

          if (userData != null) {
            const data = {
              resumeLink: userData.resumeLink,
              fullName: userData.fullname,
              email: userData.email,
              aboutYou: userData.about,
            };

            setSelectedFile([
              {
                name: decodeURIComponent(
                  userData.resumeLink.match(/\/\d+-(.+)$/)[1]
                ),
              },
            ]);

            setPhoneNumber(userData.contactNumber);

            setAboutSectionComplete(true);

            form.setFieldsValue(data);
          } else {
            setAboutSectionComplete(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
      if (parsedPhoneNumber) {
        setisPhoneNumberValid(isPhoneValid(phoneNumber));
      }
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (selectedFile.length > 0) {
      handleUpload(selectedFile[0]);
    }
  }, [selectedFile]);

  const handleUpload = async (resumeFile: any) => {
    if (!("type" in resumeFile)) {
      return;
    }

    try {
      if (resumeFile != null && resumeFile !== undefined) {
        message.open({
          key,
          type: "loading",
          content: "Uploading...",
        });
        const reader = new FileReader();
        // Pass the imageFile to the FileReader instance
        reader.readAsDataURL(resumeFile);
        reader.onload = async () => {
          const base64Data = reader.result as string;
          const requestData = {
            file: base64Data,
            contentType: resumeFile.type,
            fileName: resumeFile.name,
          };
          const res = await uploadResume(requestData);
          message.destroy();
          message.success("Cv uploaded sucessfully");
          const resumeUrl = res.data.url;

          form.setFieldValue("resumeLink", resumeUrl);
        };
      } else {
      }
    } catch (error: any) {
      if (error.status == 413) {
        message.error("Sorry the uploaded file size is to large");
        setSelectedFile([]);
        form.setFieldsValue({
          resumeLink: null,
        });
      } else {
        message.error("Sorry something went wrong");
        setSelectedFile([]);
        form.setFieldsValue({
          resumeLink: null,
        });
      }
    }
  };

  const onFinish = async (values: any) => {
    if (!isPhoneNumberValid) {
      return;
    }

    try {
      const data: CandidateAboutYouDTO = {
        resumeLink: values.resumeLink,
        fullName: values.fullName,
        email: values.email,
        contactNumber: phoneNumber,
        aboutYou: values.aboutYou,
        campaignId: campaignId,
        roles: Constants.ROLES[2].value,
      };

      await CandidateAboutYouService.createAboutYou(data);
      setAboutSectionComplete(true);
      sessionStorage.setItem("candidateEmail", data.email);
      setCurrentStep(1);
      sessionStorage.setItem("currentStep", "1");
      message.success("About you section sucessfully added");
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const content = (
    <ul>
      <li>Formats: pdf,doc,docx</li>
    </ul>
  );

  const stepData = currentData();

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    accept: "application/pdf,.docx",
    fileList: selectedFile,
    onChange(info) {
      if (info.fileList.length > 0) {
        const fileName = info.file.name;
        const parts = fileName.split(".");
        const extension = parts[parts.length - 1];
        if (extension != "pdf" && extension != "docx" && extension != "doc") {
          setSelectedFile([]);
          form.setFieldsValue({
            resumeLink: null,
          });
          message.error(`Invalid file format.`);
          return;
        }
        setSelectedFile([info.file]);
        const file: any = info.file;
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
              const blobData = new Blob([reader.result], { type: file.type });
              setBlob(blobData);
            }
          };
          reader.readAsArrayBuffer(file);
        }
      } else {
        setSelectedFile([]);
        form.setFieldsValue({
          resumeLink: null,
        });
      }
    },
  };

  if (!stepData) {
    // Handle case where currentData returns void
    return <div>No data available</div>;
  }
  return (
    <>
      <div className="md:max-w-[500px] w-full px-5 md:px-0">
        <div className="flex flex-col w-full mb-8">
          <p className="m-0 text-sm mb-[5px]">
            {stepData && stepData.stepName}
          </p>
          <p className="m-0 text-3xl font-bold mb-[14px]">
            {stepData && stepData.subTitle}
          </p>
          <p className="m-0 text-base text-[#595D62] leading-none">
            {stepData && stepData.description}
          </p>
        </div>
        <Form
          form={form}
          name="candidate-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div>
            <div>
              <p className="m-0 text-base mb-[14px]"
                
              >
                <span style={{ color: "#ff4d4f" }}> *</span> Your Resume
                <Popover placement="rightTop" content={content}>
                  {/* <InfoCircleOutlined className="tooltip-info-icon" /> */}

                  <InfoCircleOutlined
                    style={{ fontSize: "16px", marginLeft: "5px" }}
                  />
                </Popover>
              </p>
              <Form.Item
                name="resumeLink"
                rules={[
                  {
                    required: true,
                    message: "Resume is required",
                  },
                ]}
              >
                <Upload {...props}>
                  <Button
                    style={{ color: "#13265C", borderColor: "#13265C" }}
                    size="large"
                    icon={<UploadOutlined />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </div>

            <div>
              <p className="m-0 text-base mb-[8px]"
                
              >
                <span style={{ color: "#ff4d4f" }}> *</span> Full Name
              </p>

              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Full Name is required",
                  },
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: "Enter a valid Full Name",
                  },
                ]}
              >
                <Input placeholder="Full Name" size="large" />
              </Form.Item>
            </div>

            <div>
              <p className="m-0 text-base mb-[8px]"
              >
                <span style={{ color: "#ff4d4f" }}> *</span> Email
              </p>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <p className="m-0 text-base mb-[8px]"
              >
                <span style={{ color: "#ff4d4f" }}> *</span> Contact
              </p>

              <PhoneInput
                defaultCountry="lk"
                value={phoneNumber}
                onChange={(phone) => {
                  setPhoneNumber(phone);
                }}
              />
              {!isPhoneNumberValid && (
                <span
                  style={{
                    color: "#ff4d4f",
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                  }}
                >
                  Enter a valid contact number
                </span>
              )}
            </div>

            <div>
              <p className="m-0 text-base mb-[8px]"
              >
                <span style={{ color: "#ff4d4f" }}> *</span> About You
              </p>
              <Form.Item
                name="aboutYou" // Add a unique name
                rules={[
                  {
                    required: true,
                    message: "About You is required",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: "132px" }}
                  size="large"
                  placeholder="Description"
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item className="flex justify-end w-full" colon={false}>
            {isMobile ? (
              <div className="flex justify-between w-full">
                <Button
                 
                  type="primary"
                  className="mt-5 "
                  htmlType="submit"
                  size="large"
                  onClick={() => {
                    setisPhoneNumberValid(isPhoneValid(phoneNumber));
                  }}
                >
                  Next
                </Button>
              </div>
            ) : (
              <Button
                type="primary"
                className="mt-5 "
                htmlType="submit"
                size="large"
                onClick={() => {
                  setisPhoneNumberValid(isPhoneValid(phoneNumber));
                }}
              >
                Next
              </Button>
            )}
          </Form.Item>
        </Form>
        <div className="empty-div"></div>
      </div>
    </>
  );
}
export default AboutYou;
