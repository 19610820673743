import axios from "../../API";
import CandidateAboutYouDTO from "../../DTOs/CandidateAboutYouDTO";
import CandidateGetInfoDTO from "../../DTOs/CandidateGetAboutYouDTO";
import Constants from "../../constants/Constants";

const CandidateAboutYouService = {
  createAboutYou: async (data: CandidateAboutYouDTO) => {
    try {
      const params = {
        section: Constants.ABOUT_YOU,
      };

      const url = "/candidate/create-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAboutYou: async (data: CandidateGetInfoDTO) => {
    try {
      const params = {
        section: Constants.ABOUT_YOU,
      };
      const url = "/candidate/view-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default CandidateAboutYouService;
