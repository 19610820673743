import AvatarMenu from "../components/menu/AvatarMenu";
import Logo from "../assets/images/logo.svg";
import { useEffect, useState } from "react";
import API from "../API";
import { displayToaster } from "../helper/Toaster";
import { ExtractInitials } from "../helper/ExtractInitials";

function Header() {
  const [initials, setInitials] = useState("");

  useEffect(() => {
    const storedInitials = localStorage.getItem("initials");
    const initializeData = async () => {
      const url = "/user/view-profile";
      var requestData = {
        email: localStorage.getItem("email"),
      };
      // console.log("header layout:view profile")
      await API.post(url, requestData)
        .then(async (res) => {
          const responseData = res.data.data;
          if (responseData) {
            const initials = await ExtractInitials(responseData.fullName);
            localStorage.setItem("initials", initials);
            setInitials(initials);
            // console.log("initials",initials)
          }
        })
        .catch((error) => {
          // toaster
          displayToaster("error", error.response.data.message);
        });
    };

    if (storedInitials !== null) {
      setInitials(storedInitials);
    } else {
      initializeData();
    }
  }, []);

  return (
   
      <div
        className="flex flex-row justify-between py-[10px] items-center  px-[30px] md:px-[116px]"
        style={{ borderBottom: "1px solid #F5F5F5" }}
      >
        <img src={Logo} className="logo" alt="Icon"></img>

        <AvatarMenu initials={initials}></AvatarMenu>
      </div>
    
  );
}

export default Header;
