import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DashBoardNoCompanyProfile from "../../../layout/dashboard/DashBoardNoCompanyProfile";
import DashBoardNoCampaign from "../../../layout/dashboard/DashBoardNoCampaign";
import { useEffect, useState } from "react";
import DashBoardCampaigns from "../../../layout/dashboard/DashBoardCampaigns";
import { Link } from "react-router-dom";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import dayjs from "dayjs";

function Dashboard() {
  const [userData, setUserData] = useState<{
    email: string;
    fullName: string;
    isWorkSpaceCreated: boolean;
    isCompanyProfileCreated: boolean;
    isCampaignCreated: boolean;
  } | null>(null);
  const firstName = userData?.fullName ? userData.fullName.split(" ")[0] : "";
  const [greeting, setGreeting] = useState("Good Morning");

  useEffect(() => {
    const generateGreeting = () => {
      const currentHour = dayjs().hour();
      let salutation = "Good Morning";
      if (currentHour >= 1 && currentHour < 12) {
        salutation = "Good morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        salutation = "Good afternoon";
      } else {
        salutation = "Good evening";
      }

      setGreeting(salutation);
    };

    generateGreeting();
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      const url = "/user/view-profile";
      var requestData = {
        email: localStorage.getItem("email"),
      };
      // console.log("dashboard:view profile")
      await API.post(url, requestData)
        .then(async (res) => {
          const responseData = res.data.data;

          if (responseData) {
            setUserData(responseData);
          }
        })
        .catch((error) => {
          // toaster
          displayToaster("error", error.response.data.message);
        });
    };

    initializeData();
  }, []);

  return (
    <div className="flex flex-col gap-[50px] py-[50px] px-[30px] md:px-[116px] md:py-[30px]">
      <div className="flex flex-col gap-9 ">
        <div className="flex flex-col items-start justify-between gap-1 md:items-center md:flex-row">
          <div className="flex flex-col items-start">
            <p className="text-[14px] m-0">
              {greeting}, {firstName}!
            </p>
            <p className="text-[32px] font-bold m-0">Dashboard</p>
          </div>

          <Link to="/campaign">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className=""
              htmlType="submit"
              size="large"
            >
              Create Campaign
            </Button>
          </Link>
        </div>

        <div className="p-[20px] bg-[#FAFAFA] rounded-2xl border-[#E2E2E2] border-[1px] border-solid">
          {userData != null &&
            userData !== undefined &&
            userData.isWorkSpaceCreated === true &&
            userData.isCompanyProfileCreated === false && (
              <DashBoardNoCompanyProfile />
            )}
          {userData != null &&
            userData !== undefined &&
            userData.isWorkSpaceCreated === true &&
            userData.isCompanyProfileCreated === true &&
            userData.isCampaignCreated === false && <DashBoardNoCampaign />}
          {userData != null &&
            userData !== undefined &&
            userData.isWorkSpaceCreated === true &&
            userData.isCompanyProfileCreated === true &&
            userData.isCampaignCreated === true && <DashBoardCampaigns />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
