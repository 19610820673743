import { useNavigate } from 'react-router-dom';

function useNavigation() {
  const navigate = useNavigate();

  // const handleNavigation = (path: string) => {
  //   // Example navigation to a different route
  //   navigate(path);
  // };


const handleNavigation = (path: string, props: any) => {
  // Example navigation to a different route with props
  navigate(path, { state: props });
};

  return handleNavigation;
}

export default useNavigation;


