import { Button, Form, Input } from "antd";
import API from "../../../../src/API";
import { displayToaster } from "../../../helper/Toaster";

interface ShareWithEmailButtonProps {
  url: string;
}

function ShareWithEmailButton({ url }: ShareWithEmailButtonProps) {

  const [form] = Form.useForm();

  const handleShare = async () => {

    try {
      const values = form.getFieldsValue();

      console.log("handleShare...........", values)
      if (values.toList != null && values.toList !== undefined) {

        const toList = values.toList;

        const toListArray = toList.split(',').map((item: string) => item.trim());

        const data = {
          toEmail: toListArray,
          url: url
        }

        if (toListArray.length > 1) {
          throw Error("Invalid Email")
        }

        await API.post('/campaign/share', data)
          .then((res) => {
            form.setFieldsValue({ toList: null });
            displayToaster("success", res.data.message);
          })
          .catch((error) => {

            displayToaster("error", error.response.data.message);
          });
      }
    } catch (error) {
      displayToaster("error", "Invalid Email");
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-2">
        <p className="text-base">Share via Mail</p>
        <Form layout="inline" form={form}>
          <div className="flex w-full ">
            <Form.Item
              name="toList"
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
              ]}
              className="flex-1 "
              style={{  marginBottom: 0, height: '40px' , flex: 1 }}
            >
              <Input
                type="email"
                className="p-3 border border-[#E2E2E2] rounded-lg w-full"
                style={{ marginBottom: 0, height: '40px' }}
                // placeholder="To email list"
              />
            </Form.Item>
            
              <Button
                htmlType="button"
                onClick={handleShare}
                style={{ marginBottom: 0, height: '40px' }}
              >
                Send
              </Button>
            
          </div>
        </Form>
      </div>
    </>
  );
}

export default ShareWithEmailButton;
