import React, { ReactNode } from "react";
import { Modal, Button } from "antd";

interface CustomModalProps {
  onClose: () => void;
  onOk: () => void;
  visible: boolean;
  modalData: {
    title: string;
    imageUrl?: string;
    okText: string;
    cancelText: string;
    bodyTitle: string;
    bodySubTitle: string;
    bodyIcon: ReactNode;
  };
}

const CustomModal: React.FC<CustomModalProps> = ({
  onClose,
  visible,
  modalData,
  onOk,
}) => {
  const handleOk = () => {
    onOk();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={modalData.title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={modalData.okText}
      cancelText={modalData.cancelText}
      centered
      footer={
        <div className="flex gap-4">
          <Button className="w-full" size="large" onClick={handleCancel}>
            {modalData.cancelText}
          </Button>

          <Button
            type="primary"
            size="large"
            className="w-full"
            onClick={handleOk}
          >
            {modalData.okText}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col items-center w-full gap-[14px] mb-5">
        <div className="text-3xl font-bold m-o">{modalData.bodyIcon}</div>

        <p className="text-3xl font-bold m-o">{modalData.bodyTitle}</p>

        <p className="m-0 text-base">{modalData.bodySubTitle}</p>
      </div>
    </Modal>
  );
};

export default CustomModal;
