import {
  Button,
  Form,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Slider,
  Tag,
  message,
} from "antd";
import styled from "styled-components";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import Constants from "../../../constants/Constants";
import { createCampaign } from "../../../helper/CreateCampaign";
import { displayToaster } from "../../../helper/Toaster";
import { getCampaignDetails } from "../../../helper/GetCampaignDetails";
import API from "../../../API";
import { createDraftCampaign } from "../../../helper/CreateDraftCampaign";
const { Option } = Select;

interface SoftSkill {
  softSkillsId: string;
  name: string;
}

interface HardSkill {
  hardSkillsId: string;
  name: string;
}

interface ToolsTechnologies {
  toolsTechnologiesId: string;
  name: string;
}

interface Other {
  otherId: string;
  name: string;
}

interface ToolTechnology {
  tools: {
    toolsTechnologiesId: number;
  };
  experience: number;
  priority: number;
}

// Styled Form component
const StyledForm = styled(Form)`
  .ant-form-item-label {
    display: none; /* Hide default label */
  }

  .ant-form-item-required:before {
    display: none; /* Hide default asterisk */
  }

  .ant-form-item-label > label::after {
    content: "*"; /* Custom asterisk */
    margin-left: 4px; /* Adjust asterisk position */
    color: black; /* Asterisk color */
  }
`;

interface SkillsProps {
  disableDraftButton: (status: boolean) => void;
  campaignId: string | undefined;
  onIsValidChange: (isValid: boolean) => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  clickSaveDraft: (status: boolean) => void;
  saveDraftFlag: boolean | undefined;
  setSkillsSectionCompleted: (skillsSectionCompleted: boolean) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const Skills: React.FC<SkillsProps> = ({
  disableDraftButton,
  campaignId,
  onIsValidChange,
  onNextClick,
  onPreviousClick,
  clickSaveDraft,
  saveDraftFlag,
  setSkillsSectionCompleted,
  setCurrentStep,
}) => {
  const marks = {
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
    60: "60",
    70: "70",
    80: "80",
    90: "90",
    100: "100",
  };

  const sliderStyle = {
    width: "100%", // Adjust width as needed
    marginTop: "20px", // Add vertical margin as needed
    // Customize track and handle colors
    trackStyle: {
      backgroundColor: "red", // Custom track color
    },
    handleStyle: {
      borderColor: "red", // Custom handle border color
      backgroundColor: "red", // Custom handle background color
    },
    // Customize tooltip color
    dotStyle: {
      borderColor: "red", // Custom tooltip color
    },
    dotActiveBorderColor: {
      borderColor: "red",
    },
    trackBg: {
      color: "red",
    },
    handleSizeHover: 0,
    handleSize: 100,
  };

  const [form] = Form.useForm();

  const [sofSkills, setSoftSkills] = useState<SoftSkill[]>([]);

  const [hardSkills, setHardSkills] = useState<HardSkill[]>([]);

  const [toolsTechnologies, setToolsTechnologies] = useState<
    ToolsTechnologies[]
  >([]);

  const [selectedCampaignId, setSelectedCampaignId] = useState(
    campaignId != null
      ? campaignId
      : sessionStorage.getItem("createdCampaignId")
  );

  const [softSkillsPercentageValue, setSoftSkillsPercentageValue] =
    useState<number>(0);

  const [hardSkillsPercentageValue, setHardSkillsPercentageValue] =
    useState<number>(0);

  const [
    toolsAndTechSkillsPercentageValue,
    setToolsAndTechSkillsPercentageValue,
  ] = useState<number>(0);

  const [otherSkillsPercentageValue, setOtherSkillsPercentageValue] =
    useState<number>(0);

  const [percentegeExceeded, setPercentageExceeded] = useState<boolean>(false);

  const [totalPercentage, setTotalPercentage] = useState<number>(0);

  console.log(selectedCampaignId, "selectedCampaignId");

  const [other, setOther] = useState<Other[]>([]);

  type TagRender = SelectProps["tagRender"];

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="#4E46B41F"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4, color: "#13265C" }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    disableDraftButton(true);
  }, []);

  // const onrefreshWithoutCampaignCreated = () => {

  // }

  // useEffect(() => {
  //   if (state == null) {
  //     onrefreshWithoutCampaignCreated();
  //   }
  // }, []);

  useEffect(() => {
    if (
      softSkillsPercentageValue != null &&
      hardSkillsPercentageValue != null &&
      toolsAndTechSkillsPercentageValue != null &&
      otherSkillsPercentageValue != null
    ) {
      const totalPercentage =
        softSkillsPercentageValue +
        hardSkillsPercentageValue +
        toolsAndTechSkillsPercentageValue +
        otherSkillsPercentageValue;

      if (totalPercentage >= 100) {
        setPercentageExceeded(true);
      } else {
        setPercentageExceeded(false);
      }
    }
  }, [
    softSkillsPercentageValue,
    hardSkillsPercentageValue,
    toolsAndTechSkillsPercentageValue,
    otherSkillsPercentageValue,
  ]);

  useEffect(() => {
    const handleSaveAsDraft = async () => {
      const requestData = {
        email: localStorage.getItem("email"),
        id: selectedCampaignId,
        softSkills: form.getFieldValue("softSkills"),
        hardSkills: form.getFieldValue("hardSkills"),
        toolsTechnologies: form.getFieldValue("toolsTechnologiesList"),
        other: form.getFieldValue("otherList"),
        ratios: Constants.SKILL_TYPES.map((skillType: any, ind) => {
          return {
            type: skillType,
            percentage: getPercentageValue(ind, form.getFieldsValue()),
          };
        }),
      };

      const params = {
        section: Constants.SKILLS,
      };

      await createDraftCampaign(requestData, params)
        .then(async (res) => {
          const responseData = res.data;
          displayToaster("success", responseData.message);
          fetchCampaignDetails();
        })
        .catch((error) => {
          displayToaster("error", error.response.data.message);
        });

      clickSaveDraft(false);
    };

    if (
      clickSaveDraft !== undefined &&
      clickSaveDraft !== null &&
      saveDraftFlag !== false
    ) {
      handleSaveAsDraft();
      // console.log("saveDraftFlag:",saveDraftFlag)
      clickSaveDraft(false);
    }
    // console.log("saveDraftFlagout:",saveDraftFlag)

    const fetchCampaignDetails = async () => {
      if (selectedCampaignId != null && selectedCampaignId !== undefined) {
        try {
          const requestData = {
            email: localStorage.getItem("email"),
            id: selectedCampaignId,
          };

          const res = await getCampaignDetails(requestData);
          const responseData = res.data.data;
          console.log(responseData, "respdata");

          let skillSectionCompleted = true;

          if (responseData.jobDetails.toolsTechnologies.length > 0) {
            form.setFieldsValue({
              toolsTechnologiesList:
                responseData.jobDetails.toolsTechnologies.map(
                  (tool: {
                    tools: { toolsTechnologiesId: any };
                    experience: any;
                    priority: any;
                  }): ToolTechnology => ({
                    tools: {
                      toolsTechnologiesId: tool.tools.toolsTechnologiesId,
                    },
                    experience: tool.experience,
                    priority: tool.priority,
                  })
                ),
            });
          } else {
            skillSectionCompleted = false;
            form.setFieldsValue({
              toolsTechnologiesList: [{ tools: {}, experience: "" }],
            });
          }

          if (responseData.jobDetails.other.length > 0) {
            form.setFieldsValue({
              otherList: responseData.jobDetails.other.map(
                (otherItem: { others: { otherId: any }; experience: any }) => ({
                  others: {
                    otherId: otherItem.others.otherId,
                  },
                  experience: otherItem.experience,
                })
              ),
            });
          } else {
            skillSectionCompleted = false;
            form.setFieldsValue({
              otherList: [{ others: {}, experience: 0 }],
            });
          }

          // softSkills
          // if (responseData.jobDetails.softSkills.length > 0) {
          //   form.setFieldsValue({
          //     softSkillsList: responseData.jobDetails.softSkills.map(
          //       (softSkillItem: {
          //         softSkills: { softSkillsId: any };
          //         experience: any;
          //       }) => ({
          //         softSkills: {
          //           softSkillsId: softSkillItem.softSkills.softSkillsId,
          //         },
          //         experience: softSkillItem.experience,
          //       })
          //     ),
          //   });
          // } else {
          //   skillSectionCompleted = false;
          //   form.setFieldsValue({
          //     softSkillsList: [{ softSkills: {}, experience: "" }],
          //   });
          // }

          // hardSkills
          // if (responseData.jobDetails.hardSkills.length > 0) {
          //   form.setFieldsValue({
          //     hardSkillsList: responseData.jobDetails.hardSkills.map(
          //       (hardSkillsItem: {
          //         hardSkills: { hardSkillsId: any };
          //         experience: any;
          //       }) => ({
          //         hardSkills: {
          //           hardSkillsId: hardSkillsItem.hardSkills.hardSkillsId,
          //         },
          //         experience: hardSkillsItem.experience,
          //       })
          //     ),
          //   });
          // } else {
          //   skillSectionCompleted = false;
          //   form.setFieldsValue({
          //     hardSkillsList: [{ hardSkills: {}, experience: "" }],
          //   });
          // }

          setSkillsSectionCompleted(skillSectionCompleted);

          form.setFieldsValue({
            softSkills: responseData.jobDetails.softSkills,
            hardSkills: responseData.jobDetails.hardSkills,
          });

          // remove this later
          if (responseData.jobDetails.ratios.length > 0) {
            form.setFieldsValue({
              softSkillsPercentage:
                responseData.jobDetails.ratios[0].percentage,
              hardSkillsPercentage:
                responseData.jobDetails.ratios[1].percentage,
              toolsAndTechnologiesSkillsPercentage:
                responseData.jobDetails.ratios[2].percentage,
              OtherSkillsPercentage:
                responseData.jobDetails.ratios[3].percentage,
            });

            setSoftSkillsPercentageValue(
              responseData.jobDetails.ratios[0].percentage
            );

            setHardSkillsPercentageValue(
              responseData.jobDetails.ratios[1].percentage
            );

            setToolsAndTechSkillsPercentageValue(
              responseData.jobDetails.ratios[2].percentage
            );

            setOtherSkillsPercentageValue(
              responseData.jobDetails.ratios[3].percentage
            );

            setTotalPercentage(
              responseData.jobDetails.ratios[0].percentage +
                responseData.jobDetails.ratios[1].percentage +
                responseData.jobDetails.ratios[2].percentage +
                responseData.jobDetails.ratios[3].percentage
            );

            setPercentageExceeded(true);

            disableDraftButton(false);
          }

          return responseData;
        } catch (error) {
          return null;
        }
      }
    };

    if (selectedCampaignId != null && selectedCampaignId !== undefined) {
      fetchCampaignDetails();
    }
  }, [selectedCampaignId, clickSaveDraft, form, saveDraftFlag]);

  useEffect(() => {
    const initializeData = async () => {
      const dropdownsString = localStorage.getItem("dropdowns");
      const dropdowns = dropdownsString
        ? JSON.parse(dropdownsString)
        : undefined;

      if (dropdowns != null && dropdowns !== undefined) {
        setSoftSkills(dropdowns?.softSkills);
        setHardSkills(dropdowns?.hardSkills);
        setToolsTechnologies(dropdowns?.toolsTechnologies);
        setOther(dropdowns?.other);
      } else {
        const url = "/campaign/dropdowns";
        await API.post(url)
          .then((res) => {
            localStorage.setItem("dropdowns", JSON.stringify(res.data.data));
          })
          .catch((error) => {
            displayToaster("error", error.response.data.message);
          });
      }
    };

    initializeData();
  }, []);

  const handleDisableSaveDraftButton = async () => {
    const values = form.getFieldsValue();
    const errors = form.getFieldsError();

    if (values && errors) {
      let hasEmptyField = false;
      let emptyFieldsCount = 0;
      let hasError = false;

      Object.values(values).forEach((value) => {
        if (!value && value != 0) {
          emptyFieldsCount += 1;
        }
      });

      if (form.getFieldValue("otherList")) {
        form.getFieldValue("otherList").forEach((item: any) => {
          if (item == undefined || item == null) {
            emptyFieldsCount += 1;
          } else if (
            !item ||
            !item.others ||
            Object.keys(item.others).length == 0 ||
            (!item.experience && typeof item.experience !== "number")
          ) {
            emptyFieldsCount += 1;
          }
        });
      }

      if (form.getFieldValue("toolsTechnologiesList")) {
        form.getFieldValue("toolsTechnologiesList").forEach((tool: any) => {
          if (tool == undefined || tool == null) {
            emptyFieldsCount += 1;
          } else if (
            !tool ||
            !tool.tools ||
            Object.keys(tool.tools).length == 0 ||
            (!tool.experience && typeof tool.experience !== "number") ||
            (!tool.priority && typeof tool.priority !== "number")
          ) {
            emptyFieldsCount += 1;
          }
        });
      }

      hasError = errors.some((field) => field.errors.length > 0);

      const isPercentageMatched =
        form.getFieldValue("softSkillsPercentage") +
          form.getFieldValue("hardSkillsPercentage") +
          form.getFieldValue("toolsAndTechnologiesSkillsPercentage") +
          form.getFieldValue("OtherSkillsPercentage") >=
        100;

      hasEmptyField = emptyFieldsCount == 0 ? false : true;

      console.log(emptyFieldsCount, "emptyFieldsCount");

      console.log(hasEmptyField, "hasEmptyField");

      console.log(hasError, "hasError");

      console.log(isPercentageMatched, "isPercentageMatched");

      disableDraftButton(hasEmptyField || hasError || !isPercentageMatched);
    } else {
      disableDraftButton(true);
    }
  };

  const getPercentageValue = (ind: number, values: any) => {
    if (ind == 0) {
      return values.softSkillsPercentage;
    } else if (ind == 1) {
      return values.hardSkillsPercentage;
    } else if (ind == 2) {
      return values.toolsAndTechnologiesSkillsPercentage;
    } else if (ind == 3) {
      console.log(values.OtherSkillsPercentage)
      return values.OtherSkillsPercentage != null && values.OtherSkillsPercentage !== undefined ? values.OtherSkillsPercentage : 0;
    }
  };

  const onFinish = async (values: any) => {
    if (!percentegeExceeded) {
      message.error("Percentages doesn't add upto 100%");
      return;
    }

    const emptyOtherArr = [{others:{},experience:0}];
    const requestData = {
      email: localStorage.getItem("email"),
      id: selectedCampaignId,
      softSkills: values.softSkills,
      hardSkills: values.hardSkills,
      toolsTechnologies: values.toolsTechnologiesList,
      other: values.otherList.length > 0 ? values.otherList: emptyOtherArr,
      ratios: Constants.SKILL_TYPES.map((skillType: any, ind) => {
        return { type: skillType, percentage: getPercentageValue(ind, values) };
      }),
    };
    const params = {
      section: Constants.SKILLS,
    };

    await createCampaign(requestData, params)
      .then(async (res) => {
        const responseData = res.data;
        displayToaster("success", responseData.message);
        setSkillsSectionCompleted(true);
        setCurrentStep(2);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <>
      <StyledForm
        form={form}
        name="campaign-form"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={handleDisableSaveDraftButton}
        scrollToFirstError
      >
        <div>
          <div className="flex justify-between mb-4">
            <p id="soft-skills" className="text-lg font-bold">
              Soft Skills
            </p>

            <Form.Item
              name="softSkillsPercentage"
              rules={[
                {
                  required: true,
                  message: "Percentage is required",
                },
              ]}
            >
              <InputNumber
                className="w-[150px]"
                placeholder="Percentage"
                size="large"
                width={150}
                min={0}
                max={100}
                formatter={(value: any) => {
                  if (!value) {
                    return "";
                  } else {
                    if (
                      value &&
                      Number(value) +
                        Number(hardSkillsPercentageValue) +
                        Number(toolsAndTechSkillsPercentageValue) +
                        Number(otherSkillsPercentageValue) >
                        100
                    ) {
                      form.setFieldsValue({
                        softSkillsPercentage: softSkillsPercentageValue,
                      });
                      message.error("Percentages exceeds 100%");
                      return `${form.getFieldValue("softSkillsPercentage")}%`;
                    }
                    return `${Math.floor(value)}%`;
                  }
                }}
                parser={(value) => {
                  // Remove non-digit characters
                  const parsedValue = value?.replace(/[^\d]/g, "") || "0";
                  return parseInt(parsedValue, 10);
                }}
                onChange={(value: any) => {
                  if (
                    value &&
                    value +
                      hardSkillsPercentageValue +
                      toolsAndTechSkillsPercentageValue +
                      otherSkillsPercentageValue >
                      100
                  ) {
                    return;
                    console.log(
                      "softSkillsPercentageValue",
                      softSkillsPercentageValue
                    );

                    form.setFieldsValue({
                      softSkillsPercentage: softSkillsPercentageValue,
                    });
                    message.error("Percentages exceeds 100%");
                    return;
                  } else if (
                    (value &&
                      softSkillsPercentageValue &&
                      value < softSkillsPercentageValue) ||
                    value == 0
                  ) {
                    setSoftSkillsPercentageValue(value);
                  } else if (percentegeExceeded) {
                    form.setFieldsValue({
                      softSkillsPercentage: softSkillsPercentageValue,
                    });
                    return;
                  } else {
                    setSoftSkillsPercentageValue(value);
                  }
                }}
                onKeyPress={(event) => {
                  // Prevent input of non-numeric characters
                  const charCode = event.which ? event.which : event.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="softSkills"
            label="Soft Skills"
            rules={[
              {
                required: true,
                message: "Soft Skills are required",
              },
            ]}
          >
            <Select
              mode="multiple"
              size="large"
              className="w-full"
              tagRender={tagRender}
              options={sofSkills.map((item) => ({
                value: item.softSkillsId,
                label: item.name,
              }))}
            />
          </Form.Item>
        </div>

        <div className="flex justify-between mb-4">
          <p id="hard-skills" className="text-lg font-bold">
            Hard Skills
          </p>

          <Form.Item
            name="hardSkillsPercentage"
            rules={[
              {
                required: true,
                message: "Percentage is required",
              },
            ]}
          >
            <InputNumber<number>
              className="w-[150px]"
              placeholder="Percentage"
              size="large"
              min={0}
              max={100}
              formatter={(value: any) => {
                if (!value) {
                  return "";
                } else {
                  if (
                    value &&
                    Number(value) +
                      Number(softSkillsPercentageValue) +
                      Number(toolsAndTechSkillsPercentageValue) +
                      Number(otherSkillsPercentageValue) >
                      100
                  ) {
                    form.setFieldsValue({
                      hardSkillsPercentage: hardSkillsPercentageValue,
                    });
                    message.error("Percentages exceeds 100%");
                    return `${form.getFieldValue("hardSkillsPercentage")}%`;
                  }
                  return `${Math.floor(value)}%`;
                }
              }}
              parser={(value) => {
                // Remove non-digit characters
                const parsedValue = value?.replace(/[^\d]/g, "") || "0";
                return parseInt(parsedValue, 10);
              }}
              onChange={(value: any) => {
                if (
                  value &&
                  value +
                    softSkillsPercentageValue +
                    toolsAndTechSkillsPercentageValue +
                    otherSkillsPercentageValue >
                    100
                ) {
                  return;
                  form.setFieldsValue({
                    hardSkillsPercentage: hardSkillsPercentageValue,
                  });
                  message.error("Percentages exceeds 100%");
                  return;
                } else if (
                  (value &&
                    hardSkillsPercentageValue &&
                    value < hardSkillsPercentageValue) ||
                  value == 0
                ) {
                  setHardSkillsPercentageValue(value);
                } else if (percentegeExceeded) {
                  form.setFieldsValue({
                    hardSkillsPercentage: hardSkillsPercentageValue,
                  });

                  return;
                } else {
                  setHardSkillsPercentageValue(value);
                }
              }}
              onKeyPress={(event) => {
                // Prevent input of non-numeric characters
                const charCode = event.which ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="hardSkills"
          label="Hard Skills"
          rules={[
            {
              required: true,
              message: "Hard Skills are required",
            },
          ]}
        >
          <Select
            mode="multiple"
            size="large"
            className="w-full"
            tagRender={tagRender}
            options={hardSkills.map((item) => ({
              value: item.hardSkillsId,
              label: item.name,
            }))}
          />
        </Form.Item>

        <div className="flex justify-between mb-4">
          <p id="tools" className="text-lg font-bold">
            Tools & Technologies
          </p>

          <Form.Item
            name="toolsAndTechnologiesSkillsPercentage"
            rules={[
              {
                required: true,
                message: "Percentage is required",
              },
            ]}
          >
            <InputNumber<number>
              className="w-[150px]"
              size="large"
              min={0}
              max={100}
              placeholder="Percentage"
              formatter={(value: any) => {
                if (!value) {
                  return "";
                } else {
                  if (
                    value &&
                    Number(value) +
                      Number(softSkillsPercentageValue) +
                      Number(hardSkillsPercentageValue) +
                      Number(otherSkillsPercentageValue) >
                      100
                  ) {
                    form.setFieldsValue({
                      toolsAndTechnologiesSkillsPercentage:
                        toolsAndTechSkillsPercentageValue,
                    });
                    message.error("Percentages exceeds 100%");
                    return `${form.getFieldValue(
                      "toolsAndTechnologiesSkillsPercentage"
                    )}%`;
                  }
                  return `${Math.floor(value)}%`;
                }
              }}
              parser={(value) => {
                // Remove non-digit characters
                const parsedValue = value?.replace(/[^\d]/g, "") || "0";
                return parseInt(parsedValue, 10);
              }}
              onChange={(value: any) => {
                if (
                  value &&
                  value +
                    softSkillsPercentageValue +
                    hardSkillsPercentageValue +
                    otherSkillsPercentageValue >
                    100
                ) {
                  return;
                  form.setFieldsValue({
                    toolsAndTechnologiesSkillsPercentage:
                      toolsAndTechSkillsPercentageValue,
                  });
                  message.error("Percentages exceeds 100%");
                  return;
                } else if (
                  (value &&
                    toolsAndTechSkillsPercentageValue &&
                    value < toolsAndTechSkillsPercentageValue) ||
                  value == 0
                ) {
                  setToolsAndTechSkillsPercentageValue(value);
                } else if (percentegeExceeded) {
                  form.setFieldsValue({
                    toolsAndTechnologiesSkillsPercentage:
                      toolsAndTechSkillsPercentageValue,
                  });
                  return;
                } else {
                  setToolsAndTechSkillsPercentageValue(value);
                }
              }}
              onKeyPress={(event) => {
                // Prevent input of non-numeric characters
                const charCode = event.which ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>

        <Form.List name="toolsTechnologiesList">
          {(toolsTechnologiesList, { add, remove }) => (
            <div>
              {toolsTechnologiesList.map((field, index) => (
                <div key={field.key}>
                  <div className="flex flex-row items-center gap-4">
                    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                      <Form.Item
                        label="Technology"
                        name={[index, "tools", "toolsTechnologiesId"]}
                        rules={[
                          {
                            required: true,
                            message: "Technology is required",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const allIds = getFieldValue(
                                "toolsTechnologiesList"
                              ).map(
                                (item: {
                                  tools: { toolsTechnologiesId: any };
                                }) => item?.tools?.toolsTechnologiesId
                              );
                              if (
                                allIds.filter((id: any) => id === value)
                                  .length > 1
                              ) {
                                return Promise.reject(
                                  "Duplicate field selected"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Select
                          showSearch
                          size="large"
                          className="w-full"
                          placeholder="Select"
                          optionFilterProp="children"
                        >
                          {toolsTechnologies.map((tech) => (
                            <Option
                              key={tech.toolsTechnologiesId}
                              value={tech.toolsTechnologiesId}
                            >
                              {tech.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Experience"
                        name={[index, "experience"]}
                        rules={[
                          {
                            required: true,
                            message: "Experience is required",
                          },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message:
                              "Experience must be greater than or equal to zero and less than 100",
                          },
                        ]}
                      >
                        <InputNumber
                          size="large"
                          className="w-full"
                          placeholder="Experience"
                          formatter={(value) => {
                            const formattedValue = `${value} ${
                              value == 1 ? "Year" : "Years"
                            }`;
                            return formattedValue;
                          }}
                          parser={(value: string | undefined) => {
                            const numericValue = parseFloat(
                              value?.replace(" years", "") || "0"
                            );
                            return isNaN(numericValue) ? 0 : numericValue;
                          }}
                        />
                      </Form.Item>
                    </div>
                    {toolsTechnologiesList.length > 1 ? (
                      <FormItem>
                        <Button
                          onClick={() => remove(field.name)}
                          icon={<CloseOutlined />}
                        />
                      </FormItem>
                    ) : null}
                  </div>

                  <div>
                    <p className="text-base">Priority</p>

                    <Form.Item label="Priority" name={[index, "priority"]}>
                      <Slider
                        min={0}
                        max={100}
                        marks={marks}
                        step={10}
                        style={sliderStyle}
                      />
                    </Form.Item>
                  </div>
                </div>
              ))}
              <div>
                <Form.Item>
                  <Button
                    className="text-[#595D62]"
                    icon={<PlusOutlined />}
                    type="link"
                    size="large"
                    onClick={() => add()}
                  >
                    Add another
                  </Button>
                </Form.Item>
              </div>
            </div>
          )}
        </Form.List>

        <div>
          <div className="flex justify-between mb-4">
            <p id="other" className="text-lg font-bold">
              Other
            </p>

            <Form.Item
              name="OtherSkillsPercentage"
              // rules={[
              //   {
              //     required: true,
              //     message: "Percentage is required",
              //   },
              // ]}
            >
              <InputNumber<number>
                className="w-[150px]"
                size="large"
                placeholder="Percentage"
                defaultValue={0}
                value={otherSkillsPercentageValue}
                min={0}
                max={100}
                formatter={(value: any) => {
                  if (!value) {
                    return "";
                  } else {
                    if (
                      value &&
                      Number(value) +
                        Number(softSkillsPercentageValue) +
                        Number(hardSkillsPercentageValue) +
                        Number(toolsAndTechSkillsPercentageValue) >
                        100
                    ) {
                      form.setFieldsValue({
                        OtherSkillsPercentage: otherSkillsPercentageValue,
                      });

                      message.error("Percentages exceeds 100%");
                      return `${form.getFieldValue("OtherSkillsPercentage")}%`;
                    }
                    return `${Math.floor(value)}%`;
                  }
                }}
                parser={(value) => {
                  // Remove non-digit characters
                  const parsedValue = value?.replace(/[^\d]/g, "") || "0";
                  return parseInt(parsedValue, 10);
                }}
                onChange={(value: any) => {
                  if (
                    value &&
                    value +
                      softSkillsPercentageValue +
                      hardSkillsPercentageValue +
                      toolsAndTechSkillsPercentageValue >
                      100
                  ) {
                    return;
                    form.setFieldsValue({
                      OtherSkillsPercentage: otherSkillsPercentageValue,
                    });
                    message.error("Percentages exceeds 100%");
                    return;
                  } else if (
                    (value &&
                      otherSkillsPercentageValue &&
                      value < otherSkillsPercentageValue) ||
                    value == 0
                  ) {
                    setOtherSkillsPercentageValue(value);
                  } else if (percentegeExceeded) {
                    form.setFieldsValue({
                      OtherSkillsPercentage: otherSkillsPercentageValue,
                    });
                    return;
                  } else {
                    setOtherSkillsPercentageValue(value);
                  }
                }}
                onKeyPress={(event) => {
                  // Prevent input of non-numeric characters
                  const charCode = event.which ? event.which : event.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>

          <Form.List name="otherList">
            {(otherList, { add, remove }) => (
              <div>
                {otherList.map((field, index) => (
                  <div
                    className="flex flex-row items-center gap-4"
                    key={field.key}
                  >
                    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
                      <Form.Item
                        label="Other"
                        name={[index, "others", "otherId"]}
                        rules={[
                          // { required: true, message: "Other is required" },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const allIds = getFieldValue("otherList").map(
                                (item: { others: { otherId: any } }) =>
                                  item?.others?.otherId
                              );
                              if (
                                allIds.filter((id: any) => id === value)
                                  .length > 1
                              ) {
                                return Promise.reject(
                                  "Duplicate field selected"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Select
                          showSearch
                          className="w-full"
                          size="large"
                          placeholder="Select"
                          optionFilterProp="children"
                        >
                          {other.map((otheritem) => (
                            <Option
                              key={otheritem.otherId}
                              value={otheritem.otherId}
                            >
                              {otheritem.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Experience"
                        name={[index, "experience"]}
                        rules={[
                          {
                            required: true,
                            message: "Experience is required",
                          },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message:
                              "Experience must be greater than or equal to zero and less than 100",
                          },
                        ]}
                      >
                        <InputNumber
                          className="w-full"
                          size="large"
                          placeholder="Experience"
                          formatter={(value) => {                            
                            const formattedValue = `${Number(value)} ${
                              value == 1 ? "Year" : "Years"
                            }`;
                            return formattedValue;
                          }}
                          parser={(value: string | undefined) => {
                            const numericValue = parseFloat(
                              value?.replace(" years", "") || "0"
                            );
                            return isNaN(numericValue) ? 0 : numericValue;
                          }}
                        />
                      </Form.Item>
                    </div>
                    {/* {otherList.length > 1 ? (
                      <FormItem>
                        <Button
                          onClick={() => remove(field.name)}
                          icon={<CloseOutlined />}
                        />
                      </FormItem>
                    ) : null} */}
                    <FormItem>
                        <Button
                          onClick={() => remove(field.name)}
                          icon={<CloseOutlined />}
                        />
                      </FormItem>
                  </div>
                ))}
                <Row style={{ marginTop: 16 }}>
                  <Form.Item>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      className="text-[#595D62]"
                      type="link"
                      size="large"
                    >
                      Add another
                    </Button>
                  </Form.Item>
                </Row>
              </div>
            )}
          </Form.List>
        </div>
        <div className="flex justify-between w-full">
          <Form.Item colon={false}>
            <Button className="mt-5 " onClick={onPreviousClick} size="large">
              Previous
            </Button>
          </Form.Item>

          <Form.Item colon={false}>
            <Button
              type="primary"
              className="mt-5 "
              htmlType="submit"
              size="large"
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </StyledForm>
    </>
  );
};

export default Skills;
