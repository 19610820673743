import axios from "../../API";
import CreateExperienceProps from "../../DTOs/CandidateExperienceDTOs";
import CandidateGetInfoDTO from "../../DTOs/CandidateGetAboutYouDTO";
import Constants from "../../constants/Constants";

const CandidateExperienceService = {
  createExperience: async (data: CreateExperienceProps) => {
    try {
      const params = {
        section: Constants.EXPERIENCE,
        action: Constants.ACTIONS[0],
      };
      const url = "/candidate/create-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getAllExperiences: async (data: CandidateGetInfoDTO) => {
    try {
      const params = {
        section: Constants.EXPERIENCE,
      };
      const url = "/candidate/view-candidate";
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  getExperienceBYId: async (data: CandidateGetInfoDTO, id: number) => {
    try {
      const url = "/candidate/view-candidate";

      const params = {
        section: Constants.EXPERIENCE,
        id: id,
      };

      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  updateExperience: async (data: any) => {
    try {
      const url = "/candidate/create-candidate";

      const params = {
        section: Constants.EXPERIENCE,
        action: Constants.ACTIONS[1],
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  deleteExperience: async (data: any) => {
    try {
      const url = "/candidate/create-candidate";

      const params = {
        section: Constants.EXPERIENCE,
        action: Constants.ACTIONS[2],
      };
      const response = await axios.post(url, data, {
        params: params,
      });
      return response;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default CandidateExperienceService;
