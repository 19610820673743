import { Button, Divider, Input, Form } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { auth, provider } from "../../../config/FirebaseConfig";
import { signInWithPopup } from "firebase/auth";
import useNavigation from "../../../helper/UseNavigation";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { Link } from "react-router-dom";
import usePasswordValidator from "../../../helper/PasswordValidator";
import { useAuth } from "../../../helper/AuthContext";
import Constants from "../../../constants/Constants";
import Google from "../../../assets/icons/googleIcon.png";
// import Google from "../../../assets/icons/googleIcon.png";

function Register() {
  // navigation helper function
  const handleNavigation = useNavigation();
  // password validation helper function
  const validatePassword = usePasswordValidator();

  const authHandler = useAuth();

  // handles normal signup function
  const onFinish = async (values: any) => {
    if (
      values.email != null &&
      values.fullname != null &&
      values.password != null &&
      values.email !== undefined &&
      values.fullname !== undefined &&
      values.password !== undefined
    ) {
      var requestData = {
        email: values.email,
        fullname: values.fullname,
        password: values.password,
        roles: Constants.ROLES[0].value,
      };
      const url = "/auth/signup";
      await API.post(url, requestData)
        .then((res) => {
          // toaster
          displayToaster("success", res.data.message);
          localStorage.setItem("email", values.email);
          // Redirect to dashboard upon successful sign-in
          // handleNavigation("/signin", null);
          handleNavigation("/verify-password", {
            email: values.email,
            password: values.password,
            type: "signin",
          });
        })
        .catch((error) => {
          // toaster
          displayToaster("error", error.response.data.message);
        });
    }
  };
  // handles google signup function
  const handleClick = () => {
    signInWithPopup(auth, provider).then(async (data: any) => {
      if (data.user.email != null && data.user.email !== undefined) {
        // call signup
        const user = data.user;
        const idToken = await user.getIdToken();
        const url = "/auth/google-signup";
        if (user.emailVerified) {
          var requestData = {
            email: user.email,
            fullname: user.displayName,
            token: idToken,
            roles: Constants.ROLES[0].value,
          };

          await API.post(url, requestData)
            .then(async (res) => {
              // toaster
              //  console.log("sucess",res.data.accessToken)
              displayToaster("success", res.data.message);

              let location = "/dashboard";
              authHandler.login(res.data.accessToken);

              if (user.email !== null) {
                localStorage.setItem("email", user.email);
                var data = {
                  email: user.email,
                };
                const response = await API.post("user/view-profile", data);

                if (
                  response != null &&
                  response !== undefined &&
                  response.data.data.isWorkSpaceCreated === false &&
                  response.data.data.isCompanyProfileCreated === false
                ) {
                  location = "/empty-dashboard";
                }
              }

              handleNavigation(location, null);
            })
            .catch((error) => {
              // toaster
              displayToaster("error", error.response.data.message);
            });
        } else {
        }
      }
    });
  };

  return (
    <div className="flex justify-center w-full h-full align-center px-[30px]">
      <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
        <div className="flex flex-col gap-[14px]">
          <p className="m-0 text-[32px] font-bold leading-none">Get Started</p>
          <p className="m-0 text-base text-[#595D62] leading-none">
            Ease up your recruitment process by using our AI powered tools.
          </p>
        </div>
        <div className="flex flex-col gap-6">
          <Button size="large" onClick={handleClick}>
            <div className="flex items-center justify-center w-full gap-3">
              <img width={18} src={Google} alt="Google Icon"></img>
              Continue with Google
            </div>
          </Button>
          <Divider style={{ margin: "0px" }}>Or</Divider>
          <Form
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            className="flex flex-col "
          >
            <Form.Item
              label="Full Name"
              name="fullname" // Add a unique name
              rules={[
                {
                  required: true,
                  message: "Full Name is required",
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Please enter only alphabetic characters",
                },
              ]}
            >
              <Input size="large" placeholder="Enter your full name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input size="large" placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                name="password"
                placeholder="input password"
                size="large"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item colon={false}>
              <Button
                type="primary"
                className="w-full"
                htmlType="submit"
                size="large"
              >
                Sign Up
              </Button>
            </Form.Item>
            <p className="m-0 text-base">
              Already have an account? <Link to="/signin">Sign in</Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default Register;
