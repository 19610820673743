import axios from "../API";

export async function createDraftCampaign(data: any, params: any) {
  try {
   
    const response = await axios.post('/campaign/create-draft-campaign', data, {
      params: params
    });
    
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
