import { Steps } from "antd";
import { SetStateAction } from "react";

export function StepComponent(
  currentStep: number,
  mainSteps: any[],
  handleStepChange: (step: SetStateAction<number>) => void,
  isMobile: boolean
) {
  // Calculate percentage for the progress
  const progressPercentage = ((currentStep + 1) / mainSteps.length) * 100;

  return (
    <div >
      {isMobile ? null : (
        <Steps
          size="small"
          progressDot
          current={currentStep}
          items={mainSteps.map((step, index) => ({
            title: step.title,
            onClick: () => handleStepChange(index),
          }))}
        />
      )}
      <Steps
        current={currentStep}
        labelPlacement={isMobile ? "vertical" : undefined}
        percent={progressPercentage}
        className={isMobile ? undefined : "md:hidden"}
        items={mainSteps.map((step, index) => ({
          title: step.title,
          onClick: () => handleStepChange(index),
        }))}
      />
    </div>
  );
}
