import { Menu } from 'antd';
import { useState } from 'react';

interface SettingsLeftSiderProps {
    onTabClick: (tab: string) => void; // Define the type of onTabClick prop
}

const SettingsLeftSider: React.FC<SettingsLeftSiderProps> = ({ onTabClick }) => {
    const [activeTab, setActiveTab] = useState('account');

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        onTabClick(tab); // Notify parent component about the selected tab
    };

    return (
        <Menu
        className='md:max-w-[300px] md:py-5 md:pr-5'
        
            mode="inline"
            selectedKeys={[activeTab]}
            onClick={({ key }) => handleTabClick(key.toString())}
        >
            <Menu.Item key="account">Account</Menu.Item>
            <Menu.Item key="security">Security</Menu.Item>
        </Menu>
    );
};

export default SettingsLeftSider;
