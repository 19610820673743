import { Button } from "antd";
import GroupIcon from "../../../src/assets/images/campaignCards.png";
import { Link } from "react-router-dom";

function DashBoardNoCampaign() {
  return (
    <div className="flex flex-col items-center justify-center min-h-[70dvh]">
      <div className="max-w-[342px] flex flex-col items-center justify-center gap-[32px] ">
        <img width={200} height={200} src={GroupIcon} alt="group-icon" />

        <p className="m-0 text-base text-center">
          Create a campaign that unleashes the potential of exceptional talent!
        </p>

        <Link to="/campaign">
          <Button className="primary-button" htmlType="submit" size="large">
            Create Campaign
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default DashBoardNoCampaign;
