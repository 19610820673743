import Swal, { SweetAlertIcon } from 'sweetalert2';

export function displayToaster(type: SweetAlertIcon, title: string) {
  if (type != null && title != null) {
    Swal.fire({
      icon: type,
      title: title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}
