import axios from "../API";

export async function uploadResume(data: any) {
  try {
    const response = await axios.post("/candidate/upload-resume", data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
