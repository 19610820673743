import { Editor } from '@tinymce/tinymce-react';

interface TextEditorProps {
  onContentChange: (content: string) => void;
  initialContent: string // Define the type for the onContentChange prop
}

const TextEditor: React.FC<TextEditorProps> = ({ onContentChange, initialContent }) => {
  // Define types for the parameters
  const handleEditorChange = (content: string, editor: any) => {
    // Call the callback function to update the parent component state with the edited content
    onContentChange(content);
  };

  return (
    <Editor
    // prod
      apiKey='1x0mp43b1p8qlkify1kfqck83x5elai0b0a541e18lsjk8c5'
      // dev
      // apiKey='8l5ff9182drixs2pejnamlq05zjo6t1oceza3yb57k0olho6'
      value={initialContent}
      init={{
        plugins: 'link lists',
        toolbar: 'undo redo | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | link',
        menubar: false,
        link_target_list: [
          { title: 'Open in current window', value: '_self' },
          // { title: 'Open in new window', value: '_blank' }
        ],
        default_link_target:"_blank",
        ai_request: (request: any, respondWith: { string: (arg0: () => Promise<never>) => any; }) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default TextEditor;
