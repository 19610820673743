import Swal from 'sweetalert2'
export function copytoClipBoard(url: string){
 

  var textField = url

  try {

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(textField)

      Swal.fire({
        icon: 'success',
        title: 'Copied to clipboard!',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textField;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        Swal.fire({
          icon: 'success',
          title: 'Copied to clipboard!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
        Swal.fire({
          icon: 'error',
          title: 'Copied to clipboard failed!',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      }
      document.body.removeChild(textArea);
    }

  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Copied to clipboard failed!',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })
  }


}
