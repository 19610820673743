import {
  Button,
  Form,
  FormInstance,
  Input,
  Popover,
  Select,
  Upload,
} from "antd";
import { SetStateAction, useEffect, useState } from "react";

import Constants from "../../../constants/Constants";
import API from "../../../API";
import { displayToaster } from "../../../helper/Toaster";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import TextArea from "antd/es/input/TextArea";
import { ExtractInitials } from "../../../helper/ExtractInitials";
import { UploadCompanyLogo } from "../../../helper/UploadCompanyLogo";
const { Option } = Select;
function Profile() {
  const content = (
    <ul>
      <li>Aspect Ratio: 1:1 (Square)</li>
      <li>Formats: JPG, PNG</li>
      <li>Max File Size: 5MB</li>
      <li>Min Dimensions: 200x200px</li>
    </ul>
  );

  const [profileForm] = Form.useForm();

  const [companyForm] = Form.useForm();

  const industries = Constants.INDUSTRIES;

  const numberOfEmployees = Constants.NUMBER_OF_EMPLOYEES;

  const [userData, setUserData] = useState<{
    email: string;
    fullName: string;
    isWorkSpaceCreated: boolean;
    isCompanyProfileCreated: boolean;
    isCampaignCreated: boolean;
  } | null>(null);

  const [companyData, setCompanyData] = useState<{
    name: string;
    about: string;
    industry: string;
    logo: string;
    numberOfEmployees: string;
    website: string;
  } | null>(null);

  const [showProfileSubmit, setShowProfileSubmit] = useState(false);

  const [showCompanySubmit, setShowCompanySubmit] = useState(false);

  const setLogoData = (
    setCompanyData: {
      (
        value: SetStateAction<{
          name: string;
          about: string;
          industry: string;
          logo: string;
          numberOfEmployees: string;
          website: string;
        } | null>
      ): void;
      (arg0: (prevCompanyData: any) => any): void;
    },
    logoUrl: any,
    companyForm: FormInstance<any>
  ) => {
    setCompanyData((prevCompanyData: any) => {
      if (prevCompanyData) {
        return {
          ...prevCompanyData,
          logo: logoUrl,
        };
      }
      return null;
    });
    companyForm.setFieldValue("logo", logoUrl);
  };

  const handleOk = async (blob: any) => {
    try {
      if (blob != null && blob !== undefined) {
        const reader = new FileReader();
        // Pass the imageFile to the FileReader instance
        reader.readAsDataURL(blob);
        reader.onload = async () => {
          const base64Data = reader.result as string;
          const requestData = {
            image: base64Data,
            contentType: blob.type,
          };

          const res = await UploadCompanyLogo(requestData);
          const logoUrl = res.data.url;
          setLogoData(setCompanyData, logoUrl, companyForm);
        };
      } else {
        // console.log("imageFile:", blob);
        displayToaster("error", "Image not selected!");
      }
    } catch (error: any) {
      displayToaster(
        "error",
        error.response?.data?.message || "An error occurred"
      );
    }
  };

  const validateURL = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error("Please input a URL!"));
    }
    // Regular expression for URL validation
    const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;

    if (!urlRegex.test(value)) {
      return Promise.reject(new Error("Please enter a valid URL!"));
    }
    return Promise.resolve();
  };

  const getProfileData = async () => {
    // console.log("profile:view profile");
    const url = "/user/view-profile";
    var requestData = {
      email: localStorage.getItem("email"),
    };
    await API.post(url, requestData)
      .then(async (res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  const getCompanyData = async () => {
    const url = "/company/view-company-profile";
    var requestData = {
      email: localStorage.getItem("email"),
    };
    await API.post(url, requestData)
      .then(async (res) => {
        setCompanyData(res.data.data);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  useEffect(() => {
    if (userData == null || userData === undefined) {
      getProfileData();
    }

    if (userData) {
      profileForm.setFieldsValue({
        fullname: userData.fullName,
        email: userData.email,
      });
    }
  }, [profileForm, userData]);

  useEffect(() => {
    if (companyData == null || companyData === undefined) {
      getCompanyData();
    }

    if (companyData) {
      companyForm.setFieldsValue({
        companyname: companyData.name,
        aboutcompany: companyData.about,
        industry: companyData.industry,
        logo: companyData.logo,
        employeeNumber: companyData.numberOfEmployees,
        website: companyData.website,
      });
    }
  }, [companyData, companyForm]);

  const handleProfileFormChange = () => {
    setShowProfileSubmit(true);
  };

  const handleCompanyFormChange = () => {
    setShowCompanySubmit(true);
  };
  const handleCompanyFormSave = async (value: any) => {
    var requestData = {
      email: localStorage.getItem("email"),
      name: value.companyname,
      logo: value.logo,
      industry: value.industry,
      numberOfEmployees: value.employeeNumber,
      about: value.aboutcompany,
      website: value.website,
    };
    const url = "/company/update-company-profile";
    await API.post(url, requestData)
      .then((res) => {
        displayToaster("success", res.data.message);
        setShowCompanySubmit(false);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  const handleProfileFormSave = async (value: any) => {
    var requestData = {
      email: localStorage.getItem("email"),
      fullName: value.fullname,
      newEmail: value.email,
    };
    const url = "/user/update-profile";
    await API.post(url, requestData)
      .then(async (res) => {
        localStorage.setItem("email", value.email);
        const initials = await ExtractInitials(value.fullname);
        localStorage.setItem("initials", initials);
        displayToaster("success", res.data.message);
      })
      .catch((error) => {
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <p className="text-2xl font-bold">Profile</p>

        <Form
          form={profileForm}
          name="profile-form"
          onChange={handleProfileFormChange}
          onFinish={handleProfileFormSave}
          layout="vertical"
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item
            label="Full Name"
            name="fullname" // Add a unique name
            rules={[
              {
                pattern: /^[A-Za-z\s]+$/,
                message: "Please enter only alphabetic characters",
              },
              {
                required: true,
                message: "Full Name is required",
              },
            ]}
          >
            <Input size="large" placeholder="Enter your full name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input size="large" placeholder="Enter your email" />
          </Form.Item>
          {showProfileSubmit && (
            <Form.Item colon={false}>
              <Button
                type="primary"
                className="w-full"
                htmlType="submit"
                size="large"
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>

      <div className="flex flex-col w-full gap-6">
        <p className="text-2xl font-bold">Company</p>

        <Form
          form={companyForm}
          name="company-form"
          onChange={handleCompanyFormChange}
          onFinish={handleCompanyFormSave}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="logo"
            rules={[
              {
                required: true,
                message: "Logo is required",
              },
            ]}
          >
            <div className="flex flex-col gap-[14px]">
              <div className="flex gap-1">
                <p className="text-base "> *Company Logo</p>
                <Popover placement="rightTop" content={content}>
                  <InfoCircleOutlined className="tooltip-info-icon" />
                </Popover>
              </div>
              <div className="flex flex col gap-2">
                {companyData && companyData.logo && (
                  <img
                    src={companyData.logo}
                    height={40}
                    width={40}
                    alt="Company Logo"
                    style={{ maxWidth: "100px" }}
                  />
                )}

                <ImgCrop
                  rotationSlider
                  modalTitle="Crop Image"
                  onModalOk={handleOk}
                >
                  <Upload showUploadList={false} maxCount={1}>
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload Logo
                    </Button>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
          </Form.Item>

          <Form.Item
            label="Company Name"
            name="companyname" // Add a unique name
            rules={[
              {
                required: true,
                message: "Company Name is required",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Industry"
            name="industry"
            rules={[
              {
                required: true,
                message: "Please select the industry!",
                type: "string",
              },
            ]}
          >
            <Select size="large" showSearch placeholder="Select Industry">
              {industries.map((industry) => (
                <Option key={industry} value={industry}>
                  {industry}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Number of Employees"
            name="employeeNumber"
            rules={[
              {
                required: true,
                message: "Please select the number of employees!",
                type: "string",
              },
            ]}
          >
            <Select size="large" showSearch placeholder="Select">
              {numberOfEmployees.map((empNumber) => (
                <Option key={empNumber} value={empNumber}>
                  {empNumber}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="About Company"
            name="aboutcompany" // Add a unique name
            rules={[
              {
                required: true,
                message: "Company description is required",
              },
            ]}
          >
            <TextArea
              placeholder="Description"
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>

          <Form.Item
            label="Website"
            name="website" // Add a unique name
            rules={[
              {
                required: true,
                message: "Website is required",
              },
              {
                validator: validateURL,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          {showCompanySubmit && (
            <Form.Item colon={false}>
              <Button
                className="signup-button lengthy-button"
                htmlType="submit"
                size="large"
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
}

export default Profile;
