interface ConstantsType {
  JWT_TOKEN: string;
  DATE_FORMAT: string;
  DISPLAY_DATE_FORMAT: string;
  TIME_FORMAT: string;
  GENERAL_DETAILS: string;
  SKILLS: string;
  CAMPAIGN_STATUS: {
    ACTIVE: string;
    CLOSED: string;
    DRAFT: string;
    PUBLISHED: string;
  };
  MAIN_CAMPAIGN_STEPS: { title: string; value: number }[];
  MAIN_CANDIDATE_STEPS: {
    title: string;
    value: number;
    subTitle: string;
    stepName: string;
    description: string;
  }[];
  CAMPAIGN_SUB_HEADINGS_STEPS: {
    step: string;
    title: string;
    heading: { key: string; href: string; title: string }[];
  }[];
  SKILLS_SUB_HEADINGS_STEPS: {
    step: string;
    title: string;
    heading: { key: string; href: string; title: string }[];
  }[];
  ROLES: { title: string; value: string }[];
  INDUSTRIES: string[];
  NUMBER_OF_EMPLOYEES: string[];
  WORKING_TYPE: string[];
  EMPLOYMENT_TYPE: string[];
  CURRENCY_TYPE: string[];
  PAID_RATE: string[];
  ABOUT_YOU: string;
  ACTIONS: string[];
  EXPERIENCE: string;
  EDUCATION: string;
  Skill: string;
  PREVIEW: string;
  SKILL_TYPES: string[];
}

const Constants: ConstantsType = {
  SKILL_TYPES: [
    "soft-skills",
    "hard-skills",
    "tools-tech-skills",
    "other-skills",
  ],
  PREVIEW: "preview",
  Skill: "skills",
  EDUCATION: "education",
  EXPERIENCE: "experience",
  ACTIONS: ["create", "update", "delete"],
  JWT_TOKEN: "jwtToken",
  ABOUT_YOU: "about-you",
  // DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT: "YYYY-MM-DD",
  DISPLAY_DATE_FORMAT: "DD/MM/YYYY",
  TIME_FORMAT: "HH:mm:ss",
  GENERAL_DETAILS: "general-details",
  SKILLS: "skills",
  CAMPAIGN_STATUS: {
    ACTIVE: "Active",
    CLOSED: "Closed",
    DRAFT: "Draft",
    PUBLISHED: "Published",
  },
  MAIN_CAMPAIGN_STEPS: [
    {
      title: "Campaign",
      value: 0,
    },
    {
      title: "Skills",
      value: 1,
    },
    {
      title: "Preview",
      value: 2,
    },
  ],
  MAIN_CANDIDATE_STEPS: [
    {
      title: "About",
      value: 0,
      subTitle: "About you",
      stepName: "Step 01",
      description:
        "Fill in the following information so companies know who you are and what you are looking for.",
    },
    {
      title: "Experience",
      value: 1,
      subTitle: "Your Experience",
      stepName: "Step 02",
      description:
        "Fill in the following information so companies know who you are and what you are looking for.",
    },
    {
      title: "Education",
      value: 2,
      subTitle: "Your Education",
      stepName: "Step 03",
      description:
        "Fill in the following information so companies know who you are and what you are looking for.",
    },
    {
      title: "Skills",
      value: 3,
      subTitle: "Your Skills",
      stepName: "Step 04",
      description:
        "Fill in the following information so companies know who you are and what you are looking for.",
    },
    {
      title: "Preview",
      value: 4,
      subTitle: "Preview Application",
      stepName: "Step 05",
      description:
        "Fill in the following information so companies know who you are and what you are looking for.",
    },
  ],
  ROLES: [
    {
      title: "Admin",
      value: "1",
    },
    {
      title: "Recruiter",
      value: "2",
    },
    {
      title: "Candidate",
      value: "3",
    },
  ],
  INDUSTRIES: [
    "Banking & Financial Services",
    "Information Technology",
    "Communication & Marketing Services",
    "Utilities",
  ],

  NUMBER_OF_EMPLOYEES: [
    "1-5 Members",
    "5-25 Members",
    "25-50 Members",
    "50-100 Members",
    "100+ Members",
  ],

  WORKING_TYPE: ["Remote", "On-site", "Hybrid"],

  EMPLOYMENT_TYPE: [
    "Full-time",
    "Internship",
    "Part-time",
    "Casual",
    "Contract",
  ],

  CURRENCY_TYPE: ["USD", "LKR"],

  PAID_RATE: ["Hour", "Day", "Week", "Month", "Year"],

  CAMPAIGN_SUB_HEADINGS_STEPS: [
    {
      step: "Step 01",
      title: "Campaign",
      heading: [
        {
          key: "general-details",
          href: "#general-details",
          title: "General Details",
        },
        {
          key: "salary-details",
          href: "#salary-details",
          title: "Salary Details",
        },
        {
          key: "work-experience",
          href: "#work-experience",
          title: "Work Experience",
        },
        {
          key: "educational-qualifications",
          href: "#educational-qualifications",
          title: "Education Qualifications",
        },
      ],
    },
  ],
  SKILLS_SUB_HEADINGS_STEPS: [
    {
      step: "Step 02",
      title: "Skills",
      heading: [
        {
          key: "soft-skills",
          href: "#soft-skills",
          title: "Soft Skills",
        },
        {
          key: "hard-skills",
          href: "#hard-skills",
          title: "Hard Skills",
        },
        {
          key: "tools",
          href: "#tools",
          title: "Tools & Technologies",
        },
        {
          key: "other",
          href: "#other",
          title: "Other",
        },
      ],
    },
  ],
};

export default Constants;
