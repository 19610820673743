import "./App.css";
import { Layout } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import About from "./layout/About";
import Register from "./views/pages/register/Register";
import SignIn from "./views/pages/signin/Signin";
import Dashboard from "./views/pages/dashboard/Dashboard";
import VerifyPassword from "./views/pages/verify/VerifyPassword";
import ForgotPassword from "./views/pages/verify/ForgotPassword";
import SetNewPassword from "./views/pages/verify/SetNewPassword";
import PasswordUpdateSuccess from "./views/pages/verify/PasswordUpdateSuccess";
import { AuthProvider } from "./helper/AuthContext";
import EmptyDashboard from "./views/pages/dashboard/EmptyDashboard";
import Settings from "./views/pages/settings/Settings";
import CompanyProfile from "./views/pages/companyprofile/CompanyProfile";
import Campaign from "./views/pages/campaign/Campaign";
import CampaignPublisSuccess from "./views/pages/campaign/CampaignPublisSuccess";
import CampaignPostView from "./views/pages/campaign/CampaignPostView";
import DashBoardNoCompanyProfile from "./layout/dashboard/DashBoardNoCompanyProfile";
import InternalCampaignPostView from "./views/pages/campaign/InternalCampaignPostView";
import { Header } from "antd/es/layout/layout";
import HeaderLayout from "./layout/HeaderLayout";
import Candidate from "./views/pages/candidate/Candidate";
import NotFound from "./../src/layout/NotFound";
import HeaderLayoutWithoutAvatar from "./layout/HeaderLayoutWithoutAvatar";


const { Content } = Layout;

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();

  // Function to check if the current path requires the header
  const shouldRenderHeader = () => {
    const pathsWithHeader = ["/dashboard", "/campaign-post-view"];
    // return pathsWithHeader.includes(location.pathname);
    return pathsWithHeader.some((path) => location.pathname.startsWith(path));
  };

  const shouldRenderHeaderOnly = () => {
    const pathsWithHeader = ["/not-found"];
    return pathsWithHeader.some((path) => location.pathname.startsWith(path));
  };

  return (
    <Layout style={{ height: "100vh" }}>
      {shouldRenderHeader() && <Header className="sticky top-0 z-30 p-0"><HeaderLayout></HeaderLayout></Header>}
      {shouldRenderHeaderOnly() && <Header className="sticky top-0 z-30 p-0"><HeaderLayoutWithoutAvatar></HeaderLayoutWithoutAvatar></Header>}
      
      <Content className="">
        <Routes>
          <Route path="/" element={<Navigate to="/signin" />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/empty-dashboard" element={<EmptyDashboard />} />
          <Route
            path="/empty-company-dashboard"
            element={<DashBoardNoCompanyProfile />}
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify-password" element={<VerifyPassword />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route
            path="/pw-update-success"
            element={<PasswordUpdateSuccess />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/company-profile" element={<CompanyProfile />} />
          <Route path="/campaign" element={<Campaign />} />
          {/* public campaign post view */}
          <Route
            path="/campaign-post/:id"
            element={<CampaignPostView campaignId={undefined} />}
          />
          {/* internal campaign view */}
          <Route
            path="/campaign-post-view/:id"
            element={<InternalCampaignPostView campaignId={undefined} />}
          />
          <Route path="/campaign#general-details" element={<Campaign />} />
          <Route
            path="/campaign/publish-success"
            element={<CampaignPublisSuccess />}
          />
          {/* <Route path="/about-you" element={<AboutYou />} /> */}
          <Route path="/candidate" element={<Candidate />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default App;
