import React from "react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "DM Sans",
          colorPrimary: "#13265C",
        },
        components: {
          Button: {
            borderRadius: 8,
            fontWeight: 500,
          },
          Pagination: {
            /* here is your component tokens */
            itemActiveBg: "#13265C",
          },
          Steps: {},
          Form: {
            labelFontSize: 16,
          },
          Menu: {
            itemBorderRadius: 8,
            itemHeight: 56,
            itemSelectedBg: "#E8EAEF",

            /* here is your component tokens */
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
