import { Checkbox, Modal, Select, message } from "antd";
import { CheckboxProps } from "antd/lib";
import React, { useState } from "react";
import Constants from "../../../constants/Constants";
import CandidateGeneralService from "../../../Services/Candidate/CandidateGeneralService";

interface ExportModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  exportProfiles: any[];

  onFinishExport: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  exportProfiles,
  onFinishExport,
}) => {
  const [resumeIncluded, setResumeIncluded] = useState<boolean>(false);

  const [exportType, setExportType] = useState<string | null>(null);

  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    setResumeIncluded(e.target.checked);
  };

  const handleExport = () => {
    if (exportType == null) {
      message.warning("Select a Export Type");
      return;
    }

    setDisableBtn(true);
    const data = {
      email: localStorage.getItem("email"),
      roles: Constants.ROLES[2].value,
      type: exportType,
      isResumeIncluded: resumeIncluded,
      candidateArray: exportProfiles,
      fileHeaderObject: [
        { id: "fullname", title: "Name" },
        { id: "email", title: "Email" },
        { id: "contactNumber", title: "Contact" },
        { id: "resumeLink", title: "Resume" },
        { id: "percentage", title: "Percentage" },
      ],
    };

    message.loading("Exporting please wait!", 0);

    CandidateGeneralService.exportCandidates(data)
      .then((resp) => {
        const base64ZipString = resp.data.data;
        const binaryString = atob(base64ZipString);

        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: "application/zip" });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "candidates.zip");

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(url);
        message.destroy();
        message.success(
          `Successfully exported ${data.candidateArray.length} ${
            data.candidateArray.length > 1 ? "Applicants" : "Applicant"
          } `
        );
        setExportType(null);
        setResumeIncluded(false);
        setDisableBtn(false);

        onFinishExport();
        handleCancel();
      })
      .catch((error) => {
        message.destroy();
        setDisableBtn(false);
        console.log(error);
      });
  };

  return (
    <Modal
      title="Export"
      open={isModalOpen}
      okText="Export"
      onCancel={handleCancel}
      width={365}
      onOk={handleExport}
      okButtonProps={{ disabled: disableBtn }}
    >
      <span className="font-normal text-base mb-2 block">Export Type</span>

      <Select
        placeholder="Select Export Type"
        size="large"
        className="w-full mb-4"
        value={exportType}
        options={[
          {
            value: "csv",
            label: "Csv",
          },
          {
            value: "excel",
            label: "Excel",
          },
        ]}
        onChange={(value) => {
          setExportType(value);
        }}
      ></Select>

      <Checkbox checked={resumeIncluded} onChange={onChange}>
        Include Resumes
      </Checkbox>
    </Modal>
  );
};

export default ExportModal;
