import { Button, Divider, Skeleton, Tag } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import API from "../../../API";
import { useState, useEffect, useCallback } from "react";
import { displayToaster } from "../../../helper/Toaster";
import { Link } from "react-router-dom";
import Constants from "../../../constants/Constants";
import useNavigation from "../../../helper/UseNavigation";
import { sanitizeHTMLString } from "../../../helper/SanitizeHTMLString";
import dayjs from "dayjs";

interface CampaignData {
  jobDetails: JobDetails;
  companyDetails: CompanyDetails;
}

interface JobDetails {
  jobTitle: string;
  location: string;
  workMode: string;
  minSal: number;
  maxSale: number;
  currency: string;
  salaryRange: string;
  jobDetails: string;
  employmentType: string;
  deadLineDate: string;
  deadLineTime: string;
  deadLineTimeStr: string;
  status: string;
  isExpired: boolean;
  isShow: boolean;
}

interface CompanyDetails {
  logo: string;
  companyName: string;
  companyDescription: string;
  companyDetails: CompanyInDetail;
}

interface CompanyInDetail {
  size: string;
  industry: string;
  website: string;
}
interface PreviewProps {
  campaignId: string | undefined;

  onPreviousClick: () => void;
}

const Preview: React.FC<PreviewProps> = ({ campaignId, onPreviousClick }) => {
  const [campaignData, setcampaignData] = useState<CampaignData>();

  const [isShowPublish, setIsShowPublish] = useState(true);

  const [updateCampaign, setUpdateCampaign] = useState<boolean>(false);

  const handleNavigation = useNavigation();

  const initializeData = useCallback(async () => {
    const url = "/campaign/campaign-preview";
    const requestData = {
      email: localStorage.getItem("email"),
      id: campaignId,
    };
    await API.post(url, requestData)
      .then((res) => {
        setcampaignData(res.data.data);
        setUpdateCampaign(res.data.data.jobDetails.isPublish);
      })
      .catch((error) => {
        if(error.response.data.code === 404){
          handleNavigation("/not-found", null)
        }
        // toaster
        displayToaster("error", error.response.data.message);
      });
  }, [campaignId]);

  const handlePublishCampaign = async () => {
    const url = "/campaign/update-status";
    const requestData = {
      id: campaignId,
      status: Constants.CAMPAIGN_STATUS.PUBLISHED,
    };
    await API.post(url, requestData)
      .then((res) => {
        displayToaster("success", res.data.data);

        sessionStorage.removeItem("createdCampaignId");

        handleNavigation("/campaign/publish-success", {
          id: campaignId,
        });
      })
      .catch((error) => {
        // toaster
        displayToaster("error", error.response.data.message);
      });
  };

  useEffect(() => {
    if (campaignData == null) {
      initializeData();
    }

    if (
      campaignData &&
      campaignData.companyDetails.logo !== null &&
      campaignData.companyDetails.companyDescription !== null &&
      campaignData.companyDetails.companyDetails.size !== null &&
      campaignData.companyDetails.companyDetails.industry !== null &&
      campaignData.companyDetails.companyDetails.website !== null &&
      campaignData.companyDetails.logo !== null &&
      campaignData.companyDetails.companyDescription !== null &&
      campaignData.companyDetails.companyDetails.size !== null &&
      campaignData.companyDetails.companyDetails.industry !== null &&
      campaignData.companyDetails.companyDetails.website !== null &&
      campaignData.companyDetails.logo !== "" &&
      campaignData.companyDetails.companyDescription !== "" &&
      campaignData.companyDetails.companyDetails.size !== "" &&
      campaignData.companyDetails.companyDetails.industry !== "" &&
      campaignData.companyDetails.companyDetails.website !== ""
    ) {
      setIsShowPublish(false);
    } else {
      setIsShowPublish(true);
    }
  }, [campaignData, initializeData]);

  return (
    <>
      <div className="flex flex-col gap-1 mb-9">
        <p className="m-0 text-sm">Step 03</p>
        <p className="m-0 text-3xl font-bold">Preview</p>
      </div>
      <div className="flex flex-col md:border md:border-[#E2E2E2] md:flex-row preview-section">
        <div
          id="campaign-content"
          className="flex flex-col w-full gap-5 py-4 md:px-6  "
        >
          <p className="text-2xl font-bold">
            {campaignData && campaignData.jobDetails.jobTitle}
          </p>
          <div className="flex flex-row flex-wrap gap-[10px] ">
            <Tag>
              {campaignData && campaignData.jobDetails.location}{" "}
              <EnvironmentOutlined />
            </Tag>

            <Tag>{campaignData && campaignData.jobDetails.employmentType}</Tag>

            <Tag>{campaignData && campaignData.jobDetails.workMode}</Tag>

            {campaignData && campaignData.jobDetails.isShow ? (
              <Tag>{campaignData && campaignData.jobDetails.salaryRange}</Tag>
            ) : null}
          </div>
          <p className="text-base text-[#595D62]">
            Application Deadline:{" "}
            {campaignData &&
              dayjs(campaignData.jobDetails.deadLineDate).format("DD/MM/YYYY")}
            - {campaignData && campaignData.jobDetails.deadLineTimeStr}
          </p>

          <Divider className="m-0" />
          <div className="flex flex-col gap-[10px]">
            <p className="text-lg font-bold">Job Details</p>
            <p className="text-base">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    campaignData && campaignData.jobDetails.jobDetails
                      ? sanitizeHTMLString(campaignData.jobDetails.jobDetails)
                      : "",
                }}
              />
            </p>
          </div>
        </div>
        <div
          id="company-content"
          className="max-w-[360px] py-4 px-6  bg-[#F5F5F580]"
        >
          <div>
            {/* when no value found */}
            {/* <Skeleton /> */}
            {campaignData &&
            campaignData.companyDetails.logo !== null &&
            campaignData.companyDetails.companyDescription !== null &&
            campaignData.companyDetails.companyDetails.size !== null &&
            campaignData.companyDetails.companyDetails.industry !== null &&
            campaignData.companyDetails.companyDetails.website !== null &&
            campaignData.companyDetails.logo !== "" &&
            campaignData.companyDetails.companyDescription !== "" &&
            campaignData.companyDetails.companyDetails.size !== "" &&
            campaignData.companyDetails.companyDetails.industry !== "" &&
            campaignData.companyDetails.companyDetails.website !== "" ? (
              <div className="flex flex-col w-full gap-3 ">
                <div>
                  <img
                    style={{ height: "50px" }}
                    src={campaignData && campaignData.companyDetails.logo}
                    alt="company-logo"
                  />
                </div>
                <div className="flex flex-col gap-[5px]">
                  <p className="text-lg font-bold">
                    {campaignData && campaignData.companyDetails.companyName}
                  </p>
                  <p className="text-sm">
                    {campaignData &&
                      campaignData.companyDetails.companyDescription}
                  </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <div className="flex flex-row gap-[5px]">
                    <p className="text-sm">Size:</p>
                    <p className="text-sm font-bold">
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.size}
                    </p>
                  </div>
                  <div className="flex flex-row gap-[5px]">
                    <p className="text-sm">Industry:</p>
                    <p className="text-sm font-bold">
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.industry}
                    </p>
                  </div>
                  <div className="flex flex-row gap-[5px]">
                    <p className="text-sm">Website:</p>
                    <p
                      onClick={() => {
                        window.open(
                          campaignData &&
                            campaignData.companyDetails.companyDetails.website,
                          "_blank"
                        );
                      }}
                      className="text-sm font-bold break-all cursor-pointer"
                    >
                      {campaignData &&
                        campaignData.companyDetails.companyDetails.website}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <Skeleton.Button shape="square" />
                </div>
                <div>
                  {campaignData && campaignData.companyDetails.companyName}
                </div>
                <div>
                  <Skeleton paragraph={{ rows: 4 }} />
                </div>
                <div>
                  Please complete the company profile first to publish campaign
                </div>
                <div>
                  <Link to="/company-profile">
                    <Button className="signup-button">
                      Complete Company Profile
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between w-full mt-8 mb-6">
        <Button className=" " size="large" onClick={onPreviousClick}>
          Previous
        </Button>

        <Button
          className=" "
          size="large"
          type="primary"
          disabled={isShowPublish}
          onClick={handlePublishCampaign}
        >
          {updateCampaign ? "Update Campaign" : "Publish Campaign"}
        </Button>
      </div>
    </>
  );
};

export default Preview;
