// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import useCoookieHandler from './CoookieHandler';
import Constants from '../constants/Constants';


interface AuthContextType {
  isLoggedIn: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const cookieHandler = useCoookieHandler();
  // const auth

  useEffect(() => {
    const fetchTokenAndCheckAuthentication = async () => {
    
      const storedToken = await cookieHandler.getToken();
      if (storedToken) {
        setToken(storedToken);
        // Check authentication asynchronously
        const isAuthenticated = await cookieHandler.checkAuthentication();
        setIsLoggedIn(isAuthenticated);
      }
    };
  
    fetchTokenAndCheckAuthentication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const login = (newToken: string) => {
    setToken(newToken);
    setIsLoggedIn(true);
    document.cookie = `${Constants.JWT_TOKEN}=${newToken}; path=/; secure; samesite=strict`;
  };

  const logout = () => {
    setToken(null);
    setIsLoggedIn(false);
    // remove cookie
    // localStorage.removeItem('token'); // Remove token from localStorage
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
