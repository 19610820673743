import { Button, Input, Form } from "antd";
import useNavigation from "../../../helper/UseNavigation";
import { displayToaster } from "../../../helper/Toaster";
import { Link } from "react-router-dom";
import { sendVerifyEmail } from "../../../helper/SendVeificationEmail";
import { ArrowLeftOutlined } from "@ant-design/icons";

function ForgotPassword() {
  // navigation helper function
  const handleNavigation = useNavigation();

  const handleResetPassword = async (values: any) => {
    const email = values.email;
    var requestData = {
      email: email,
    };

    await sendVerifyEmail(requestData)
      .then((res) => {
        // toaster
        displayToaster("success", res.data.message);
        handleNavigation("/verify-password", {
          email: values.email,
          type: "reset-password",
        });
      })
      .catch((error) => {
        // toaster
        displayToaster("error", error.response.data.message);
      });
  };

  return (
    <>
      <div className="flex justify-center w-full h-full align-center px-[30px]">
        <div className="flex flex-col justify-center w-full align-center md:max-w-[380px] gap-8">
          <div className="flex flex-col gap-[14px]">
            <p className="m-0 text-[32px] font-bold leading-none">Forgot password?</p>
            <p className="m-0 text-base text-[#595D62] leading-none ">
              No worries, we'll send you reset instructions.
            </p>
          </div>

          <Form
            name="signup-form"
            onFinish={handleResetPassword}
            layout="vertical"
            autoComplete="off"
            className="flex flex-col w-full gap-1"
            scrollToFirstError
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                size="large"
                className="input-fields"
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item colon={false}>
              <Button
                className="signup-button lengthy-button"
                htmlType="submit"
                size="large"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>

          <div className="flex justify-center w-full">
            <Link to="/signin">
              <Button
                className="text-[#595D62]"
                type="text"
                size="large"
                icon={<ArrowLeftOutlined />}
              >
                Back to log in
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
