import {
  Button,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Select,
  Popconfirm,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ExperienceEditModal from "./ExperienceEditModal";
import Constants from "../../../constants/Constants";
import GoogleAutoComplete from "react-google-autocomplete";
import CreateExperienceProps from "../../../DTOs/CandidateExperienceDTOs";
import CandidateExperienceService from "../../../Services/Candidate/CandidateExperienceService";
import dayjs from "dayjs";
import CandidateGetInfoDTO from "../../../DTOs/CandidateGetAboutYouDTO";
import retriveCandidateEmail from "../../../helper/RetriveCandidateEmailIfExist";
import { AxiosError } from "axios";

interface StepData {
  title: string;
  value: number;
  subTitle: string;
  stepName: string;
  description: string;
}

interface ExperienceProps {
  currentData: () => StepData | void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  isMobile: boolean;
  campaignId: string;
  setExperienceSectionCompleted: (experienceSectionCompleted: boolean) => void;
}

function Experience({
  currentData,
  onNextClick,
  onPreviousClick,
  isMobile,
  campaignId,
  setExperienceSectionCompleted,
}: ExperienceProps) {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stepData = currentData();
  const [isWorking, setIsWorking] = useState(false);
  const { Option } = Select;
  const [experienceEditId, setExperienceEditId] = useState<number | null>(null);
  const [empStartDate, setEmpStartDate] = useState<any>(null);
  const [empEndDate, setEmpEndDate] = useState<any>(null);
  const [allExpereinces, setAllExpereinces] = useState<any | null>();
  const dateFormat = "DD/MM/YYYY";

  const dateFormatToDataBase = "YYYY-MM-DD";

  useEffect(() => {
    getAllExperiences();
  }, []);

  const getAllExperiences = async () => {
    const candidateEmail = retriveCandidateEmail();
    if (candidateEmail != null) {
      try {
        const data: CandidateGetInfoDTO = {
          email: candidateEmail,
          campaignId: campaignId,
          roles: Constants.ROLES[2].value,
        };

        const resp = await CandidateExperienceService.getAllExperiences(data);

        setAllExpereinces(resp.data.userData);

        if (resp.data.userData.length > 0) {
          setExperienceSectionCompleted(true);
        } else {
          setExperienceSectionCompleted(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDateDifference = (d1: string, d2: string): any => {
    const formatString = "YYYY-MM-DD";

    const date1 = new Date(Date.parse(d1));
    const date2 = new Date(Date.parse(d2));

    const daysDifference =
      Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);

    const years = Math.floor(daysDifference / 365);
    const remainingDays = daysDifference % 365;
    const months = Math.floor(remainingDays / 30);

    if (years == 0 && months == 0) {
      return `${Math.round(daysDifference)}  ${
        Math.round(daysDifference) > 1 ? "days" : "day"
      }`;
    } else if (years == 0 && months > 0) {
      return `${months}  ${months > 1 ? "months" : "month"}`;
    } else {
      return `${years}  ${years > 1 ? "years" : "year"} ${
        months > 0 ? months : ""
      } ${months > 0 ? (months > 1 ? "months" : "month") : ""}`;
    }
  };

  const handlePlaceSelected = (place: any) => {
    form.setFieldsValue({ location: place.formatted_address });
  };

  const deleteExperience = async (experience: any) => {
    const candidateEmail = retriveCandidateEmail();

    try {
      const data: any = {
        campaignId: campaignId,
        email: candidateEmail,
        roles: Constants.ROLES[2].value,
        id: experience.id,
      };
      const resp = await CandidateExperienceService.deleteExperience(data);
      getAllExperiences();
      message.success("Experience deleted sucessfully");
    } catch (error) {
      console.log(error);
      message.error("Sorry something went wrong");
    }
  };

  const disabledDateEmpStartDate = (current: dayjs.Dayjs | null): boolean => {
    if (empEndDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isAfter(empEndDate, "day") ||
            dayjs(current).isSame(empEndDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const disabledDateEmpEndDate = (current: dayjs.Dayjs | null): boolean => {
    if (empStartDate == null) {
      return dayjs(current).isAfter(dayjs(), "day");
    } else {
      return (
        (!!current &&
          (dayjs(current).isBefore(empStartDate, "day") ||
            dayjs(current).isSame(empStartDate, "day"))) ||
        dayjs(current).isAfter(dayjs(), "day")
      );
    }
  };

  const onFinish = async (value: any) => {
    const candidateEmail = retriveCandidateEmail();

    try {
      const data: CreateExperienceProps = {
        jobTitle: value.position,
        companyName: value.companyName,
        employmentType: value.employmentType,
        location: value.location,
        startDate: value.empStartDate.format(dateFormatToDataBase),
        endDate: isWorking
          ? null
          : value.empEndDate.format(dateFormatToDataBase),
        isWorking: isWorking,
        summary: value.summary,
        campaignId: campaignId,
        email: candidateEmail ? candidateEmail : "",
        roles: Constants.ROLES[2].value,
      };

      const resp = await CandidateExperienceService.createExperience(data);
      getAllExperiences();
      form.resetFields();
      setEmpStartDate(null);
      setEmpEndDate(null);
      setIsWorking(false);
      message.success("Experience added successfully");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setExperienceSectionCompleted(true);
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError<any>;
      if (axiosError.response && axiosError.response.data) {
        message.error(axiosError.response.data.message);
      } else {
        message.error("Sorry something went wrong");
      }
    }
  };

  const onEditClicked = async (data: any) => {
    setExperienceEditId(data.id);
  };

  if (!stepData) {
    // Handle case where currentData returns void
    return <div>No data available</div>;
  }

  return (
    <>
      <div className="flex flex-col md:flex-row md-0 mr-0 md:ml-[116px] md:mr-[116px] gap-6 justify-between ">
        <div className="max-w-[500px] px-5 md:px-0">
          <div
            style={{ marginBottom: "16px" }}
            className="flex flex-col w-full gap-1"
          >
            <p className="m-0 text-sm">{stepData && stepData.stepName}</p>
            <p
              style={{ margin: "12px 0px" }}
              className="m-0 text-3xl font-bold"
            >
              {stepData && stepData.subTitle}
            </p>
            <p className="m-0 text-base text-[#595D62] leading-none">
              {stepData && stepData.description}
            </p>
          </div>
          <Form
            form={form}
            name="campaign-form"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            scrollToFirstError
          >
            <div>
              {/* <p id="general-details" className="mb-4 text-lg font-bold">
                General Details
              </p> */}

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Your Position
                </p>
                <Form.Item
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: "Position is required",
                    },
                  ]}
                >
                  <Input placeholder="Eg. Software Engineer" size="large" />
                </Form.Item>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Company Name
                </p>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Company Name is required",
                    },
                  ]}
                >
                  <Input placeholder="Type company name" size="large" />
                </Form.Item>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Employment Type
                </p>
                <Form.Item
                  name="employmentType"
                  rules={[
                    {
                      required: true,
                      message: "Employment Type is required",
                    },
                  ]}
                >
                  <Select
                    // defaultValue="lucy"
                    placeholder="Select"
                    size="large"
                    // onChange={handleChange}
                  >
                    {Constants.EMPLOYMENT_TYPE.map((emptype) => (
                      <Option key={emptype} value={emptype}>
                        {emptype}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Location
                </p>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Location is required",
                    },
                  ]}
                >
                  <GoogleAutoComplete
                    className="w-full border  border-[#e5e7eb] px-[11px] py-2 rounded-lg text-base"
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={handlePlaceSelected}
                    placeholder="Select"
                  />
                </Form.Item>
              </div>

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Work Period
                </p>
                <div className="grid grid-cols-1 gap-0 md:gap-4 md:grid-cols-2">
                  <Form.Item
                    name="empStartDate"
                    rules={[
                      {
                        required: true,
                        message: "Employment Start Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select start date"
                      disabledDate={disabledDateEmpStartDate}
                      style={{ width: "100%" }}
                      size="large"
                      format={dateFormat}
                      onChange={setEmpStartDate}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                  {!isWorking && (
                    <Form.Item
                      name="empEndDate"
                      rules={[
                        {
                          required: true,
                          message: "Employment End Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Select end date"
                        disabledDate={disabledDateEmpEndDate}
                        style={{ width: "100%" }}
                        size="large"
                        format={dateFormat}
                        onChange={setEmpEndDate}
                        inputReadOnly={true}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <Form.Item name="currentlyWorking">
                <Checkbox
                  checked={isWorking}
                  onChange={(e) => {
                    setIsWorking(e.target.checked);
                    form.setFieldsValue({ currentlyWorking: e.target.checked });
                    if (e.target.checked) {
                      setEmpEndDate(null);
                      form.setFieldsValue({
                        empEndDate: null,
                      });
                    }
                  }}
                >
                  I currently work here
                </Checkbox>
              </Form.Item>

              <div>
                <p className="m-0 text-base mb-[8px]">
                  <span style={{ color: "#ff4d4f" }}> *</span> Summary
                </p>

                <Form.Item
                  name="summary" // Add a unique name
                  rules={[
                    {
                      required: true,
                      message: "Summary is required",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ height: "132px" }}
                    size="large"
                    placeholder="Summary"
                  />
                </Form.Item>
              </div>

              <Button
                style={{ width: "100%", background: "#F5F5F5" }}
                className="mt-5 "
                htmlType="submit"
                size="large"
              >
                Add
              </Button>
            </div>

            {!isMobile ? (
              <div className="flex justify-between w-full">
                <Button
                  className="mt-5 "
                  onClick={onPreviousClick}
                  size="large"
                >
                  Previous
                </Button>

                <Button
                  type="primary"
                  onClick={onNextClick}
                  className="mt-5 "
                  size="large"
                >
                  Next
                </Button>
              </div>
            ) : (
              <div className="mt-5 "></div>
            )}
          </Form>
        </div>
        <div className="max-w-[600px] w-full px-5 md:px-0">
          {/* set experiences if any */}
          <div className=" rounded-[10px]" style={{ background: "#F5F5F580" }}>
            {allExpereinces != null &&
              allExpereinces.map((data: any) => (
                <div
                className="p-[30px]"
                  style={{
                    
                    borderBottom: "2px solid #E2E2E2",
                  }}
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <p className="m-0 text-base font-bold">{data.jobTitle}</p>
                      <p className="m-0 text-[14px]">
                        {data.companyName} • {data.employmentType} •{" "}
                        {data.location}
                      </p>
                      <p
                        className="m-0 text-[14px] mt-[5px] mb-[16px]"
                        style={{
                          color: "#595D62",
                        }}
                      >
                        {data.endDate != null
                          ? getDateDifference(data.startDate, data.endDate)
                          : dayjs(data.startDate).year() + " - Present"}
                      </p>
                      <p
                        className="m-0 text-[14px]"
                        style={{
                          color: "#595D62",
                        }}
                      >
                        {data.summary}
                      </p>
                    </div>
                    <div className="flex flex-row">
                      
                        <Popconfirm
                          title="Delete Experience"
                          description="Are you sure to delete this Experience?"
                          onConfirm={() => deleteExperience(data)}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                          
                        >
                          <Button icon={<DeleteOutlined />} type="text"></Button>
                        </Popconfirm>
                      
                      <Button
                        icon={<EditOutlined />}
                        type="text"
                        onClick={() => {
                          setIsModalOpen(true);
                          onEditClicked(data);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              ))}
            {/* {testData.map((data) => (
              <div
                style={{
                  margin: "0px 16px",
                  padding: "16px",
                  borderBottom: "2px solid #E2E2E2",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <p style={{ fontWeight: "700", fontSize: "16px" }}>
                      {data.name}
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {data.compName} • {data.empType} • {data.country}
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#595D62",
                        margin: "5px 0px",
                      }}
                    >
                      {data.years}
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#595D62",
                      }}
                    >
                      {data.desc}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "self-start",
                      gap: "10px",
                    }}
                  >
                    <Popconfirm
                      title="Delete Experience"
                      description="Are you sure to delete this Experience?"
                      onConfirm={() => deleteExperience(data)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                      okButtonProps={{
                        style: { backgroundColor: "#13265C" },
                      }}
                      placement={isMobile ? "bottom" : "left"}
                    >
                      <DeleteOutlined style={{ cursor: "pointer" }} />
                    </Popconfirm>
                    <EditOutlined
                      onClick={() => {
                        setIsModalOpen(true);
                        onEditClicked(data);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
        {isMobile && (
          <div className="flex justify-between w-full px-5 md:px-0">
            <Button
              className="mt-5 "
              onClick={() => onPreviousClick()}
              size="large"
            >
              Previous
            </Button>

            <Button
              type="primary"
              className="mt-5 "
              size="large"
              onClick={() => onNextClick()}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      <div className="empty-div"></div>
      <ExperienceEditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        experienceEditId={experienceEditId}
        campaignId={campaignId}
        renderExperience={getAllExperiences}
      />
    </>
  );
}

export default Experience;
