import LinkedIn from "../../../assets/icons/linkedinIcon.png"; // Make sure to use correct path and filename

interface ShareWithLinkedinButtonProps {
  url: string;
}

function ShareWithLinkedinButton({ url }: ShareWithLinkedinButtonProps) {
  const handleShare = () => {
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <>
      <img
        src={LinkedIn}
        alt="Linkedin Icon"
        onClick={handleShare}
      />
    </>
  );
}

export default ShareWithLinkedinButton;
